import { useEffect, useState } from 'react';
import { IconButton, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { DataBox } from '../../../../Components/DataBox/DataBox';
import UserFormDialog from './UserFormDialog';
import { ConfirmActionDialog } from '../../../../Components/Common/ConfirmActionDialog/ConfirmActionDialog';
import { getUsers } from '../../../../Services/Users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { assignUserToJob, getJobUsers, unAssignUserFromJob } from '../../../../Services/Jobs';
import { ROLES } from '../../../../Types/constants';

export const JobTeam = ({ jobId }: { jobId: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [showConfirmUnassignDialog, setShowConfirmUnassignDialog] = useState(false);
  const [openUserForm, setOpenUserForm] = useState(false);
  const [fromTA, setFromTa] = useState(false);
  const [teamTA, setTeamTA] = useState<User[]>([]);
  const [teamOthers, setTeamOthers] = useState<User[]>([]);
  const [userToUnassign, setUserToUnassign] = useState<User | null>(null);

  const errorMessage = t('toast.errors.something_went_wrong');
  const assignSuccessMessage = t('jobs.team.assign_user_success_message');
  const unassignSuccessMessage = t('jobs.team.unassign_user_success_message');

  const { data: users } = useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const response = await getUsers({
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const { data: jobUsers } = useQuery({
    queryKey: ['getJobUsers'],
    queryFn: async () => {
      const response = await getJobUsers(jobId, {
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const { mutate: assignUserMutation } = useMutation({
    mutationFn: (userId: string) => assignUserToJob(jobId!, userId),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(assignSuccessMessage);
      setOpenUserForm(false);
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({ queryKey: ['getJobUsers'] });
    },
  });

  const { mutate: unAssignUserMutation } = useMutation({
    mutationFn: (userId: string) => unAssignUserFromJob(jobId!, userId),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(unassignSuccessMessage);
      setOpenUserForm(false);
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({ queryKey: ['getJobUsers'] });
    },
  });

  const handleAddUserFromTa = () => {
    setFromTa(true);
    setOpenUserForm(true);
  };

  const handleAddOtherUser = () => {
    setFromTa(false);
    setOpenUserForm(true);
  };

  const handleUserAdd = (user: User) => {
    assignUserMutation(user.id!);
    setOpenUserForm(false);
  };

  const handleUnassignClicked = (user: User) => {
    setUserToUnassign(user);
    setShowConfirmUnassignDialog(true);
  };
  const handleUnassignConfirm = () => {
    unAssignUserMutation(userToUnassign?.id!);
    setShowConfirmUnassignDialog(false);
  };

  useEffect(() => {
    if (jobUsers) {
      setTeamTA(
        jobUsers.filter(
          (user) => user.roles.includes(ROLES.admin) || user.roles.includes(ROLES.recruiter),
        ),
      );
      setTeamOthers(jobUsers.filter((user) => user.roles.includes(ROLES.internal)));
    }
  }, [jobUsers]);
  return (
    <div style={{ display: 'flex' }}>
      <>
        <DataBox title={t('jobs.team.hrTeam')} handleAdd={handleAddUserFromTa}>
          <TableContainer component={Paper}>
            <Table>
              {teamTA?.map((user, index) => (
                <TableRow key={index} style={{ height: '55px' }}>
                  <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                    {user.fullName}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ textAlign: 'right' }}>
                    <IconButton onClick={() => handleUnassignClicked(user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </DataBox>
        <DataBox title={t('jobs.team.others')} handleAdd={handleAddOtherUser}>
          <TableContainer component={Paper}>
            <Table>
              {teamOthers?.map((user, index) => (
                <TableRow key={index} style={{ height: '55px' }}>
                  <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                    {user.fullName}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ textAlign: 'right' }}>
                    <IconButton onClick={() => handleUnassignClicked(user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </DataBox>
        <UserFormDialog
          open={openUserForm}
          handleClose={() => setOpenUserForm(false)}
          users={users ?? []}
          onAddUser={handleUserAdd}
          fromTA={fromTA}
        />
        <ConfirmActionDialog
          open={showConfirmUnassignDialog}
          handleClose={() => setShowConfirmUnassignDialog(false)}
          handleConfirm={handleUnassignConfirm}
          description={t('jobs.team.unassign', { elem: userToUnassign?.fullName })}
        />
      </>
    </div>
  );
};
