import { TableRow } from '@mui/material';
import { StyledCell } from './styles';
import { DashboardTable } from '../../Components/DashboardTable/DashboardTable';

interface Props {
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
];

export const ClientTable = ({
  currentPage,
  handlePageChange,
  rows,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
}: Props) => {
  return (
    <DashboardTable
      headerCells={headCells}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      rows={rows}
      lastPage={lastPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItems={totalItems}
    >
      {rows?.map((row) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            <StyledCell>{row.name}</StyledCell>
            <StyledCell id={row.id} scope="row">
              {row.id}
            </StyledCell>
            <StyledCell>{row.type}</StyledCell>
          </TableRow>
        );
      })}
    </DashboardTable>
  );
};
