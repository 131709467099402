import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { editJob, getJobById } from '../Services/Jobs';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { SENIORITY_OPTIONS } from '../Types/constants';

export const useJob: () => any = () => {
  const { jobId } = useParams();
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState(0);
  const [job, setJob] = useState<Job>();
  const [hasBeenEdited, setHasBeenEdited] = useState(false);

  const { data: jobData } = useQuery({
    queryKey: ['getJobById', jobId],
    queryFn: () => getJobById(jobId),
  });

  useEffect(() => {
    if (jobData) {
      const updatedJobData = { ...jobData };
      if (updatedJobData.seniority) {
        updatedJobData.seniority =
          SENIORITY_OPTIONS[updatedJobData.seniority] || updatedJobData.seniority;
      }
      setJob(updatedJobData);
      setHasBeenEdited(false);
    }
  }, [jobData]);

  const updateJob = (updatedFields: Job) => {
    setJob((prevJob) => {
      if (!prevJob) return prevJob;
      setHasBeenEdited(true);
      return {
        ...prevJob,
        ...updatedFields,
      };
    });
  };
  const { mutate: editJobMutation } = useMutation({
    mutationFn: (data: Job) => editJob(job?.id, data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.edit', { elem: 'Job ' + data.id }));
      queryClient.invalidateQueries({ queryKey: ['getJobById'] });
    },
  });

  useEffect(() => {
    if (job && hasBeenEdited) {
      const updatedJob = { ...job };
      Object.keys(updatedJob).forEach((key) => {
        if (updatedJob[key] === '') {
          updatedJob[key] = null;
        }
      });
      if (updatedJob.client && typeof updatedJob.client === 'object') {
        updatedJob.clientId = updatedJob.client.id;
        updatedJob.client = null;
      }

      editJobMutation(updatedJob);
    }
  }, [job, hasBeenEdited, editJobMutation]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return {
    job,
    selectedTab,
    handleChange,
    updateJob,
  };
};
