import { LabelSpan, NoteContainer, StyledTypography } from './styles';
import { IconButton, TextField, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCandidateById } from '../../../../../Services/Candidates';
import { formatDateTime } from '../../../../../Utils/format-date';

interface Props {
  candidate: CandidateJob;
  comments: string;
  setComments: (newComments: string) => void;
}

export const GeneralInfo = ({ candidate, comments, setComments }: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const linkedInLabel = t('planner.detailed_card.general_info.linked_in');
  const englishLabel = t('planner.detailed_card.general_info.english');
  const coderbyteLabel = t('planner.detailed_card.general_info.coderbyte');
  const recruiterLabel = t('planner.detailed_card.general_info.recruiter');
  const goToLinkedInProfileLabel = t('planner.detailed_card.general_info.go_to_linked_in_profile');
  const countryLabel = t('planner.labels.country');
  const commentsLabel = t('common.comments');

  const noData = t('common.no_data');
  const noResults = t('common.no_results');

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  };

  const { data: candidateData } = useQuery({
    queryKey: ['getCandidateById', candidate.candidateId],
    queryFn: () => getCandidateById(`${candidate.candidateId}`),
    enabled: !!candidate.candidateId,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <StyledTypography>
        <LabelSpan>{countryLabel}</LabelSpan>
        {': '} {candidate.candidateCountry}
      </StyledTypography>
      <StyledTypography>
        <LabelSpan>{linkedInLabel}</LabelSpan>
        {': '}
        {candidateData?.linkedin ? (
          <a href={candidateData?.linkedin} target="_blank" rel="noreferrer">
            {goToLinkedInProfileLabel}
          </a>
        ) : (
          <span>{noData}</span>
        )}
      </StyledTypography>
      <StyledTypography>
        <LabelSpan>
          {englishLabel}
          {': '}
        </LabelSpan>
        {candidate.englishProficiencyData.level ? (
          `${candidate.englishProficiencyData.level} - ${formatDateTime(candidate.englishProficiencyData.testDate)}`
        ) : (
          <span>{noResults}</span>
        )}
      </StyledTypography>
      <StyledTypography>
        <LabelSpan>
          {coderbyteLabel}
          {': '}
        </LabelSpan>
        {candidate.technicalSkills.testScore ? `${candidate.technicalSkills.testScore}` : noResults}
      </StyledTypography>
      <StyledTypography>
        <LabelSpan>{recruiterLabel}</LabelSpan>
        {': '}
        {candidate.user?.fullName || noData}
      </StyledTypography>
      <StyledTypography>
        <LabelSpan>{commentsLabel}:</LabelSpan>
      </StyledTypography>
      <NoteContainer
        style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'start',
            justifyContent: 'end',
          }}
        >
          {isEditing ? (
            <TextField
              value={comments}
              onChange={handleInputChange}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              autoFocus
            />
          ) : (
            <div style={{ flex: 1, paddingTop: 5, minHeight: 100 }}>{comments}</div>
          )}
          <div style={{ display: 'flex', gap: '8px', marginLeft: 5 }}>
            {isEditing ? (
              <>
                <IconButton
                  style={{ marginTop: 0 }}
                  color="primary"
                  onClick={() => setIsEditing(false)}
                >
                  <CheckIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <IconButton
                  style={{ marginTop: 0 }}
                  color="primary"
                  onClick={() => setIsEditing(false)}
                >
                  <CloseIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  style={{ marginTop: 0 }}
                  color="primary"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </NoteContainer>
    </Box>
  );
};
