import { useState } from 'react';
import { Autocomplete, DialogContent, DialogContentText, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormDialogContainer from '../../../Components/FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../../Components/DialogActionButtons/DialogActionButtons';

interface UserFormDialogProps {
  open: boolean;
  handleClose: () => void;
  users: User[];
  onAddUser: (user: User) => void;
}

export default function UserFormDialog({
  open,
  handleClose,
  users,
  onAddUser,
}: UserFormDialogProps) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleUserSelect = (event: any, newValue: User | null) => {
    setSelectedUser(newValue);
  };

  const handleAddClick = () => {
    if (selectedUser) {
      onAddUser(selectedUser);
      handleClose();
    }
  };

  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={t('jobs.team.select_user')}>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 16 }}>
          {t('candidate_jobs.recruiter_responsible.description')}
        </DialogContentText>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.fullName}
          onChange={handleUserSelect}
          renderInput={(params) => <TextField {...params} label={t('common.user')} />}
          fullWidth
        />
      </DialogContent>
      <DialogActionButtons onCancel={handleClose} onConfirm={handleAddClick} />
    </FormDialogContainer>
  );
}
