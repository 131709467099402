import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    yellow: Palette['primary'];
  }
  interface PaletteOptions {
    yellow?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    yellow: {
      light: '#fde994',
      main: '#ffe35e',
      dark: '#83580b',
    },
    info: {
      light: '#E2E7EA',
      main: '#E2E7EA',
      dark: '#1C2025',
    },
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    success: {
      light: '#ddeedf',
      dark: '#5c715e',
      main: '#2e7d32',
    },
    error: {
      light: '#ffebeb',
      dark: '#bc2525',
      main: '#e70000',
    },
  },
});

export default theme;
