import { instance, ServiceResponse } from './Axios';

export const getCandidateJobs = async (params: {
  page: number;
  count: number;
  jobId?: string;
  candidateId?: string;
  candidateName?: string;
  status?: string;
  subStatus?: string;
}) => {
  const response = await instance.get<ServiceResponse<CandidateJob[]>>('/candidate-jobs', {
    params,
  });
  return response.data;
};

export const getCandidateJobById = async (params: { id: number }) => {
  const response = await instance.get('/candidate-jobs', { params });
  return response.data;
};

export const editCandidateJob = async (id?: number, data?: CandidateJob) => {
  const response = await instance.put(`/candidate-jobs/${id}`, data);
  return response.data;
};

export const getInterviews = async (
  candidateJobId: number,
  params: {
    page: number;
    count: number;
  },
) => {
  const response = await instance.get<ServiceResponse<Interview[]>>(
    `candidate-jobs/${candidateJobId}/interviews`,
    {
      params,
    },
  );
  return response.data;
};

export const addInterview = async (candidateJobId: number, data: Interview) => {
  const response = await instance.post(`candidate-jobs/${candidateJobId}/interviews`, data);
  return response.data;
};

export const editInterview = async (data: Interview) => {
  const response = await instance.put(`interviews/${data.id}`, data);
  return response.data;
};

export const deleteInterview = async (id: string) => {
  const response = await instance.delete(`interviews/${id}`);
  return response.data;
};

export const getCandidateJobNotes = async (
  candidateJobId: number,
  params: {
    page: number;
    count: number;
  },
) => {
  const response = await instance.get<ServiceResponse<Note[]>>(
    `candidate-jobs/${candidateJobId}/notes`,
    {
      params,
    },
  );
  return response.data.items;
};

export const addNoteCandidateJob = async (
  candidateJobId: number,
  params: { description: string; userId: number },
) => {
  const response = await instance.post(`candidate-jobs/${candidateJobId}/notes`, params);
  return response.data;
};

export const updateCandidateJobStatus = async (
  id: string,
  params: { status: string; subStatus: string },
) => {
  const response = await instance.put(`/candidate-jobs/${id}/status`, params);
  return response.data;
};

export const uploadCandidateJobFile = async (id?: string, file?: FormData) => {
  const response = await instance.post(`/candidate-jobs/${id}/files`, file);
  return response.data;
};

export const getCandidateJobFiles = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<AttachmentFile[]>>(
    `/candidate-jobs/${id}/files`,
    { params },
  );
  return response.data.items;
};

export const editCandidateJobVisibilityToInternalClient = async (id?: number, date?: string) => {
  const response = await instance.put(`/candidate-jobs/${id}/visibility`, { date });
  return response.data;
};

export const deleteCandidateJob = async (id?: string) => {
  const response = await instance.delete(`/candidate-jobs/${id}`);
  return response.data;
};

export const assignUserToCandidateJob = async (candidateJobId: string, userId: string) => {
  const response = await instance.put(`/candidate-jobs/${candidateJobId}/users/${userId}`);
  return response.data;
};

export const unAssignUserFromCandidateJob = async (candidateJobId: string, userId: string) => {
  const response = await instance.delete(`/candidate-jobs/${candidateJobId}/users/${userId}`);
  return response.data;
};
