import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { COUNT_ROWS_PER_PAGE } from '../Types/constants';

interface UseTableProps {
  query: any;
  params: Record<string, any>;
  removeEmptyRows?: boolean;
}

export interface UseTableResult {
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

export const useTable = ({ query, params }: UseTableProps): UseTableResult => {
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);

  const { data } = useQuery({
    queryKey: [query.name, currentPage, params, rowsPerPage],
    queryFn: () =>
      query({
        ...params,
        page: currentPage + 1,
        count: rowsPerPage,
      } as unknown as Parameters<typeof query>[0]),
  });

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (data) {
      setLastPage(data?.lastPage - 1);
    }
  }, [data]);

  return {
    currentPage,
    lastPage,
    handlePageChange,
    rows: data?.items || [],
    rowsPerPage,
    setRowsPerPage,
    totalItems: data?.totalItems,
  };
};
