import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../../Stores/useAuthStore';

export default function ProtectedRoutesAuth({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const { instance } = useMsal();
  const { user } = useAuthStore();
  const account = instance.getAllAccounts()[0];
  if (!account) return <Navigate to="/login" replace />;
  return children ? <>{children}</> : <Outlet />;
}
