import { SectionTitle } from '../Common/SectionTitle/SectionTitle';
import { AttachmentContainer, Container, Footer, StyledButton } from './styles';
import FileUpload from 'react-material-file-upload';
import { useTranslation } from 'react-i18next';

interface Props {
  files: File[];
  onFileUpload: () => void;
  setFiles: (files: File[]) => void;
  width?: string;
}

export const Dropzone = ({ files, onFileUpload, setFiles, width }: Props) => {
  const { t } = useTranslation();

  const newFilesTitle = t('attachments.new_files.title');

  const handleAttachFiles = () => {
    onFileUpload();
  };
  return (
    <Container width={width}>
      <SectionTitle title={newFilesTitle} />
      <AttachmentContainer>
        <FileUpload value={files} onChange={setFiles} multiple={false} />
        <Footer>
          <StyledButton variant="outlined" onClick={() => setFiles([])}>
            Cancel
          </StyledButton>
          <StyledButton disabled={!files.length} variant="contained" onClick={handleAttachFiles}>
            Confirm
          </StyledButton>
        </Footer>
      </AttachmentContainer>
    </Container>
  );
};
