import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { TextField, List, ListItem, Chip, Button, CircularProgress } from '@mui/material';
import { ChipContainer, Container, EmptyMessageText } from './styles';
import { SectionTitle } from '../Common/SectionTitle/SectionTitle';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '../../Hooks/useDebounce';
import { getSkills } from '../../Services/Skills';

interface SearchableChipBoxProps {
  initialSelectedChips: string[];
  handleSave: (selectedChips: string[]) => void;
  width?: string;
}

const SearchableChipBox = ({ initialSelectedChips, handleSave, width }: SearchableChipBoxProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedChips, setSelectedChips] = useState<string[]>(initialSelectedChips);

  const [skillsSearchTerm, setSkillsSearchTerm] = useState('');
  const debouncedSkillsSearchTerm = useDebounce(skillsSearchTerm, 200);

  const { data: skills, isLoading } = useQuery({
    queryKey: ['getSkills', debouncedSkillsSearchTerm],
    queryFn: () =>
      getSkills({
        page: 1,
        count: 10,
        name: debouncedSkillsSearchTerm,
      }),
    enabled: debouncedSkillsSearchTerm.length > 2,
  });

  useEffect(() => {
    if (skills) {
      setOptions(skills);
    }
  }, [skills]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSkillsSearchTerm(value);
  };

  const handleOptionSelect = (option: string) => {
    setSelectedChips([...selectedChips, option]);
    setSkillsSearchTerm('');
    setOptions([]);
  };

  const handleChipDelete = (chip: string) => {
    setSelectedChips(selectedChips.filter((chipx: string) => chipx !== chip));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % options.length);
    } else if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
    } else if (event.key === 'Enter') {
      const inputValueLower = skillsSearchTerm.toLowerCase();

      if (options[selectedIndex]) {
        handleOptionSelect(options[selectedIndex]);
      } else if (skillsSearchTerm && !options.length) {
        const isDuplicate = selectedChips.some((chip) => chip.toLowerCase() === inputValueLower);
        if (!isDuplicate) {
          setSelectedChips([...selectedChips, skillsSearchTerm]);
        }
        setSkillsSearchTerm('');
      }
    }
  };

  useEffect(() => {
    setSelectedChips(initialSelectedChips);
  }, [initialSelectedChips]);

  return (
    <Container width={width}>
      <SectionTitle title={t('common.skills')} handleEdit={() => setOnEdit(true)} />
      <ChipContainer>
        {selectedChips.length || onEdit ? (
          selectedChips.map((chip, index) => (
            <Chip
              key={index}
              label={chip}
              onDelete={onEdit ? () => handleChipDelete(chip) : undefined}
              sx={{ mr: 1, mb: 1 }}
            />
          ))
        ) : (
          <EmptyMessageText>{t('actions.no_elem', { elem: 'skills' })}</EmptyMessageText>
        )}
      </ChipContainer>
      {onEdit && (
        <>
          <TextField
            label={t('common.search')}
            value={skillsSearchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            fullWidth
            size="small"
            style={{ marginTop: 8 }}
          />
          {!isLoading && options.length > 0 && !!skillsSearchTerm && (
            <List sx={{ maxHeight: 150, overflow: 'auto', border: '1px solid #ccc' }}>
              {options.map((option, index) => (
                <ListItem
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option}
                </ListItem>
              ))}
            </List>
          )}
          {isLoading && <CircularProgress />}
          <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                setSelectedChips(initialSelectedChips);
                setOnEdit(false);
              }}
              variant="outlined"
              color="secondary"
              style={{ width: '48%' }}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              onClick={() => {
                const uniqueSelectedChips = Array.from(new Set(selectedChips));
                handleSave(uniqueSelectedChips);
                setSelectedChips(uniqueSelectedChips);
                setOnEdit(false);
              }}
              variant="contained"
              color="primary"
              style={{ marginRight: '8px', width: '48%' }}
            >
              {t('actions.save')}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default SearchableChipBox;
