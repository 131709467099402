import { format, parse } from 'date-fns';

export const formatDateTime = (dateTimeStr: string) => {
  const date = new Date(dateTimeStr);
  return format(date, 'yyyy-MM-dd');
};

export const unFormatDateTime = (dateStr: string) => {
  const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
  return parsedDate.toISOString();
};
