import { useQuery } from '@tanstack/react-query';
import { AttachmentsBox } from '../../../Components/AttachmentsBox/AttachmentsBox';
import { getCandidateJobFiles } from '../../../Services/CandidateJobs';

interface Props {
  candidateJob: CandidateJob;
}

export const CandidateJobAttachments = ({ candidateJob }: Props) => {
  const { data: candidateJobFiles } = useQuery({
    queryKey: ['getCandidateJobFiles'],
    queryFn: () =>
      getCandidateJobFiles(`${candidateJob.id}`, {
        page: 1,
        count: 100,
      }),
    enabled: !!candidateJob.id,
  });
  return (
    <AttachmentsBox
      title={`Attachments - ${candidateJob.jobPosition}`}
      files={candidateJobFiles || []}
    />
  );
};
