import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parse, format } from 'date-fns';

interface Props {
  startDate: string | null;
  setStartDate: (value: string | null) => void;
  endDate: string | null;
  setEndDate: (value: string | null) => void;
}

export const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }: Props) => {
  const parseDate = (dateString: string | null): Date | null => {
    return dateString ? parse(dateString, 'yyyy-MM-dd', new Date()) : null;
  };

  const formatDate = (date: Date | null): string | null => {
    return date ? format(date, 'yyyy-MM-dd') : null;
  };

  return (
    <div style={{ display: 'flex', gap: '16px' }}>
      <DatePicker
        sx={{ width: '170px' }}
        label="Start date"
        value={parseDate(startDate)}
        onChange={(newValue) => {
          setStartDate(formatDate(newValue));
        }}
      />
      <DatePicker
        sx={{ width: '170px' }}
        label="End date"
        value={parseDate(endDate)}
        onChange={(newValue) => {
          setEndDate(formatDate(newValue));
        }}
      />
    </div>
  );
};
