import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Footer, NoteContainer } from './styles';
import { useMutation } from '@tanstack/react-query';
import { deleteNote, editNote } from '../../Services/Notes';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ConfirmActionDialog } from '../Common/ConfirmActionDialog/ConfirmActionDialog';

interface Props {
  note?: Note;
  onSuccessEdit?: () => void;
}

export const Note = ({ note, onSuccessEdit }: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(note?.description || '');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const errorMessage = t('toast.errors.something_went_wrong');
  const deleteSuccessMessage = t('toast.success.delete', { elem: 'Note' });
  const editSuccessMessage = t('toast.success.edit', { elem: 'Note' });

  const confirmDelete = t('notes.delete.confirmation');

  const { mutate: deleteNoteMutation } = useMutation({
    mutationFn: () => deleteNote(note!.id!),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(deleteSuccessMessage);
      if (onSuccessEdit) onSuccessEdit();
    },
  });

  const { mutate: editNoteMutation } = useMutation({
    mutationFn: () => editNote(note!.id!, description),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(editSuccessMessage);
      if (onSuccessEdit) onSuccessEdit();
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    deleteNoteMutation();
    setIsEditing(false);
  };

  const handleSave = () => {
    editNoteMutation();
    setIsEditing(false);
  };

  const handleClose = () => {
    setIsEditing(false);
    setDescription(note?.description ?? '');
  };

  return (
    <NoteContainer>
      <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
        {isEditing ? (
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            autoFocus
          />
        ) : (
          <div style={{ flex: 1 }}>{description}</div>
        )}
        <div style={{ display: 'flex', gap: '8px', marginLeft: 5 }}>
          {isEditing ? (
            <>
              <IconButton style={{ marginTop: 0 }} color="primary" onClick={handleSave}>
                <CheckIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton style={{ marginTop: 0 }} color="primary" onClick={handleClose}>
                <CloseIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton style={{ marginTop: 0 }} color="primary" onClick={handleEdit}>
                <EditIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                style={{ marginTop: 0 }}
                color="primary"
                onClick={() => setShowConfirmDeleteDialog(true)}
              >
                <DeleteIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <Footer>
        {(note?.editDateTime || note?.creationDateTime) && (
          <span>
            {format(parseISO(note?.editDateTime || note?.creationDateTime!), 'yyyy-MM-dd')}
          </span>
        )}
        <span>{note?.author}</span>
      </Footer>
      <ConfirmActionDialog
        open={showConfirmDeleteDialog}
        handleClose={() => setShowConfirmDeleteDialog(false)}
        handleConfirm={handleDelete}
        description={confirmDelete}
      />
    </NoteContainer>
  );
};
