import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { Container, AddButton, Title } from './styles';

interface Props {
  title: string;
  handleAdd?: () => void;
  handleEdit?: () => void;
  padding?: string;
}

export const SectionTitle = ({ title, handleAdd, handleEdit, padding }: Props) => {
  return (
    <Container padding={padding}>
      <Title>{title}</Title>
      {!!handleAdd && (
        <AddButton onClick={handleAdd}>
          <AddIcon />
        </AddButton>
      )}
      {!!handleEdit && (
        <AddButton onClick={handleEdit}>
          <EditIcon style={{ height: 17, width: 17 }} />
        </AddButton>
      )}
    </Container>
  );
};
