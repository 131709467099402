import { useNavigate, Link } from 'react-router-dom';
import { CANDIDATES } from '../../../../Routing/paths';
import { StyledCell } from './styles';
import { Chip, IconButton, TableRow, Tooltip } from '@mui/material';
import { DashboardTable } from '../../../../Components/DashboardTable/DashboardTable';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import { AssignCandidateDialog } from './Components/AssignCandidateDialog';
import { useState } from 'react';
import { t } from 'i18next';

interface Props {
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

const headCells: HeadCell[] = [
  {
    id: 'fullName',
    numeric: true,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'linkedin',
    numeric: true,
    disablePadding: false,
    label: 'LinkedIn',
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Position',
  },
  {
    id: 'seniority',
    numeric: true,
    disablePadding: false,
    label: 'Seniority',
  },
  {
    id: 'englishLevel',
    numeric: true,
    disablePadding: false,
    label: 'English Level',
  },
  {
    id: 'skills',
    numeric: true,
    disablePadding: false,
    label: 'Skills',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

export const CandidateTable = ({
  currentPage,
  handlePageChange,
  rows,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
}: Props) => {
  const navigate = useNavigate();

  const [showAssignCandidatesModal, setShowAssignCandidatesModal] = useState(false);
  const [candidateToAssignId, setCandidateToAssignId] = useState();

  return (
    <DashboardTable
      headerCells={headCells}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      rows={rows}
      lastPage={lastPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItems={totalItems}
    >
      {rows?.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const candidateLink = `${CANDIDATES}/${row.id}`;

        return (
          <TableRow
            component={Link}
            to={candidateLink}
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
            onClick={() => navigate(candidateLink)}
          >
            <StyledCell>{row.fullName}</StyledCell>
            <StyledCell id={labelId} scope="row">
              {row.id}
            </StyledCell>
            <StyledCell>
              {row.linkedin && (
                <a href={row.linkedin ?? ''} onClick={(e) => e.stopPropagation()} target="_blank">
                  {t('common.go_to_profile')}
                </a>
              )}
            </StyledCell>
            <StyledCell>{row.position}</StyledCell>
            <StyledCell>{row.seniority}</StyledCell>
            <StyledCell>{row.englishLevel}</StyledCell>
            <StyledCell>
              {row.skills && row.skills.length > 0 ? (
                <>
                  {row.skills.slice(0, 2).map((skill: string) => (
                    <Chip label={skill} sx={{ marginRight: 1 }} key={skill} />
                  ))}
                  {row.skills.length > 2 && (
                    <Chip label={`+${row.skills.length - 2}`} sx={{ marginRight: 1 }} />
                  )}
                </>
              ) : (
                '-'
              )}
            </StyledCell>
            <StyledCell
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={t('jobs.assign.tooltip')}>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCandidateToAssignId(row.id);
                    setShowAssignCandidatesModal(true);
                  }}
                >
                  <NextWeekIcon />
                </IconButton>
              </Tooltip>
            </StyledCell>
          </TableRow>
        );
      })}
      <AssignCandidateDialog
        isOpen={showAssignCandidatesModal}
        handleClose={() => setShowAssignCandidatesModal(false)}
        onInvalidateQuery={() => null}
        candidateId={candidateToAssignId!}
      />
    </DashboardTable>
  );
};
