export const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="245" height="65" viewBox="0 0 499 151.238">
      <g id="Grupo_58" data-name="Grupo 58" transform="translate(-3782 -1599)">
        <rect
          id="Rectángulo_64"
          data-name="Rectángulo 64"
          width="499"
          height="151.238"
          transform="translate(3782 1599)"
          fill="none"
        />
        <g id="Grupo_57" data-name="Grupo 57" transform="translate(3839.464 1654.495)">
          <rect
            id="Rectángulo_15"
            data-name="Rectángulo 15"
            width="11.093"
            height="39.217"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Trazado_23"
            data-name="Trazado 23"
            d="M1164.923,801.845l-17.312-20.9h-9.132v39.217h10.869v-20.9l17.311,20.9h9.133V780.948h-10.869Z"
            transform="translate(-1119.488 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_24"
            data-name="Trazado 24"
            d="M1187,789.744h12.045v30.421h11.093V789.744h11.989v-8.8H1187Z"
            transform="translate(-1126.55 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_25"
            data-name="Trazado 25"
            d="M1243.944,804.422h17.423v-8.292h-17.423V789.52h19.777v-8.572h-30.758v39.217h31.486v-8.571h-20.5Z"
            transform="translate(-1133.24 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_26"
            data-name="Trazado 26"
            d="M1310.386,802.882a13.654,13.654,0,0,0,1.989-7.423,13.285,13.285,0,0,0-8.179-12.745,22.372,22.372,0,0,0-9.244-1.765h-17.928v39.217h11.092v-10.42h6.051l7.115,10.42h11.877L1304.7,807.84A13.451,13.451,0,0,0,1310.386,802.882Zm-10.951-3.193a7.758,7.758,0,0,1-5.155,1.484h-6.163V789.688h6.163a7.657,7.657,0,0,1,5.155,1.512,5.348,5.348,0,0,1,1.736,4.258A5.263,5.263,0,0,1,1299.435,799.689Z"
            transform="translate(-1139.653 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_27"
            data-name="Trazado 27"
            d="M1361.9,780.948l-13.446,22.633-13.782-22.633h-9.132v39.217H1335.8V799.94l9.565,15.6,2.712,4.6h.517l2.519-4.438,9.73-16.324.112,20.785h10.2l-.112-39.217Z"
            transform="translate(-1146.715 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_28"
            data-name="Trazado 28"
            d="M1399.075,804.422H1416.5v-8.292h-17.423V789.52h19.777v-8.572h-30.758v39.217h31.486v-8.571h-20.5Z"
            transform="translate(-1155.819 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_29"
            data-name="Trazado 29"
            d="M1462.128,783.357a25.977,25.977,0,0,0-11.428-2.409h-18.545v39.217H1450.7a25.954,25.954,0,0,0,11.428-2.409,18.147,18.147,0,0,0,7.732-6.835,20.942,20.942,0,0,0,0-20.729A18.159,18.159,0,0,0,1462.128,783.357Zm-3.782,25.1a11.369,11.369,0,0,1-8.1,2.856h-7V789.8h7a11.37,11.37,0,0,1,8.1,2.857,11.744,11.744,0,0,1,0,15.8Z"
            transform="translate(-1162.232 -780.948)"
            fill="#fff"
          />
          <rect
            id="Rectángulo_16"
            data-name="Rectángulo 16"
            width="11.093"
            height="39.217"
            transform="translate(316.2 0)"
            fill="#fff"
          />
          <path
            id="Trazado_30"
            data-name="Trazado 30"
            d="M1520.345,780.948l-17.311,39.217h11.317l11.358-28.3,11.388,28.3h11.541l-17.368-39.217Z"
            transform="translate(-1172.548 -780.948)"
            fill="#fff"
          />
          <path
            id="Trazado_31"
            data-name="Trazado 31"
            d="M1553.206,782.372c-3.478,4.523,1.684,9.684,6.207,6.207C1562.89,784.055,1557.729,778.894,1553.206,782.372Zm.633,5.574c-2.768-3.6,1.34-7.707,4.94-4.94C1561.547,786.6,1557.44,790.712,1553.839,787.945Z"
            transform="translate(-1179.684 -780.989)"
            fill="#fff"
          />
          <path
            id="Trazado_32"
            data-name="Trazado 32"
            d="M1558.078,786.078a1.145,1.145,0,0,0-.512-1.667,1.86,1.86,0,0,0-.764-.145h-1.481v3.075a.164.164,0,0,0,.165.165h.752v-.861h.5l.588.861h.981l-.7-1.019A1.11,1.11,0,0,0,1558.078,786.078Zm-.9-.264a.64.64,0,0,1-.427.123h-.508v-.949h.508a.635.635,0,0,1,.427.125.443.443,0,0,1,.143.352A.435.435,0,0,1,1557.174,785.814Z"
            transform="translate(-1180.158 -781.431)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
