import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMenu } from '../../../Hooks/useMenu';
import { useNavigate } from 'react-router-dom';
import { Logo } from './components/Logo';
import BasicPopover from '../../BasicPopover/BasicPopover';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CandidateFormDialog from './components/CandidateFormDialog';
import JobFormDialog from './components/JobFormDialog';
import SummarizeIcon from '@mui/icons-material/Summarize';
import * as paths from '../../../Routing/paths';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import toast from 'react-hot-toast';
import ClientFormDialog from './components/ClientFormDialog';
import { useAuthStore } from '../../../Stores/useAuthStore';
import { ROLES } from '../../../Types/constants';
import { useQueryClient } from '@tanstack/react-query';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const MainLayout = ({
  children,
  noPadding = false,
  onJobsInvalidateQuery,
  onClientsInvalidateQuery,
  onCandidatesInvalidateQuery,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  onJobsInvalidateQuery?: () => void;
  onClientsInvalidateQuery?: () => void;
  onCandidatesInvalidateQuery?: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { instance } = useMsal();
  const { menuItems } = useMenu();
  const navigate = useNavigate();
  const { setUser, user } = useAuthStore();

  const [open, setOpen] = useState(false);
  const [openCreateCandidate, setOpenCreateCandidate] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openCreateJob, setOpenCreateJob] = useState(false);

  const isRecruiter = !user?.roles.includes(ROLES.internal);

  const createCandidateTitle = t('candidates.create.title');
  const createJobTitle = t('jobs.create.title');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then(() => {
        setUser(null);
        navigate(paths.LOGIN);
      })
      .catch((e: Error) => toast.error(e.message));
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 1,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Logo />
          </div>
          {!user?.roles.includes(ROLES.internal) && (
            <Box display="flex" alignItems="center" gap={3}>
              <BasicPopover
                options={[
                  {
                    label: createJobTitle,
                    value: 'createJob',
                    onClick: () => setOpenCreateJob(true),
                  },
                  {
                    label: createCandidateTitle,
                    value: 'createCandidate',
                    onClick: () => setOpenCreateCandidate(true),
                  },
                  {
                    label: t('client.create'),
                    value: 'createClient',
                    onClick: () => setOpenCreateClient(true),
                  },
                ]}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ flexGrow: 1 }}>
          <List>
            {isRecruiter &&
              menuItems.map((item: MenuItem, index: number) => (
                <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => navigate(item.route)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            {!isRecruiter &&
              menuItems
                .filter((item: MenuItem) => item.id === 'planner')
                .map((item: MenuItem, index: number) => (
                  <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      onClick={() => navigate(item.route)}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
          {isRecruiter && (
            <Box>
              <Divider />
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() => navigate(paths.REPORTS)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('menu.reports')} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Box>
          )}
        </Box>
        <Box sx={{ mb: 2 }}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('menu.logout')} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: noPadding ? 0 : 3 }}>
        <DrawerHeader />
        {children}
      </Box>
      <CandidateFormDialog
        open={openCreateCandidate}
        handleClose={() => setOpenCreateCandidate(false)}
        onInvalidateQuery={
          onCandidatesInvalidateQuery ||
          (() =>
            queryClient.invalidateQueries({
              queryKey: ['getCandidates'],
            }))
        }
      />
      <JobFormDialog
        open={openCreateJob}
        handleClose={() => setOpenCreateJob(false)}
        onInvalidateQuery={
          onJobsInvalidateQuery ||
          (() =>
            queryClient.invalidateQueries({
              queryKey: ['getJobs'],
            }))
        }
      />
      <ClientFormDialog
        onInvalidateQuery={
          onClientsInvalidateQuery ||
          (() =>
            queryClient.invalidateQueries({
              queryKey: ['getClients'],
            }))
        }
        open={openCreateClient}
        handleClose={() => setOpenCreateClient(false)}
      />
    </Box>
  );
};
