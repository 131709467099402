import { Container } from './styles';
import { SectionTitle } from '../Common/SectionTitle/SectionTitle';
import { ReactNode } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;
  title: string;
  onEdit?: boolean;
  handleEdit?: () => void;
  handleAdd?: () => void;
  handleSave?: () => void;
  handleCancel?: () => void;
  fullWidth?: boolean;
}

export const DataBox = ({
  children,
  title,
  handleEdit,
  handleAdd,
  handleCancel,
  handleSave,
  onEdit,
  fullWidth,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Container width={fullWidth ? '100%' : '640px'}>
      <SectionTitle
        title={title}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        padding={fullWidth ? '5px 12px 5px 12px' : '12px'}
      />
      {children}
      {onEdit && (
        <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel} variant="outlined" style={{ width: '49%' }}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary" style={{ width: '49%' }}>
            {t('actions.save')}
          </Button>
        </div>
      )}
    </Container>
  );
};
