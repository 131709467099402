import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../../Stores/useAuthStore';
import { ROLES } from '../../Types/constants';

export default function ProtectedRouteRole({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const { user } = useAuthStore();
  if (user && user.roles.includes(ROLES.internal)) return <Navigate to="/planner" replace />;
  return children ? <>{children}</> : <Outlet />;
}
