import { useEffect, useState } from 'react';
import {
  initialMessageSentSubStatusOptions,
  InProgressClientSubStatusOptions,
  InProgressTalentAcquisitionSubStatusOptions,
  NotSelectedSubStatusOptions,
  RejectedProposalSubStatusOptions,
} from '../Types/constants';

export const useCandidateJobSubStatus = (
  candidateJobStatus: string,
  setCandidateJobSubStatus: (newValue: string) => void,
  initialSubStatus?: string,
) => {
  const [candidateJobSubStatusOptions, setCandidateJobSubStatusOptions] = useState<
    { label: string; value: string }[]
  >([{ label: 'All', value: 'all' }]);

  useEffect(() => {
    let subStatusOptions = [{ label: 'All', value: 'all' }];
    let defaultSubStatus = 'all';

    const filterOptions = (options: { label: string; value: string }[]) => {
      return options.filter((option) => option.value !== 'all');
    };

    switch (candidateJobStatus) {
      case 'InitialMessageSent':
        subStatusOptions = filterOptions(initialMessageSentSubStatusOptions);
        break;
      case 'InProgressTalentAcquisition':
        subStatusOptions = filterOptions(InProgressTalentAcquisitionSubStatusOptions);
        break;
      case 'NotSelected':
        subStatusOptions = filterOptions(NotSelectedSubStatusOptions);
        break;
      case 'InProgressClient':
        subStatusOptions = filterOptions(InProgressClientSubStatusOptions);
        break;
      case 'RejectedProposal':
        subStatusOptions = filterOptions(RejectedProposalSubStatusOptions);
        break;
      case 'Hired':
      case 'all':
      default:
        subStatusOptions = [{ label: 'All', value: 'all' }];
        break;
    }

    // Si se proporciona un initialSubStatus válido, se usa, sino se toma el primero del array.
    if (initialSubStatus && subStatusOptions.some((option) => option.value === initialSubStatus)) {
      defaultSubStatus = initialSubStatus;
    } else if (subStatusOptions.length > 0) {
      defaultSubStatus = subStatusOptions[0].value;
    }

    setCandidateJobSubStatusOptions(subStatusOptions);
    setCandidateJobSubStatus(defaultSubStatus);
  }, [candidateJobStatus, initialSubStatus]);

  return { candidateJobSubStatusOptions };
};
