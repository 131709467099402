import ExpandableCard from '../../Components/ExpandableCard/ExpandableCard';

export const Interviews = ({
  interviews,
  handleSaveInterview,
  handleDelete,
}: {
  interviews: Interview[];
  handleSaveInterview: (x: Interview) => void;
  handleDelete: (id?: string) => void;
}) => {
  return (
    <>
      {interviews.map((interview: Interview) => (
        <ExpandableCard
          key={interview.id}
          interview={interview}
          onSave={handleSaveInterview}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
};
