import { Switch as SwitchMui, FormControlLabel } from '@mui/material';

const Switch = ({ checked, onChange }: { checked: boolean; onChange: (e: boolean) => void }) => {
  const handleChange = (event: any) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<SwitchMui checked={checked} onChange={handleChange} color="primary" />}
      label={checked ? 'Yes' : 'No'}
    />
  );
};

export default Switch;
