import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HeaderCell } from './styles';

interface EnhancedTableProps {
  headCells: HeadCell[];
}

export const TableHeader = ({ headCells }: EnhancedTableProps) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <HeaderCell key={headCell.id}>{headCell.label}</HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
