import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import theme from '../../theme';

export const StyledAddIconButton = styled(IconButton)(() => ({
  color: theme.palette.primary.main,
  backgroundColor: 'white',
  marginRight: 5,
  ':hover': {
    color: 'white',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
}));
