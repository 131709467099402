import React, { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styles';

interface Props {
  title: string;
  editable?: boolean;
  defaultExpanded?: boolean;
  editDescription?: string;
  setEditDescription: (newValue: string) => void;
}

export const DetailedCardAccordion = ({
  title,
  editable = false,
  defaultExpanded = false,
  editDescription,
  setEditDescription,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <StyledAccordion expanded={expanded} onChange={handleAccordionChange}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography noWrap>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {editable ? (
          <TextField
            fullWidth
            multiline
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            variant="outlined"
          />
        ) : (
          <Typography>{editDescription}</Typography>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
