import { Link, useNavigate } from 'react-router-dom';
import { JOBS } from '../../../../Routing/paths';
import { StyledCell } from './styles';
import { Box, Chip, TableRow } from '@mui/material';
import { ChipStatus } from '../../../../Components/ChipStatus.tsx/ChipStatus';
import { DashboardTable } from '../../../../Components/DashboardTable/DashboardTable';

const headCells: HeadCell[] = [
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Position name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Client',
  },
  {
    id: 'headcount',
    numeric: true,
    disablePadding: false,
    label: 'Headcount',
  },
  {
    id: 'skills',
    numeric: true,
    disablePadding: false,
    label: 'Skills',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Job status',
  },
];
interface JobsTableProps {
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

export const JobsTable = ({
  currentPage,
  handlePageChange,
  rows,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
}: JobsTableProps) => {
  const navigate = useNavigate();

  return (
    <DashboardTable
      headerCells={headCells}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      rows={rows}
      lastPage={lastPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItems={totalItems}
    >
      {rows.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const jobLink = `${JOBS}/${row.id}`;
        return (
          <TableRow
            component={Link}
            to={jobLink}
            hover
            onClick={() => navigate(jobLink)}
            tabIndex={-1}
            key={row.id}
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            <StyledCell>{row.position}</StyledCell>
            <StyledCell id={labelId} scope="row">
              {row.id}
            </StyledCell>
            <StyledCell>{row?.client?.name}</StyledCell>
            <StyledCell>{row.headcount}</StyledCell>
            <StyledCell>
              <Box display="flex" alignItems="center">
                {row.skills && row.skills.length > 0 ? (
                  <>
                    {row.skills.slice(0, 2).map((skill: string) => (
                      <Chip label={skill} sx={{ marginRight: 1 }} key={skill} />
                    ))}
                    {row.skills.length > 2 && (
                      <Chip label={`+${row.skills.length - 2}`} sx={{ marginRight: 1 }} />
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Box>
            </StyledCell>
            <StyledCell>
              <ChipStatus status={row.status} />
            </StyledCell>
          </TableRow>
        );
      })}
    </DashboardTable>
  );
};
