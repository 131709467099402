import { Select, MenuItem, styled, FormControl, InputLabel } from '@mui/material';

const CustomSelect = styled(Select)(({ theme }) => ({
  marginLeft: '10px',
  padding: '8px 16px',
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
}));

const StatusMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  borderRadius: '0',
  '&:hover': {
    borderRadius: '0',
  },
}));

interface Props {
  handleChange: (newValue: string) => void;
  status: string;
  label?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
}

export const StatusSelect = ({ handleChange, status, label, options, disabled = false }: Props) => {
  return (
    <FormControl variant="outlined">
      {label && <InputLabel>{label}</InputLabel>}
      <CustomSelect
        disabled={disabled}
        value={status}
        onChange={(e) => handleChange(e.target.value as string)}
        label={label}
      >
        {options.map((option) => (
          <StatusMenuItem key={option.value} value={option.value}>
            {option.label}
          </StatusMenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};
