import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import FormDialogContainer from '../../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../../DialogActionButtons/DialogActionButtons';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { createClient } from '../../../../Services/Client';
import { createClientSchema } from '../../../../Utils/schemas';

interface ClientFormDialogProps {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (newClient: Client) => void;
  onInvalidateQuery?: () => void;
}

export default function ClientFormDialog({
  open,
  handleClose,
  onSuccess,
  onInvalidateQuery,
}: ClientFormDialogProps) {
  const { t } = useTranslation();

  const nameLabel = t('client.name');
  const typeLabel = t('client.type');

  const createClientTitle = t('client.create');
  const createClientDescription = t('client.description');

  const toastErrorMessage = t('toast.errors.something_went_wrong');
  const toastSuccessMessage = t('toast.success.create', { elem: 'Client' });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    register,
    reset,
  } = useForm<z.infer<typeof createClientSchema>>({
    resolver: zodResolver(createClientSchema),
  });

  const { mutate: createClientMutation } = useMutation({
    mutationFn: (data: Client) => createClient(data),
    onError: () => {
      toast.error(toastErrorMessage);
    },
    onSuccess: (newClient) => {
      toast.success(toastSuccessMessage);
      handleClose();
      if (onInvalidateQuery) {
        onInvalidateQuery();
      }
      onSuccess?.(newClient);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof createClientSchema>> = (data) => {
    createClientMutation(data);
    reset();
    handleClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue('type', event.target.value as 'Internal' | 'External');
  };

  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={createClientTitle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText>{createClientDescription}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={nameLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('name')}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="type-label">{typeLabel}</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              label={typeLabel}
              {...register('type')}
              error={!!errors.type}
              onChange={handleChange}
            >
              <MenuItem value="Internal">{'Internal'}</MenuItem>
              <MenuItem value="External">{'External'}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActionButtons
          onCancel={() => {
            handleClose();
            reset();
          }}
        />
      </form>
    </FormDialogContainer>
  );
}
