import { AddButton, InterviewHeaderDiv } from './styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataBoxTable } from '../../Components/DataBoxTable/DataBoxTable';
import { Interviews } from './Interviews';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import { Results } from './Results';
import { useCandidateJob } from '../../Hooks/useCandidateJob';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { CandidateJobStatusDialog } from '../../Components/Dialogs/CandidateJobStatusDialog/CandidateJobStatusDialog';
import { generatePath, useNavigate } from 'react-router-dom';
import * as paths from '../../Routing/paths';

export const CandidateJobs = ({
  candidateJob,
  updateCandidateJob,
}: {
  candidateJob: CandidateJob;
  updateCandidateJob: (j: CandidateJob) => void;
}) => {
  const navigate = useNavigate();
  const candidateJobsData = useCandidateJob({
    candidateJob: candidateJob,
    updateCandidateJob: updateCandidateJob,
  });
  const {
    expanded,
    setExpanded,
    interviewsExpanded,
    setInterviewsExpanded,
    interviews,
    handleAddInterview,
    generalFormData,
    englishFormData,
    technicalFormData,
    handleSaveInterview,
    handleDeleteInterview,
  } = candidateJobsData;

  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const convertCamelCaseToSentence = (camelCaseStr: string): string => {
    const spacedStr = camelCaseStr.replace(/([a-z])([A-Z])/g, '$1 $2');
    return spacedStr.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Accordion
      defaultExpanded
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      style={{
        margin: 0,
        paddingBottom: expanded ? 40 : 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{
          backgroundColor: '#d1d4d7',
          paddingLeft: 12,
          fontWeight: 'bold',
          color: 'black',
          fontSize: 18,
          marginBottom: 10,
          display: 'flex',
          width: '100%',
        }}
      >
        <Box
          onClick={(e) => {
            e.stopPropagation();
            navigate(generatePath(paths.JOB_DETAIL, { jobId: `${candidateJob.jobId}` }));
          }}
        >
          <Tooltip placement="top" title={t('candidate_jobs.title_tooltip')}>
            <Box
              component="span"
              sx={{
                width: '50%',
                alignSelf: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {candidateJob?.jobPosition}
            </Box>
          </Tooltip>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="end"
          gap={2}
          marginRight={1}
          sx={{ flexGrow: 1 }}
        >
          <Chip
            label={
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowUpdateStatusModal(true);
                }}
              >
                <span>
                  {convertCamelCaseToSentence(candidateJob.generalData.status)}
                  {candidateJob.generalData.subStatus &&
                    ` - ${convertCamelCaseToSentence(candidateJob.generalData.subStatus)}`}
                </span>

                {candidateJob.generalData.status !== 'Hired' && (
                  <IconButton
                    size="small"
                    sx={{
                      p: 0,
                      width: 24,
                      height: 24,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <Edit sx={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </Box>
            }
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#f4f4f4',
              },
            }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <DataBoxTable title="General" {...generalFormData} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DataBoxTable title="English Proficiency" {...englishFormData} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DataBoxTable title="Technical Skills" {...technicalFormData} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion
              expanded={interviewsExpanded}
              onChange={() => setInterviewsExpanded(!interviewsExpanded)}
              style={{ boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  backgroundColor: '#e9ecef',
                  paddingLeft: 12,
                  fontWeight: 'bold',
                  fontSize: 18,
                  margin: '0px 0px 5px 5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: 40,
                  minHeight: 40,
                }}
              >
                {
                  <InterviewHeaderDiv>
                    {t('common.interviews')}
                    {
                      <AddButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleAddInterview();
                        }}
                      >
                        <AddIcon style={{ height: 25, width: 25 }} />
                      </AddButton>
                    }
                  </InterviewHeaderDiv>
                }
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                    gap: '10px',
                  }}
                >
                  <Interviews
                    interviews={interviews}
                    handleSaveInterview={handleSaveInterview}
                    handleDelete={handleDeleteInterview}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Results candidateJobsData={candidateJobsData} candidateJob={candidateJob} />
          </Grid>
        </Grid>
      </AccordionDetails>
      <CandidateJobStatusDialog
        candidateJob={candidateJob}
        open={showUpdateStatusModal}
        handleClose={() => setShowUpdateStatusModal(false)}
      />
    </Accordion>
  );
};
