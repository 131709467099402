import './App.css';
import { Router } from './Routing/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

export const msalConfig = {
  // TODO: Update to environment variable
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || 'default-client-id',
    authority: process.env.REACT_APP_AUTHORITY || 'default-authority',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const queryClient = new QueryClient();

  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router />
          <Toaster />
        </LocalizationProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
