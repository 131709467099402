import styled from '@emotion/styled';
import { Select, TextField } from '@mui/material';

export const TextEditor = styled(TextField)(() => ({
  padding: 0,
  '& input': {
    padding: 5,
    fontSize: 15,
  },
}));

export const SelectEditor = styled(Select)(() => ({
  '& .MuiSelect-select': {
    padding: 5,
    fontSize: 15,
  },
}));
