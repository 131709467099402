import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  editCandidate,
  editCandidateSkills,
  getCandidateById,
  getCandidateFiles,
  getCandidateSkills,
  uploadCandidateFile,
} from '../Services/Candidates';
import { CandidateSchema } from '../Utils/schemas';

export const useCandidate = (defaultTab: number) => {
  const { candidateId: id } = useParams();
  const queryClient = useQueryClient();

  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<Candidate>();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const { data: candidateData } = useQuery({
    queryKey: ['getCandidateById', id],
    queryFn: () => getCandidateById(id),
    enabled: !!id,
  });

  const { data: candidateSkills } = useQuery({
    queryKey: ['getCandidateSkills', id],
    queryFn: () => getCandidateSkills(Number(id)),
  });

  const { mutate: editCandidateMutation } = useMutation({
    mutationFn: (data: Candidate) => editCandidate(id, data),
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      toast.success(t('toast.success.edit', { elem: 'Candidate' }));
      setOnEdit(false);
      queryClient.invalidateQueries({ queryKey: ['getCandidateById'] });
    },
  });

  const { mutate: editSkillsMutation } = useMutation({
    mutationFn: (skills: string[]) => editCandidateSkills(id, skills),
    onSuccess: () => {
      toast.success(t('toast.success.edit', { elem: 'Skills' }));

      queryClient.invalidateQueries({ queryKey: ['getCandidateSkills', id] });
    },
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
  });

  const { data: candidateFiles } = useQuery({
    queryKey: ['getCandidateFiles'],
    queryFn: () =>
      getCandidateFiles(id!, {
        page: 1,
        count: 100,
      }),
    enabled: !!id,
  });

  const { mutate: uploadCandidateFileMutation } = useMutation({
    mutationFn: (data: FormData) => uploadCandidateFile(id, data),
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      toast.success(t('toast.success.upload'));
      setFiles([]);
      queryClient.invalidateQueries({ queryKey: ['getCandidateFiles'] });
    },
  });

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadCandidateFileMutation(formData);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof CandidateSchema>>({
    resolver: zodResolver(CandidateSchema),
  });

  useEffect(() => {
    if (candidate) {
      reset(candidate);
    }
  }, [candidate, reset]);

  useEffect(() => {
    if (candidateData) {
      const updatedCandidate = {
        ...candidateData,
        lastContactDateTime: candidateData.lastContactDateTime?.split('T')[0] ?? '',
      };
      setCandidate(updatedCandidate);
    }
  }, [candidateData]);

  useEffect(() => {
    if (candidateSkills) {
      setSelectedSkills(candidateSkills.skills);
    }
  }, [candidateSkills]);

  const onSubmit = (data: z.infer<typeof CandidateSchema>) => {
    const editedCandidate: Candidate = {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone || '',
      city: data.city || '',
      country: data.country || '',
      linkedin: data.linkedin || '',
      lastContactDateTime: data.lastContactDateTime || null,
      address: data.address || '',
    };
    editCandidateMutation(editedCandidate);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return {
    onEdit,
    setOnEdit,
    candidate,
    selectedTab,
    control,
    handleSubmit,
    errors,
    onSubmit,
    handleChange,
    selectedSkills,
    handleSkillsSave: editSkillsMutation,
    candidateFiles,
    files,
    setFiles,
    handleFileUpload,
  };
};
