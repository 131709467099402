import { format, parseISO } from 'date-fns';
import { instance, ServiceResponse } from './Axios';
import { StatusType } from '../Types/constants';

export interface CreateJobData {
  position: string;
  clientId?: string;
  headcount?: number;
  seniority?: string;
  confidential?: boolean;
}
export interface CandidateSource {
  candidateId: string;
  candidateSource: string;
  sourceDetail?: string;
}

export const getJobs = async (params: {
  page: number;
  count: number;
  position?: string;
  status?: StatusType;
  fromCreationDate?: string | null;
  toCreationDate?: string | null;
  skills?: string[];
  clientId?: string;
  userId?: string;
}) => {
  const searchParams = new URLSearchParams({
    page: params.page.toString(),
    count: params.count.toString(),
    position: params.position || '',
    status: params.status || '',
    fromCreationDate: params.fromCreationDate || '',
    toCreationDate: params.toCreationDate || '',
    clientId: params.clientId || '',
    userId: params.userId || '',
  });

  if (params.skills) {
    params.skills.forEach((skill) => searchParams.append('skills', skill));
  }

  const url = `/jobs?${searchParams.toString()}`;

  const response = await instance.get<ServiceResponse<Job[]>>(url);
  return response.data;
};

export const createJob = async (job: CreateJobData) => {
  const response = await instance.post<Job>('/jobs', job);
  return response.data;
};

export const getJobById = async (id?: string) => {
  const response = await instance.get<Job>(`/jobs/${id}`);
  const item = response.data;
  return {
    ...item,
    requestedDateTime: item.requestedDateTime
      ? format(parseISO(item.requestedDateTime), 'yyyy-MM-dd')
      : '',
    closeDateTime: item.closeDateTime ? format(parseISO(item.closeDateTime), 'yyyy-MM-dd') : '',
    creationDateTime: item.creationDateTime
      ? format(parseISO(item.creationDateTime), 'yyyy-MM-dd')
      : '',
    expectedStartDateTime: item.expectedStartDateTime
      ? format(parseISO(item.expectedStartDateTime), 'yyyy-MM-dd')
      : '',
  };
};
export const editJob = async (id?: number, data?: CreateJobData) => {
  const response = await instance.put(`/jobs/${id}`, data);
  return response.data;
};

export const uploadJobFile = async (id?: string, file?: FormData) => {
  const response = await instance.post(`/jobs/${id}/files`, file);
  return response.data;
};

export const getJobFiles = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<AttachmentFile[]>>(`/jobs/${id}/files`, {
    params,
  });
  return response.data.items;
};

export const assignCandidateToJob = async (id: string, data: CandidateSource) => {
  const response = await instance.post(`/jobs/${id}/candidates`, data);
  return response.data;
};

export const getJobCandidates = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<Candidate[]>>(`/jobs/${id}/candidates`, {
    params,
  });
  return response.data.items;
};

export const getJobNotes = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<Note[]>>(`/jobs/${id}/notes`, { params });
  return response.data.items;
};

export const createJobNote = async (
  id: string,
  params: { description: string; userId: number },
) => {
  const response = await instance.post(`/jobs/${id}/notes`, params);
  return response.data;
};

export const getJobStatus = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<JobStatusItem[]>>(`/jobs/${id}/status`, {
    params,
  });
  return response.data.items.map((item) => ({
    ...item,
    dateTime: item.dateTime ? format(parseISO(item.dateTime), 'yyyy-MM-dd') : '',
  }));
};

export const updateJobStatus = async (
  id: string,
  params: { date: string; status: StatusType; comment?: string },
) => {
  const response = await instance.put(`/jobs/${id}/status`, params);
  return response.data;
};

export const getMetrics = async (id: string) => {
  const response = await instance.get<JobMetricsType>(`/jobs/${id}/metrics`);
  return response.data;
};

export const getJobSkills = async (id: number) => {
  const response = await instance.get(`/jobs/${id}/skills`);
  return response.data;
};

export const editJobSkills = async (id?: string, data?: string[]) => {
  const response = await instance.put(`/jobs/${id}/skills`, { skills: data });
  return response.data;
};

export const getJobUsers = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<User[]>>(`/jobs/${id}/users`, { params });
  return response.data;
};

export const assignUserToJob = async (jobId: string, userId: string) => {
  const response = await instance.post(`/jobs/${jobId}/users/${userId}`);
  return response.data;
};

export const unAssignUserFromJob = async (jobId: string, userId: string) => {
  const response = await instance.delete(`/jobs/${jobId}/users/${userId}`);
  return response.data;
};

export const generateReport = async (id?: string) => {
  const response = await instance.post(`/jobs/${id}/report`);
  return response.data;
};

export const getJobReports = async (params: { jobId: string; page: number; count: number }) => {
  const response = await instance.get<ServiceResponse<JobReport[]>>(`/reports`, {
    params,
  });
  return response.data;
};
