import { Box, Button } from '@mui/material';
import toast from 'react-hot-toast';
import { useAuthStore } from '../../Stores/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import * as paths from '../../Routing/paths';
import { useTranslation } from 'react-i18next';

export const UserError = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { setUser } = useAuthStore();

  const title = t('user_error.title');
  const logout = t('user_error.logout');

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then(() => {
        setUser(null);
        navigate(paths.LOGIN);
      })
      .catch((e: Error) => toast.error(e.message));
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={5}
      paddingTop={10}
    >
      <h3>{title}</h3>
      <Button variant="contained" color="primary" onClick={handleLogout}>
        {logout}
      </Button>
    </Box>
  );
};
