import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { Box, Tab, Typography } from '@mui/material';
import { DataBoxTable } from '../../Components/DataBoxTable/DataBoxTable';
import { NotesBox } from '../../Components/NotesBox/NotesBox';
import {
  AttachmentsContainer,
  Container,
  StyledAvatar,
  SubTitle,
  TabsContainer,
  Title,
  TitleContainer,
} from './styles';
import SearchableChipBox from '../../Components/SearchableChipBox/SearchableChipBox';
import { AttachmentsBox } from '../../Components/AttachmentsBox/AttachmentsBox';
import { Dropzone } from '../../Components/Dropzone/Dropzone';
import { useCandidate } from '../../Hooks/useCandidate';
import { getInitials } from '../../Utils';
import { candidateDetailsFields } from '../../Utils/fields';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createCandidateNote } from '../../Services/Candidates';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { CandidateJobList } from './CandidateJobList';
import { getCandidateJobs } from '../../Services/CandidateJobs';
import { CandidateJobAttachments } from './Components/CandidateJobAttachments';
import { useAuthStore } from '../../Stores/useAuthStore';

interface Props {
  defaultTab: number;
}

export const CandidateDetail = ({ defaultTab }: Props) => {
  const { t } = useTranslation();
  const { candidateId } = useParams();
  const { user } = useAuthStore();
  const queryClient = useQueryClient();
  const {
    onEdit,
    setOnEdit,
    candidate,
    selectedTab,
    control,
    handleSubmit,
    errors,
    onSubmit,
    handleChange,
    selectedSkills,
    handleSkillsSave,
    candidateFiles,
    files,
    setFiles,
    handleFileUpload,
  } = useCandidate(defaultTab);

  const errorMessage = t('toast.errors.something_went_wrong');

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const { data: jobsData } = useQuery({
    queryKey: ['getCandidateJobs', candidateId],
    queryFn: () => getCandidateJobs({ page: 1, count: 50, candidateId: candidateId }),
    enabled: !!candidateId,
  });

  const { mutate: createCandidateNoteMutation } = useMutation({
    mutationFn: (description: string) =>
      createCandidateNote(candidateId!, { description, userId: user?.id! }),
    onError: (error: any) => {
      toast.error(errorMessage);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getCandidateById'] });
    },
  });

  return (
    <MainLayout noPadding>
      {candidate && (
        <div>
          <Container>
            <StyledAvatar>{getInitials(candidate?.fullName)}</StyledAvatar>
            <TitleContainer>
              <Title>{candidate?.fullName}</Title>
              {candidate.city && candidate.country && (
                <SubTitle>
                  <LocationOnIcon style={{ marginRight: '8px' }} />
                  {candidate?.city + ', ' + candidate?.country}
                </SubTitle>
              )}
            </TitleContainer>
          </Container>
          <TabsContainer
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Jobs" {...a11yProps(1)} />
            <Tab label="Notes" {...a11yProps(2)} />
            <Tab label="Attachments" {...a11yProps(3)} />
          </TabsContainer>
          {selectedTab === 0 && (
            <div style={{ display: 'flex', padding: '20px' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DataBoxTable
                  title="Candidate details"
                  fields={candidateDetailsFields}
                  control={control}
                  values={candidate}
                  handleSave={() => handleSubmit(onSubmit)()}
                  handleEdit={() => setOnEdit(true)}
                  onEdit={onEdit}
                  handleCancel={() => setOnEdit(false)}
                  errors={errors}
                />
              </form>
              <div>
                <SearchableChipBox
                  initialSelectedChips={selectedSkills}
                  handleSave={handleSkillsSave}
                />
              </div>
            </div>
          )}
          {selectedTab === 1 && <CandidateJobList />}
          {selectedTab === 2 && (
            <NotesBox
              onSuccessEdit={() =>
                queryClient.invalidateQueries({ queryKey: ['getCandidateById'] })
              }
              containerWidth="40%"
              createNote={createCandidateNoteMutation}
              notes={candidate.notes}
            />
          )}
          {selectedTab === 3 && (
            <AttachmentsContainer>
              <Box>
                <AttachmentsBox title="Attachments - candidate" files={candidateFiles || []} />
                <Box display="flex" justifyContent="end" sx={{ paddingRight: 1 }}>
                  <Typography variant="body2" color="gray">
                    Candidate jobs files will also be displayed in planner details
                  </Typography>
                </Box>
                {jobsData?.items.map((job) => <CandidateJobAttachments candidateJob={job} />)}
              </Box>
              <Dropzone onFileUpload={handleFileUpload} files={files} setFiles={setFiles} />
            </AttachmentsContainer>
          )}
        </div>
      )}
    </MainLayout>
  );
};
