import { useEffect, useState } from 'react';
import { CandidateJobStatus } from '../Types/constants';

const usePlannerCandidateJobs = (candidateJobs: CandidateJob[]) => {
  const [inProgressTalentAcquisitionCandidates, setInProgressTalentAcquisitionCandidates] =
    useState<CandidateJob[]>([]);
  const [inProgressInternalClientCandidates, setInProgressInternalClientCandidates] = useState<
    CandidateJob[]
  >([]);
  const [inProgressExternalClientCandidates, setInProgressExternalClientCandidates] = useState<
    CandidateJob[]
  >([]);
  const [preSelectedCandidates, setPreSelectedCandidates] = useState<CandidateJob[]>([]);
  const [notSelectedInternalClientCandidates, setNotSelectedInternalClientCandidates] = useState<
    CandidateJob[]
  >([]);
  const [notSelectedExternalClientCandidates, setNotSelectedExternalClientCandidates] = useState<
    CandidateJob[]
  >([]);
  const [hiredCandidates, setHiredCandidates] = useState<CandidateJob[]>([]);

  useEffect(() => {
    if (candidateJobs) {
      setInProgressTalentAcquisitionCandidates(
        candidateJobs?.filter(
          (job) => job.generalData.status === CandidateJobStatus.InProgressTalentAcquisition,
        ),
      );
      setInProgressInternalClientCandidates(
        candidateJobs?.filter(
          (job) =>
            job.generalData.status === CandidateJobStatus.InProgressClient &&
            job.generalData.subStatus === 'Internal',
        ),
      );
      setInProgressExternalClientCandidates(
        candidateJobs?.filter(
          (job) =>
            job.generalData.status === CandidateJobStatus.InProgressClient &&
            job.generalData.subStatus === 'External',
        ),
      );
      setPreSelectedCandidates(
        candidateJobs?.filter(
          (job) =>
            job.generalData.status === CandidateJobStatus.InProgressClient &&
            job.generalData.subStatus === 'PreSelected',
        ),
      );
      setNotSelectedInternalClientCandidates(
        candidateJobs?.filter(
          (job) =>
            job.generalData.status === CandidateJobStatus.NotSelected &&
            job.generalData.subStatus === 'ByInternalClient',
        ),
      );
      setNotSelectedExternalClientCandidates(
        candidateJobs?.filter(
          (job) =>
            job.generalData.status === CandidateJobStatus.NotSelected &&
            job.generalData.subStatus === 'ByExternalClient',
        ),
      );
      setHiredCandidates(
        candidateJobs?.filter((job) => job.generalData.status === CandidateJobStatus.Hired),
      );
    }
  }, [candidateJobs]);

  return {
    inProgressTalentAcquisitionCandidates,
    inProgressInternalClientCandidates,
    inProgressExternalClientCandidates,
    preSelectedCandidates,
    notSelectedInternalClientCandidates,
    notSelectedExternalClientCandidates,
    hiredCandidates,
  };
};

export default usePlannerCandidateJobs;
