import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const Container = styled.div<{ width?: string }>(({ width = '600px' }) => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  width,
  margin: 5,
}));

export const AttachmentContainer = styled.div(() => ({
  padding: '10px',
  border: '1px solid #ddd',
  marginBottom: '10px',
  borderRadius: '5px',
  backgroundColor: '#f9f9f9',
  marginTop: 5,
}));

export const Footer = styled.div(() => ({
  marginTop: '10px',
  fontSize: '12px',
  color: '#888',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledButton = styled(Button)({
  width: '49%',
});
