import { IconButton, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { DataBox } from '../../Components/DataBox/DataBox';
import { DataBoxTable } from '../../Components/DataBoxTable/DataBoxTable';
import { NotesBox } from '../../Components/NotesBox/NotesBox';
import { t } from 'i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { getUsers } from '../../Services/Users';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import UserFormDialog from './Components/UserFormDialog';
import { ConfirmActionDialog } from '../../Components/Common/ConfirmActionDialog/ConfirmActionDialog';
import toast from 'react-hot-toast';
import {
  assignUserToCandidateJob,
  unAssignUserFromCandidateJob,
} from '../../Services/CandidateJobs';

export const Results = ({
  candidateJobsData,
  candidateJob,
}: {
  candidateJobsData: any;
  candidateJob: CandidateJob;
}) => {
  const queryClient = useQueryClient();
  const { resultsFormData, notesData, handleAddNote, handleSuccessEditNote } = candidateJobsData;
  const [openUserForm, setOpenUserForm] = useState(false);
  const [showConfirmUnassignDialog, setShowConfirmUnassignDialog] = useState(false);

  const errorMessage = t('toast.errors.something_went_wrong');
  const assignSuccessMessage = t('jobs.team.assign_user_success_message');
  const unassignSuccessMessage = t('jobs.team.unassign_user_success_message');

  const { data: users } = useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const response = await getUsers({
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const { mutate: assignUserMutation } = useMutation({
    mutationFn: (userId: string) => assignUserToCandidateJob(`${candidateJob.id}`, userId),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(assignSuccessMessage);
      setOpenUserForm(false);
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({
        queryKey: ['getCandidateJobs', `${candidateJob.candidateId}`],
      });
    },
  });

  const { mutate: unAssignUserMutation } = useMutation({
    mutationFn: () => unAssignUserFromCandidateJob(`${candidateJob.id}`, candidateJob.user!.id!),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(unassignSuccessMessage);
      setOpenUserForm(false);
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({
        queryKey: ['getCandidateJobs', `${candidateJob.candidateId}`],
      });
    },
  });

  const handleUserAdd = (user: User) => {
    assignUserMutation(user.id!);
    setOpenUserForm(false);
  };

  const handleUnassignConfirm = () => {
    unAssignUserMutation();
    setShowConfirmUnassignDialog(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '380px', marginRight: 10 }}>
        <DataBoxTable title="Results" {...resultsFormData} fullWidth />
      </div>
      <NotesBox
        notes={notesData}
        createNote={handleAddNote}
        onSuccessEdit={handleSuccessEditNote}
        paddingTitle="5px 12px 5px 12px"
      />
      <DataBox
        title={t('candidate_jobs.recruiter_responsible.title')}
        handleAdd={() => setOpenUserForm(true)}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableRow style={{ height: '55px' }}>
              <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                {candidateJob.user?.fullName ||
                  t('candidate_jobs.recruiter_responsible.not_assigned')}
              </TableCell>
              {candidateJob.user && (
                <TableCell component="th" scope="row" style={{ textAlign: 'right' }}>
                  <IconButton onClick={() => setShowConfirmUnassignDialog(true)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </Table>
        </TableContainer>
      </DataBox>
      <UserFormDialog
        open={openUserForm}
        handleClose={() => setOpenUserForm(false)}
        users={users ?? []}
        onAddUser={handleUserAdd}
      />
      <ConfirmActionDialog
        open={showConfirmUnassignDialog}
        handleClose={() => setShowConfirmUnassignDialog(false)}
        handleConfirm={handleUnassignConfirm}
        description={t('jobs.team.unassign', { elem: candidateJob.user?.fullName })}
      />
    </div>
  );
};
