import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { StyledAddIconButton, StyledBox } from './styles';

interface Option {
  label: string;
  value: string;
  onClick?: () => void;
}

interface BasicPopoverProps {
  options: Option[];
}

export default function BasicPopover({ options }: BasicPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <StyledAddIconButton aria-describedby={id} onClick={handleClick}>
        <AddIcon />
      </StyledAddIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <StyledBox>
          {options.map((option) => (
            <Button
              fullWidth
              key={option.value}
              size="small"
              sx={{
                textTransform: 'none',
                color: 'black',
                justifyContent: 'flex-start',
              }}
              onClick={() => {
                if (option.onClick) {
                  option.onClick();
                  handleClose();
                }
              }}
            >
              <PersonAddAltIcon color="inherit" sx={{ marginLeft: 2 }} />
              <Typography sx={{ p: 2 }}>{option.label}</Typography>
            </Button>
          ))}
        </StyledBox>
      </Popover>
    </div>
  );
}
