import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../Common/SectionTitle/SectionTitle';
import { AttachmentContainer, Container, EmptyMessageContainer, EmptyMessageText } from './styles';
import { List } from '@mui/material';
import { FileListItem } from './Components/FileListItem';

interface AttachmentsBoxProps {
  files: AttachmentFile[];
  width?: string;
  title?: string;
}

export const AttachmentsBox = ({ files, width, title }: AttachmentsBoxProps) => {
  const { t } = useTranslation();
  const attachmentsTitle = t('attachments.title');
  return (
    <Container width={width}>
      <SectionTitle title={title || attachmentsTitle} />
      <AttachmentContainer>
        <List>
          {files.map((file) => (
            <FileListItem file={file} />
          ))}
          {!files.length && (
            <EmptyMessageContainer>
              <EmptyMessageText>No files uploaded yet</EmptyMessageText>
            </EmptyMessageContainer>
          )}
        </List>
      </AttachmentContainer>
    </Container>
  );
};
