import { Select, MenuItem, styled } from '@mui/material';
import { STATUS_TYPES } from '../../Types/constants';
import { t } from 'i18next';

const CustomSelect = styled(Select)(({ theme }) => ({
  marginLeft: '10px',
  borderRadius: '50px',
  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
  },
}));

const StatusMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  borderRadius: '0',
  '&:hover': {
    borderRadius: '0',
  },
}));

export const filterStatusTypes = ['All', ...STATUS_TYPES] as const;
export type FilterStatusType = (typeof filterStatusTypes)[number];

const statusColors: Record<FilterStatusType, string> = {
  All: '#ffffff',
  Active: '#81c784',
  OnHold: '#ffeb3b',
  Canceled: '#e70000',
  Closed: '#e2e7ea',
};

interface Props {
  handleChange: (newValue: FilterStatusType) => void;
  status: FilterStatusType;
  containerMargin?: number;
  hideAll?: boolean;
}

export const JobStatusSelect = ({
  handleChange,
  status,
  containerMargin,
  hideAll = false,
}: Props) => {
  return (
    <CustomSelect
      sx={{ marginBottom: containerMargin }}
      value={status}
      onChange={(e) => handleChange(e.target.value as FilterStatusType)}
      variant="outlined"
      size="small"
      renderValue={(selected) => {
        const status = selected as FilterStatusType;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: statusColors[status],
                borderRadius: '50%',
                height: 12,
                width: 12,
                marginRight: 8,
              }}
            />
            {t(`jobs.status.${status}`)}
          </div>
        );
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {hideAll
        ? STATUS_TYPES.map((type) => (
            <StatusMenuItem key={type} value={type}>
              {type === 'OnHold' ? 'On hold' : type}
            </StatusMenuItem>
          ))
        : filterStatusTypes.map((type) => (
            <StatusMenuItem key={type} value={type}>
              {type === 'OnHold' ? 'On hold' : type}
            </StatusMenuItem>
          ))}
    </CustomSelect>
  );
};
