import { Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IntermediaBlueLogo } from '../../Components/icons/IntermediaBlueLogo';
import { useAuthStore } from '../../Stores/useAuthStore';
import { login } from '../../Services/Auth';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../Types/constants';
import * as paths from '../../Routing/paths';
import { handleLogin } from '../../Utils/auth';
import { Loader } from '../../Components/Loader/Loader';

export const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUser, setLoading, loading } = useAuthStore();

  const errorMessage = t('toast.errors.login_error');

  const title = t('login.title');
  const description = t('login.description');
  const buttonText = t('login.button');

  const onClickLogIn = async () => {
    try {
      setLoading(true);
      await handleLogin();
      loginMutation();
    } catch (error) {
      toast.error('Login failed. Please try again.');
    }
  };

  const { mutate: loginMutation } = useMutation({
    mutationFn: () => login(),
    onError: () => {
      setLoading(false);
      toast.error(errorMessage);
    },
    onSuccess: (response) => {
      setUser(response);
      setLoading(false);
      if (response.roles.includes(ROLES.internal)) {
        navigate(paths.PLANNER);
      } else {
        navigate(paths.JOBS);
      }
    },
  });

  return (
    <div>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{ gap: 5 }}
    >
      <Box sx={{ position: 'absolute', top: 20, left: 20 }}>
        <IntermediaBlueLogo />
      </Box>
      <Box>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {description}
        </Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={onClickLogIn}>
        {buttonText}
      </Button>
    </Box>
    <Loader isLoading={loading} />
    </div>
  );
};
