import axios from 'axios';
import { useAuthStore } from '../Stores/useAuthStore';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '../App'; 
import { handleLogin } from '../Utils/auth';

export interface ServiceResponse<T> {
  items: T;
  lastPage?: number;
  page?: number;
  totalItems?: number;
}

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
});

instance.interceptors.request.use(async (config) => {
  let accessToken = useAuthStore.getState().accessToken;

  try {
    // Attempt to acquire token silently, this will renew the token if it has expired
    const accounts = msalInstance.getAllAccounts();
    var scope = process.env.REACT_APP_SCOPE as string;
    const account = accounts[0];
    const response = await msalInstance.acquireTokenSilent({
      scopes: [scope],
      account: account,
    });

    // Update access token in the state
    accessToken = response.accessToken;
    useAuthStore.setState({ accessToken });
  } catch (error) {
    // If there's an error and it's an InteractionRequiredAuthError, it means we need user interaction
    if (error instanceof InteractionRequiredAuthError) {
      console.log("User interaction required to renew token.");
      accessToken = await handleLogin();
    } else {
      console.error('Error acquiring token silently: ', error);
      return Promise.reject(error);
    }
  }

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
},
  (error) => {
    return Promise.reject(error);
  },
);
