import { useTranslation } from 'react-i18next';
import { Button, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { SectionTitle } from '../../../../Components/Common/SectionTitle/SectionTitle';
import { Container } from './styles';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getJobStatus } from '../../../../Services/Jobs';
import { useParams } from 'react-router-dom';
import { StatusType } from '../../../../Types/constants';
import { JobStatusDialog } from '../../../../Components/Dialogs/JobStatusDialog/JobStatusDialog';

interface Props {
  creationDate: string;
  status: StatusType;
}

export const JobTimeline = ({ creationDate, status }: Props) => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const [jobDates, setJobDates] = useState<JobStatusItem[]>([]);
  const [showJobStatusDialog, setShowJobStatusDialog] = useState(false);
  const [newStatus, setNewStatus] = useState<StatusType>('');

  const title = t('jobs.timeline.title');
  const suspendJobButton = t('jobs.timeline.suspend');
  const closeJobButton = t('jobs.timeline.close');
  const reopenJobButton = t('jobs.timeline.reopen');

  const { data: jobTimeline } = useQuery({
    queryKey: ['getJobStatus'],
    queryFn: () =>
      getJobStatus(jobId!, {
        page: 1,
        count: 100,
      }),
    enabled: !!jobId,
  });

  useEffect(() => {
    if (jobTimeline) {
      setJobDates([
        {
          newStatus: 'Active',
          dateTime: creationDate,
          comment: 'Job was created',
        },
        ...jobTimeline,
      ]);
    }
  }, [jobTimeline]);

  const reopenJob = () => {
    setNewStatus('Active');
    setShowJobStatusDialog(true);
  };

  const suspendJob = () => {
    setNewStatus('OnHold');
    setShowJobStatusDialog(true);
  };

  const closeJob = () => {
    setNewStatus('Closed');
    setShowJobStatusDialog(true);
  };

  return (
    <Container>
      <SectionTitle title={title} />
      <TableContainer component={Paper}>
        <Table>
          {jobDates?.map(({ dateTime, newStatus, comment }) => (
            <TableRow>
              <TableCell>{newStatus}</TableCell>
              <TableCell>{dateTime}</TableCell>
              <TableCell>{comment}</TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      {status !== 'Closed' && (
        <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" style={{ width: '49%' }} onClick={closeJob}>
            {closeJobButton}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '49%' }}
            onClick={status === 'OnHold' ? reopenJob : suspendJob}
          >
            {status === 'OnHold' ? reopenJobButton : suspendJobButton}
          </Button>
        </div>
      )}
      <JobStatusDialog
        newStatus={newStatus}
        open={showJobStatusDialog}
        handleClose={() => {
          setShowJobStatusDialog(false);
          setNewStatus('');
        }}
      />
    </Container>
  );
};
