import { FormControl, Autocomplete, TextField } from '@mui/material';
import { DialogActionButtons } from '../../../../../Components/DialogActionButtons/DialogActionButtons';
import FormDialogContainer from '../../../../../Components/FormDialogContainer/FormDialogContainer';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assignCandidateToJob, getJobs } from '../../../../../Services/Jobs';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AssignCandidateFromCandidatesTableSchema } from '../../../../../Utils/schemas';
import { ContactSource } from './ContactSource';
import { getCandidateJobs } from '../../../../../Services/CandidateJobs';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onInvalidateQuery: () => void;
  candidateId: string;
}

type AssignCandidateFormData = z.infer<typeof AssignCandidateFromCandidatesTableSchema>;

export const AssignCandidateDialog = ({
  isOpen,
  handleClose,
  onInvalidateQuery,
  candidateId,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<AssignCandidateFormData>({
    resolver: zodResolver(AssignCandidateFromCandidatesTableSchema),
    defaultValues: {
      jobId: '',
      candidateSource: 'LinkedIn Recruiter',
      sourceDetail: '',
    },
  });

  const assignSuccessMessage = t('jobs.assign.success_message');
  const errorMessage = t('toast.errors.something_went_wrong');

  const { data: candidateJobs } = useQuery({
    queryKey: ['getCandidateJobs', candidateId],
    queryFn: () => getCandidateJobs({ page: 1, count: 50, candidateId }),
    enabled: !!candidateId,
  });

  const { data: jobs } = useQuery({
    queryKey: ['getJobs'],
    queryFn: () =>
      getJobs({
        page: 1,
        count: 1000,
      }),
  });

  const jobsAssignedToCandidate = useMemo(() => {
    if (candidateId) {
      return candidateJobs?.items?.map((job) => job.jobId);
    }
  }, [candidateId, candidateJobs]);

  const handleModalClose = () => {
    handleClose();
    setValue('jobId', '');
    setValue('candidateSource', 'LinkedIn Recruiter');
    setValue('sourceDetail', '');
  };

  const { mutate: assignCandidateMutation } = useMutation({
    mutationFn: (data: AssignCandidateFormData) =>
      assignCandidateToJob(data.jobId, {
        candidateId: candidateId,
        candidateSource: data.candidateSource,
        sourceDetail: data.sourceDetail,
      }),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(assignSuccessMessage);
      handleModalClose();
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobs', candidateId] });
      onInvalidateQuery();
    },
  });

  const onSubmit: SubmitHandler<AssignCandidateFormData> = (data) => {
    assignCandidateMutation(data);
    handleClose();
  };

  const handleJobChange = (event: React.SyntheticEvent, newValue: Job | null) => {
    setValue('jobId', newValue?.id?.toString() || '');
    clearErrors('jobId');
  };

  const jobId = watch('jobId');

  return (
    <FormDialogContainer
      open={isOpen}
      handleClose={handleModalClose}
      title={t('jobs.assign.title')}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <FormControl sx={{ paddingLeft: 2, paddingRight: 2 }} fullWidth>
          <p>{t('jobs.assign.select')}</p>
          {jobs?.items && (
            <Autocomplete
              id="job-autocomplete"
              options={
                jobs?.items?.filter(
                  ({ id, status }) =>
                    !jobsAssignedToCandidate?.includes(id) &&
                    (status !== 'Canceled' && status !== 'Closed'),
                ) ?? []
              }
              getOptionLabel={(option) => `${option.id} - ${option.position}`}
              value={jobs?.items.find((job) => `${job.id}` === jobId) || null}
              onChange={handleJobChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={!!errors.jobId}
                  helperText={errors.jobId?.message}
                  placeholder={t('jobs.assign.select')}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              fullWidth
            />
          )}
          <ContactSource
            candidateSource={watch('candidateSource')}
            setSource={(value) => setValue('candidateSource', value)}
            sourceDetail={watch('sourceDetail') ?? ''}
            setSourceDetail={(value) => setValue('sourceDetail', value)}
          />
          <DialogActionButtons noMargin onCancel={handleModalClose} />
        </FormControl>
      </form>
    </FormDialogContainer>
  );
};
