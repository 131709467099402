import React from 'react';
import { useDrop } from 'react-dnd';
import Card, { ItemType } from '../Card/Card';
import { Container, StyledDivider, Title } from './styles';
import { useAuthStore } from '../../../../Stores/useAuthStore';
import { ROLES } from '../../../../Types/constants';

interface ColumnProps {
  title: string;
  items: CandidateJob[];
  onDropItem: (itemId: string, newColumnId: string) => void;
  columnId: string;
}

const Column: React.FC<ColumnProps> = ({ title, items, onDropItem, columnId }) => {
  const { user } = useAuthStore();
  const [, drop] = useDrop({
    accept: ItemType.CARD,
    drop: (item: { id: string }) => {
      onDropItem(item.id, columnId);
    },
  });
  const isRecruiter = !user?.roles.includes(ROLES.internal);

  const filteredItems = isRecruiter
    ? items
    : items.filter((item) => item.visibleToInternalClientDate);

  return (
    <Container ref={drop}>
      <Title>{title}</Title>
      <StyledDivider />
      {filteredItems.map((item) => (
        <Card key={item.id} item={item} />
      ))}
    </Container>
  );
};

export default Column;
