import { StyledChip } from './styles';
import { StatusType } from '../../Types/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  status: StatusType;
  label?: string;
}
export const ChipStatus = ({ label, status }: Props) => {
  const { t } = useTranslation();
  const statusLabel = t(`jobs.status.${status}`);
  return <StyledChip status={status} label={label || statusLabel} />;
};
