import styled from '@emotion/styled';
import { Avatar, IconButton, Tabs } from '@mui/material';
import theme from '../../theme';

export const StyledAvatar = styled(Avatar)(() => ({
  display: 'flex',
  backgroundColor: '#F0B529',
  height: 100,
  width: 100,
  fontSize: 36,
}));
export const Container = styled.div(() => ({
  display: 'flex',
  margin: 12,
  alignItems: 'center',
}));
export const JobContainer = styled.div(() => ({
  margin: 20,
}));
export const TitleContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'flex-start',
  margin: 12,
  padding: '10px',
}));
export const Title = styled.h1(() => ({
  margin: '0px 0px 2px 0px',
}));
export const SubTitle = styled.p(() => ({
  display: 'flex',
  margin: 0,
  fontSize: 15,
  alignItems: 'flex-end',
}));

export const TabsContainer = styled(Tabs)(() => ({
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: 'lightGray',
}));

export const AttachmentsContainer = styled.div(({}) => ({
  width: '100%',
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
}));
export const AddButton = styled(IconButton)(() => ({
  color: theme.palette.primary.main,
  ':hover': {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  width: 35,
  height: 35,
  marginRight: 16,
}));

export const InterviewHeaderDiv = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));
