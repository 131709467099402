import styled from '@emotion/styled';
import theme from '../../theme';

export const BadgeContainer = styled.div(({}) => ({
  marginLeft: 6,
  borderRadius: 100,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  width: 22,
  height: 22,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
