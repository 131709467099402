import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as paths from './paths';
import { Candidates } from '../Views/Candidates/Candidates';
import { CandidateDetail } from '../Views/CandidateDetail/CandidateDetail';
import { NotFound } from '../Views/NotFound/NotFound';
import { Jobs } from '../Views/Jobs/Jobs';
import { JobDetail } from '../Views/JobDetail/JobDetail';
import { Planner } from '../Views/Planner/Planner';
import { Reports } from '../Views/Reports/Reports';
import { Login } from '../Views/Login/Login';
import { Clients } from '../Views/Clients/Clients';
import ProtectedRoutesAuth from './ProtectedRoutes/ProtectedRoutesAuth';
import ProtectedRouteLogin from './ProtectedRoutes/ProtectedRouteLogin';
import { UserError } from '../Views/UserError/UserError';
import ProtectedRouteUser from './ProtectedRoutes/ProtectedRouteUser';
import ProtectedRouteRole from './ProtectedRoutes/ProtectedRouteRole';

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={paths.HOME} key={paths.HOME} element={<Login />} />
      <Route element={<ProtectedRouteLogin />}>
        <Route path={paths.LOGIN} key={paths.LOGIN} element={<Login />} />
      </Route>
      <Route element={<ProtectedRoutesAuth />}>
        <Route element={<ProtectedRouteUser />}>
          <Route element={<ProtectedRouteRole />}>
            <Route path={paths.CANDIDATES} key={paths.CANDIDATES} element={<Candidates />} />
            <Route path={paths.JOBS} key={paths.JOBS} element={<Jobs />} />
            <Route path={paths.REPORTS} key={paths.REPORTS} element={<Reports />} />
            <Route path={paths.CLIENTS} key={paths.CLIENTS} element={<Clients />} />
            <Route
              path={paths.CANDIDATE_DETAIL}
              key={paths.CANDIDATE_DETAIL}
              element={<CandidateDetail defaultTab={0} />}
            />
            <Route
              path={paths.CANDIDATE_DETAIL_JOBS}
              key={paths.CANDIDATE_DETAIL_JOBS}
              element={<CandidateDetail defaultTab={1} />}
            />
            <Route path={paths.JOB_DETAIL} key={paths.JOB_DETAIL} element={<JobDetail />} />
          </Route>
          <Route path={paths.PLANNER} key={paths.PLANNER} element={<Planner />} />
        </Route>
      </Route>
      <Route path={paths.NOT_FOUND} key={paths.NOT_FOUND} element={<NotFound />} />
      <Route path={paths.USER_ERROR} key={paths.USER_ERROR} element={<UserError />} />
    </Routes>
  </BrowserRouter>
);
