import { useEffect, useState } from 'react';
import {
  initialMessageSentSubStatusOptions,
  InProgressClientSubStatusOptions,
  InProgressTalentAcquisitionSubStatusOptions,
  NotSelectedSubStatusOptions,
  RejectedProposalSubStatusOptions,
} from '../Types/constants';

export const useFilterCandidateJobSubStatus = (
  candidateJobStatus: string,
  setCandidateJobSubStatus: (newValue: string) => void,
) => {
  const [candidateJobSubStatusOptions, setCandidateJobSubStatusOptions] = useState<
    { label: string; value: string }[]
  >([{ label: 'All', value: 'all' }]);

  useEffect(() => {
    let subStatusOptions = [{ label: 'All', value: 'all' }];
    let defaultSubStatus = 'all';

    switch (candidateJobStatus) {
      case 'InitialMessageSent':
        subStatusOptions = initialMessageSentSubStatusOptions;
        defaultSubStatus = initialMessageSentSubStatusOptions[0].value;
        break;
      case 'InProgressTalentAcquisition':
        subStatusOptions = InProgressTalentAcquisitionSubStatusOptions;
        defaultSubStatus = InProgressTalentAcquisitionSubStatusOptions[0].value;
        break;
      case 'NotSelected':
        subStatusOptions = NotSelectedSubStatusOptions;
        defaultSubStatus = NotSelectedSubStatusOptions[0].value;
        break;
      case 'InProgressClient':
        subStatusOptions = InProgressClientSubStatusOptions;
        defaultSubStatus = InProgressClientSubStatusOptions[0].value;
        break;
      case 'RejectedProposal':
        subStatusOptions = RejectedProposalSubStatusOptions;
        defaultSubStatus = RejectedProposalSubStatusOptions[0].value;
        break;
      case 'Hired':
      case 'all':
      default:
        subStatusOptions = [{ label: 'All', value: 'all' }];
        defaultSubStatus = 'all';
        break;
    }

    setCandidateJobSubStatusOptions(subStatusOptions);
    setCandidateJobSubStatus(defaultSubStatus);
  }, [candidateJobStatus]);

  return { candidateJobSubStatusOptions };
};
