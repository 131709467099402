import { instance, ServiceResponse } from './Axios';

export const getUsers = async (params: {
  page: number;
  count: number;
  fullname?: string;
  email?: string;
  role?: string;
}) => {
  const response = await instance.get<ServiceResponse<User[]>>('/users', { params });
  return response.data;
};