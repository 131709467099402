import { Button, Autocomplete, TextField } from '@mui/material';
import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { useEffect, useState } from 'react';
import { ReportsTable } from './Components/ReportsTable';
import { useTranslation } from 'react-i18next';
import { COUNT_ROWS_PER_PAGE } from '../../Types/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { generateReport, getJobReports, getJobs } from '../../Services/Jobs';
import { Loader } from '../../Components/Loader/Loader';
import toast from 'react-hot-toast';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Reports = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);
  const [jobToReport, setJobToReport] = useState<Job | null>(null);
  const queryClient = useQueryClient();

  const { data: jobsData, isLoading: jobsLoading } = useQuery({
    queryKey: ['getJobs'],
    queryFn: () =>
      getJobs({
        page: 1,
        count: 2000,
      }),
  });
  const {
    data: reportsData,
    isLoading: reportsLoading,
    refetch: refetchReports,
    isFetching: reportsFetching,
  } = useQuery({
    queryKey: ['getJobReports', jobToReport?.id, currentPage, rowsPerPage],
    queryFn: () =>
      getJobReports({
        jobId: '' + jobToReport?.id,
        page: currentPage + 1,
        count: rowsPerPage,
      }),
    enabled: !!jobToReport,
  });

  const { mutate: generateReportMutation } = useMutation({
    mutationFn: (id: string) => generateReport(id),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      toast.success(t('toast.success.report'));
      queryClient.invalidateQueries({
        queryKey: ['getJobReports', jobToReport?.id, currentPage, rowsPerPage],
      });
    },
  });

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (reportsData) {
      setLastPage(reportsData?.lastPage! - 1);
    }
  }, [reportsData]);

  useEffect(() => {
    if (jobToReport) {
      setCurrentPage(0);
    }
  }, [jobToReport]);

  return (
    <MainLayout>
      <h1>{t('common.reports')}</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: jobToReport ? 50 : 0,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'end', gap: 15 }}>
          <Autocomplete
            options={jobsData?.items || []}
            getOptionLabel={(option) => `${option.id} - ${option.position}`}
            value={jobToReport}
            onChange={(event, value) => setJobToReport(value)}
            renderInput={(params) => (
              <TextField {...params} label={t('reports.report_label')} placeholder="Select a Job" />
            )}
            sx={{ width: '300px', marginTop: 1 }}
          />
          <Button
            variant="contained"
            disabled={!jobToReport}
            sx={{ height: '55px' }}
            onClick={() => generateReportMutation('' + jobToReport?.id)}
          >
            {t('reports.generate_report')}
          </Button>
          <Button
            variant="contained"
            disabled={!jobToReport || reportsLoading || reportsFetching}
            sx={{ height: '55px' }}
            onClick={() => refetchReports()}
          >
            <RefreshIcon />
          </Button>
        </div>
        {!jobToReport && <h3>{t('reports.no_job_selected')}</h3>}
        {jobToReport && (
          <ReportsTable
            rows={reportsData?.items || []}
            currentPage={currentPage}
            lastPage={lastPage}
            handlePageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={reportsData?.totalItems || 0}
          />
        )}
      </div>
      <Loader isLoading={jobsLoading || reportsLoading || reportsFetching} />
    </MainLayout>
  );
};
