import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { useDebounce } from '../../Hooks/useDebounce';
import { useTable } from '../../Hooks/useTable';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { getClients } from '../../Services/Client';
import { ClientTable } from './ClientTable';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { COUNT_ROWS_PER_PAGE } from '../../Types/constants';
import { Loader } from '../../Components/Loader/Loader';

export const Clients = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);

  const title = t('client.title');

  const tableData = useTable({
    query: getClients,
    params: { name: debouncedSearchTerm },
  });

  const { data: clientsData, isLoading } = useQuery({
    queryKey: ['getClients', debouncedSearchTerm, rowsPerPage, currentPage],
    queryFn: () =>
      getClients({
        page: currentPage + 1,
        count: rowsPerPage,
        name: debouncedSearchTerm,
      }),
  });

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (clientsData) {
      setLastPage(clientsData?.lastPage! - 1);
    }
  }, [clientsData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm]);

  return (
    <div>
      <MainLayout
        onClientsInvalidateQuery={() =>
          queryClient.invalidateQueries({
            queryKey: ['getClients', debouncedSearchTerm, rowsPerPage, currentPage],
          })
        }
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h1>{title}</h1>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchBar margin="0 0 15px 0" onSearch={handleSearch} />
        </div>
        {tableData?.rows && (
          <ClientTable
            rows={clientsData?.items || []}
            currentPage={currentPage}
            lastPage={lastPage}
            handlePageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={clientsData?.totalItems || 0}
          />
        )}
        <Loader isLoading={isLoading} />
      </MainLayout>
    </div>
  );
};
