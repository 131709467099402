import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TableHeader } from './Components/TableHeader/TableHeader';
import Body from '@mui/material/TableBody';

interface DashboardTableProps {
  headerCells: HeadCell[];
  children: React.ReactNode;
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

export const DashboardTable = ({
  headerCells,
  children,
  currentPage,
  handlePageChange,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
  rows,
}: DashboardTableProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHeader headCells={headerCells} />
            <Body>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headerCells.length} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              ) : (
                children
              )}
            </Body>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
          slotProps={{
            actions: {
              nextButton: { disabled: currentPage === lastPage },
              previousButton: { disabled: currentPage === 0 },
            },
          }}
          onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value))}
        />
      </Paper>
    </Box>
  );
};
