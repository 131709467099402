import {
  CANDIDATE_JOB_STATUS_TYPES,
  CODERBYTE_STATUS,
  CURRENCY_TYPES,
  ENGLISH_LEVELS,
  ENGLISH_TEST_LEVELS,
  getOptions,
  PROPOSAL_RESPONSE,
  SENIORITY_TYPES,
  PRIORITY_TYPES,
} from '../Types/constants';

export const generalFields: Field[] = [
  { label: 'Salary Expectations', key: 'salaryExpectation', type: 'text', edit: true },
  {
    label: 'Currency',
    key: 'currency',
    edit: true,
    type: 'select',
    options: getOptions(CURRENCY_TYPES),
  },
  {
    label: 'Seniority',
    key: 'seniority',
    edit: true,
    type: 'select',
    options: getOptions(SENIORITY_TYPES),
  },
  { label: 'Years of Experience', key: 'yearsOfExperience', edit: true, type: 'text' },
  { label: ' ', key: '', edit: false, type: 'text' },
  { label: ' ', key: '', edit: false, type: 'text' },
];

export const englishFields: Field[] = [
  { label: 'Level', key: 'level', type: 'select', edit: true, options: getOptions(ENGLISH_LEVELS) },
  { label: 'Test date', key: 'testDate', edit: true, type: 'date' },
  {
    label: 'Test result',
    key: 'testResult',
    edit: true,
    type: 'select',
    options: getOptions(ENGLISH_TEST_LEVELS),
  },
  { label: 'Comments', key: 'comments', edit: true, type: 'text' },
  { label: ' ', key: '', edit: false, type: 'text' },
  { label: ' ', key: '', edit: false, type: 'text' },
];

export const technicalFields: Field[] = [
  {
    label: 'CoderByte status',
    key: 'testStatus',
    type: 'select',
    edit: true,
    options: getOptions(CODERBYTE_STATUS),
  },
  { label: 'Score', key: 'testScore', edit: true, type: 'text' },
  { label: 'Test date', key: 'testDate', edit: true, type: 'date' },
  { label: 'Coding Challenge', key: 'difficulty', edit: true, type: 'text' },
  { label: 'Cheating', key: 'cheatedOnTest', edit: true, type: 'boolean' },
  { label: 'Comments', key: 'comments', edit: true, type: 'text' },
];
export const internalClientField: Field[] = [
  {
    label: 'Introduction date',
    key: 'introduction_date',
    type: 'text',
    edit: true,
  },
  {
    label: 'Interview date',
    key: 'interview_date',
    type: 'text',
    edit: true,
  },
  {
    label: 'Interviewer',
    key: 'interviewer',
    type: 'text',
    edit: true,
  },
];
export const resultsFields: Field[] = [
  {
    label: 'Proposal date',
    key: 'proposalDate',
    type: 'date',
    edit: true,
  },
  {
    label: 'Response date',
    key: 'responseDate',
    type: 'date',
    edit: true,
  },
  {
    label: 'Response',
    key: 'response',
    type: 'select',
    edit: true,
    options: getOptions(PROPOSAL_RESPONSE),
  },
  {
    label: 'Start date',
    key: 'startDate',
    type: 'date',
    edit: true,
  },
  {
    label: 'Survey',
    key: 'surveyCompleted',
    type: 'boolean',
    edit: true,
  },
  {
    label: 'Notification date',
    key: 'notificationDate',
    type: 'date',
    edit: true,
  },
  {
    label: 'Notification mean',
    key: 'notificationMean',
    type: 'text', // Evaluate to change to select type
    edit: true,
  },
];

export const candidateDetailsFields: Field[] = [
  { label: 'Candidate name', key: 'fullName', edit: true, type: 'text' },
  { label: 'Candidate ID', key: 'id', type: 'text' },
  { label: 'Phone', key: 'phone', edit: true, type: 'text' },
  { label: 'LinkedIn', key: 'linkedin', edit: true, type: 'text' },
  { label: 'Email', key: 'email', edit: true, type: 'text' },
  { label: 'Country', key: 'country', edit: true, type: 'text' },
  { label: 'City', key: 'city', edit: true, type: 'text' },
  { label: 'Address', key: 'address', edit: true, type: 'text' },
  { label: 'Last Contact Date', key: 'lastContactDateTime', edit: true, type: 'date' },
];

export const priorityAndConfidentialityFields: Field[] = [
  {
    label: 'Confidential',
    key: 'confidential',
    edit: true,
    type: 'boolean',
  },
  {
    label: 'Priority',
    key: 'priority',
    edit: true,
    type: 'select',
    options: getOptions(PRIORITY_TYPES),
  },
];

export const metricsFields: Field[] = [
  {
    label: 'Candidates presented to client',
    key: 'totalPresentedToClient',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'P&T interviews',
    key: 'totalInterviewedByPeopleAndTeams',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Rejected proposals',
    key: 'totalRejectedOffers',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Total interested',
    key: 'totalInterestedCandidates',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Total contacted',
    key: 'totalContactedCandidates',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
];
export const fieldsContactSource: Field[] = [
  {
    label: 'LinkedIn recruiter',
    key: 'LinkedIn Recruiter',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Applicant',
    key: 'Applicant',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Referral',
    key: 'Referral',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'Database',
    key: 'Database',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
  {
    label: 'External consultant',
    key: 'External Consultant',
    edit: true,
    type: 'text',
    textFieldType: 'number',
  },
];
