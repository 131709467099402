export const IntermediaBlueLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="39.226"
      viewBox="0 0 380.96 39.226"
    >
      <g id="Grupo_55" data-name="Grupo 55" transform="translate(-59.079 -56.024)">
        <g id="Grupo_9" data-name="Grupo 9" transform="translate(59.079 56.024)">
          <rect
            id="Rectángulo_17"
            data-name="Rectángulo 17"
            width="11.096"
            height="39.226"
            transform="translate(0 0)"
            fill="#005596"
          />
          <path
            id="Trazado_33"
            data-name="Trazado 33"
            d="M1164.93,309.85l-17.316-20.9h-9.135v39.226h10.872v-20.9l17.315,20.9h9.135V288.948H1164.93Z"
            transform="translate(-1119.483 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_34"
            data-name="Trazado 34"
            d="M1187,297.746h12.048v30.428h11.1V297.746h11.992v-8.8H1187Z"
            transform="translate(-1126.536 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_35"
            data-name="Trazado 35"
            d="M1243.946,312.428h17.428v-8.294h-17.428v-6.612h19.781v-8.574h-30.765v39.226h31.493V319.6h-20.51Z"
            transform="translate(-1133.216 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_36"
            data-name="Trazado 36"
            d="M1310.394,310.887a13.657,13.657,0,0,0,1.99-7.425,13.288,13.288,0,0,0-8.181-12.748,22.378,22.378,0,0,0-9.246-1.766h-17.932v39.226h11.095V317.752h6.053l7.117,10.422h11.88l-8.461-12.328A13.453,13.453,0,0,0,1310.394,310.887Zm-10.954-3.194a7.76,7.76,0,0,1-5.156,1.484h-6.165V297.69h6.165a7.659,7.659,0,0,1,5.156,1.513,5.349,5.349,0,0,1,1.737,4.259A5.264,5.264,0,0,1,1299.44,307.693Z"
            transform="translate(-1139.62 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_37"
            data-name="Trazado 37"
            d="M1361.912,288.948l-13.449,22.639-13.785-22.639h-9.135v39.226H1335.8V307.945l9.568,15.607,2.712,4.6h.517l2.519-4.439,9.732-16.328.112,20.79h10.2l-.112-39.226Z"
            transform="translate(-1146.672 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_38"
            data-name="Trazado 38"
            d="M1399.078,312.428h17.427v-8.294h-17.427v-6.612h19.781v-8.574h-30.765v39.226h31.493V319.6h-20.509Z"
            transform="translate(-1155.764 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_39"
            data-name="Trazado 39"
            d="M1462.135,291.357a25.985,25.985,0,0,0-11.431-2.409h-18.549v39.226H1450.7a25.961,25.961,0,0,0,11.431-2.409,18.15,18.15,0,0,0,7.734-6.836,20.947,20.947,0,0,0,0-20.734A18.163,18.163,0,0,0,1462.135,291.357Zm-3.783,25.106a11.372,11.372,0,0,1-8.1,2.857h-7V297.8h7a11.373,11.373,0,0,1,8.1,2.858,11.747,11.747,0,0,1,0,15.8Z"
            transform="translate(-1162.168 -288.948)"
            fill="#005596"
          />
          <rect
            id="Rectángulo_18"
            data-name="Rectángulo 18"
            width="11.096"
            height="39.226"
            transform="translate(316.275 0)"
            fill="#005596"
          />
          <path
            id="Trazado_40"
            data-name="Trazado 40"
            d="M1520.349,288.948l-17.315,39.226h11.32l11.361-28.3,11.39,28.3h11.543l-17.372-39.226Z"
            transform="translate(-1172.469 -288.948)"
            fill="#005596"
          />
          <path
            id="Trazado_41"
            data-name="Trazado 41"
            d="M1553.206,290.372c-3.478,4.524,1.685,9.687,6.208,6.208C1562.893,292.056,1557.73,286.893,1553.206,290.372Zm.633,5.575c-2.768-3.6,1.34-7.709,4.942-4.942C1561.549,294.606,1557.441,298.714,1553.839,295.947Z"
            transform="translate(-1179.596 -288.989)"
            fill="#005596"
          />
          <path
            id="Trazado_42"
            data-name="Trazado 42"
            d="M1558.079,294.079a1.145,1.145,0,0,0-.512-1.667,1.86,1.86,0,0,0-.764-.145h-1.481v3.076a.164.164,0,0,0,.165.165h.752v-.862h.5l.588.862h.981l-.7-1.019A1.11,1.11,0,0,0,1558.079,294.079Zm-.905-.264a.64.64,0,0,1-.427.123h-.508v-.95h.508a.636.636,0,0,1,.427.125.443.443,0,0,1,.143.352A.435.435,0,0,1,1557.174,293.815Z"
            transform="translate(-1180.069 -289.43)"
            fill="#005596"
          />
        </g>
      </g>
    </svg>
  );
};
