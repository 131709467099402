import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(() => ({
  color: 'gray',
}));

export const LabelSpan = styled('span')(() => ({
  color: '#4a4a4a',
}));

export const NoteContainer = styled.div(() => ({
  padding: '10px',
  border: '1px solid #ddd',
  borderRadius: '5px',
  backgroundColor: '#f9f9f9',
}));

export const Description = styled.p(() => ({
  fontSize: '16px',
  margin: 0,
  overflow: 'hidden',
}));
