import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../../Stores/useAuthStore';
import { Loader } from '../../Components/Loader/Loader';

export default function ProtectedRouteUser({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const { user, loading } = useAuthStore();

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (!loading && (!user || !user.roles)) {
    return <Navigate to="/user-error" replace />;
  }

  return children ? <>{children}</> : <Outlet />;
}
