import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { useTranslation } from 'react-i18next';
import { JobsTable } from './Components/JobsTable/JobsTable';
import { getJobs } from '../../Services/Jobs';
import { useEffect, useState } from 'react';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { DateRangePicker } from '../../Components/Common/DateRangePicker/DateRangePicker';
import { useDebounce } from '../../Hooks/useDebounce';
import AutocompleteSelect from '../../Components/AutocompleteSelect/AutocompleteSelect';
import { getSkills } from '../../Services/Skills';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClearFiltersButton } from '../../Components/Common/ClearFiltersButton/ClearFiltersButton';
import { getClients } from '../../Services/Client';
import {
  FilterStatusType,
  filterStatusTypes,
  JobStatusSelect,
} from '../../Components/StatusSelect/JobStatusSelect';
import { COUNT_ROWS_PER_PAGE } from '../../Types/constants';
import { Loader } from '../../Components/Loader/Loader';

export const Jobs = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [status, setStatus] = useState<FilterStatusType>(filterStatusTypes[0] as FilterStatusType);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [skillsSearchTerm, setSkillsSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [clearSearch, setClearSearch] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm);
  const debouncedSkillsSearchTerm = useDebounce(skillsSearchTerm);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);

  const title = t('jobs.title');

  const { data: jobsData, isLoading } = useQuery({
    queryKey: [
      'getJobs',
      debouncedSearchTerm,
      status,
      startDate,
      endDate,
      selectedOptions,
      selectedClient?.id,
      rowsPerPage,
      currentPage,
    ],
    queryFn: () =>
      getJobs({
        page: currentPage + 1,
        count: rowsPerPage,
        position: debouncedSearchTerm,
        status,
        fromCreationDate: startDate,
        toCreationDate: endDate,
        skills: selectedOptions,
        clientId: selectedClient?.id,
      }),
  });

  const { data: skills } = useQuery({
    queryKey: ['getSkills', debouncedSkillsSearchTerm],
    queryFn: () =>
      getSkills({
        page: 1,
        count: 10,
        name: debouncedSkillsSearchTerm,
      }),
  });

  const { data: clients = [] } = useQuery<Client[]>({
    queryKey: ['getClients'],
    queryFn: async () => {
      const response = await getClients({
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const handleClearFilters = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
    setStatus('All');
    setSkillsSearchTerm('');
    setSelectedOptions([]);
    setClearSearch(true);
    setSelectedClient(null);
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setClearSearch(false);
  };

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (jobsData) {
      setLastPage(jobsData?.lastPage! - 1);
    }
  }, [jobsData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm, selectedOptions, status, startDate, endDate, selectedClient]);

  return (
    <div>
      <MainLayout
        onJobsInvalidateQuery={() =>
          queryClient.invalidateQueries({
            queryKey: [
              'getJobs',
              debouncedSearchTerm,
              status,
              startDate,
              endDate,
              selectedOptions,
              selectedClient?.id,
              rowsPerPage,
              currentPage,
            ],
          })
        }
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h1>{title}</h1>
          <ClearFiltersButton handleClearFilters={handleClearFilters} />
        </Box>
        <Accordion sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              margin: 0,
              '&.MuiAccordionSummary-root': {
                margin: 0,
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: 15,
              }}
            >
              <SearchBar margin="0 0 15px 0" onSearch={handleSearch} clearSearch={clearSearch} />
              <JobStatusSelect
                containerMargin={2}
                handleChange={(newValue: FilterStatusType) => {
                  setStatus(newValue);
                }}
                status={status}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', gap: '16px' }}>
              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <AutocompleteSelect
                searchTerm={skillsSearchTerm}
                onSearchTermChange={(newValue: string) => setSkillsSearchTerm(newValue)}
                options={skills ?? []}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
              {!!clients && (
                <Autocomplete
                  sx={{ width: '380px' }}
                  id="client-autocomplete"
                  options={clients.length ? clients?.map((client) => client.name) : []}
                  onChange={(_, newValue) => {
                    const selected = clients?.find((client) => client.name === newValue);
                    setSelectedClient(selected || null);
                  }}
                  value={selectedClient?.name || ''}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('common.client')} />
                  )}
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {jobsData && (
          <JobsTable
            rows={jobsData?.items || []}
            currentPage={currentPage}
            lastPage={lastPage}
            handlePageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={jobsData?.totalItems || 0}
          />
        )}
        <Loader isLoading={isLoading} />
      </MainLayout>
    </div>
  );
};
