export const getInitials = (name?: string) => {
  const words = name?.split(' ');
  if (words?.length === 1) {
    return words[0]?.charAt(0).toUpperCase();
  }
  if (words?.length === 2) {
    return words[0]?.charAt(0).toUpperCase() + words[1]?.charAt(0).toUpperCase();
  }
  if (words) {
    return words[0].charAt(0).toUpperCase() + words[1]?.charAt(0).toUpperCase();
  } else {
    return '';
  }
};

export const replaceEmptyStrings = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceEmptyStrings(obj[key]);
    } else if (obj[key] === '') {
      obj[key] = null;
    }
  });
};

export const formatDates = <T extends Record<string, any>>(obj: T): T => {
  const formattedObj: Partial<T> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        formattedObj[key] = formatDates(value);
      } else if (typeof value === 'string' && key.toLowerCase().includes('date')) {
        formattedObj[key] = value.split('T')[0];
      } else {
        formattedObj[key] = value;
      }
    }
  }

  return formattedObj as T;
};
