import { DialogContent } from '@mui/material';
import FormDialogContainer from '../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../DialogActionButtons/DialogActionButtons';
import { useTranslation } from 'react-i18next';
import { candidateJobStatusOptions } from '../../../Types/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { StatusSelect } from '../../StatusSelect/StatusSelect';
import { useCandidateJobSubStatus } from '../../../Hooks/useCandidateJobSubStatus';
import { updateCandidateJobStatus } from '../../../Services/CandidateJobs';
import { useParams } from 'react-router-dom';

interface Props {
  candidateJob: CandidateJob;
  open: boolean;
  handleClose: () => void;
}

export const CandidateJobStatusDialog = ({ candidateJob, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { candidateId } = useParams();

  const [status, setStatus] = useState(candidateJob.generalData.status);
  const [subStatus, setSubStatus] = useState(candidateJob.generalData.subStatus);
  const { candidateJobSubStatusOptions } = useCandidateJobSubStatus(
    status,
    setSubStatus,
    subStatus,
  );

  const title = t('candidate_jobs.status.update.title');

  const successMessage = t('jobs.status.update.success_message');

  const { mutate: updateCandidateJobStatusMutation } = useMutation({
    mutationFn: () =>
      updateCandidateJobStatus(`${candidateJob.id}`, {
        status,
        subStatus: subStatus === 'all' ? '' : subStatus,
      }),
    onError: (error: { response: { data: string } }) => {
      toast.error(error?.response?.data);
    },
    onSuccess: () => {
      toast.success(successMessage);
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobs', candidateId] });
    },
  });

  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={title}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <StatusSelect
          handleChange={setStatus}
          status={status}
          options={candidateJobStatusOptions.filter((option) => option.value !== 'all')}
        />
        <StatusSelect
          disabled={
            candidateJobSubStatusOptions.filter((option) => option.value !== 'all').length === 0
          }
          handleChange={setSubStatus}
          status={subStatus}
          options={candidateJobSubStatusOptions.filter((option) => option.value !== 'all')}
        />
      </DialogContent>
      <DialogActionButtons onCancel={handleClose} onConfirm={updateCandidateJobStatusMutation} />
    </FormDialogContainer>
  );
};
