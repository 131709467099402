import styled from '@emotion/styled';
import { Divider } from '@mui/material';

export const Container = styled.div(({}) => ({
  flex: 1,
  margin: '8px',
  padding: '8px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '530px',
}));

export const Title = styled.h4(({}) => ({
  margin: '0 0 8px',
  height: '50px',
  alignContent: 'center',
  textAlign: 'center',
}));

export const StyledDivider = styled(Divider)(() => ({
  marginBottom: '8px',
  width: '100%',
}));
