import styled from '@emotion/styled';

export const Container = styled.div<{ width?: string }>(({ width = '600px' }) => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  width,
  margin: 5,
}));

export const AttachmentContainer = styled.div(() => ({
  padding: '10px',
  border: '1px solid #ddd',
  marginBottom: '10px',
  borderRadius: '5px',
  backgroundColor: '#f9f9f9',
  marginTop: 5,
}));

export const EmptyMessageContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
}));

export const EmptyMessageText = styled.span(({}) => ({
  alignSelf: 'center',
}));
