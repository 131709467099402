import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import { ActionsContainer, SearchAndFilterContainer, StyledCell } from './styles';
import { Box, Button, IconButton, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import SearchBar from '../../../../Components/SearchBar/SearchBar';
import * as paths from '../../../../Routing/paths';
import { useTranslation } from 'react-i18next';
import { AssignCandidateDialog } from './Components/AssignCandidateDialog';
import CandidateFormDialog from '../../../../Components/Layouts/MainLayout/components/CandidateFormDialog';
import { candidateJobStatusOptions, StatusType } from '../../../../Types/constants';
import { StatusSelect } from '../../../../Components/StatusSelect/StatusSelect';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ClearFiltersButton } from '../../../../Components/Common/ClearFiltersButton/ClearFiltersButton';
import { ConfirmActionDialog } from '../../../../Components/Common/ConfirmActionDialog/ConfirmActionDialog';
import { useMutation } from '@tanstack/react-query';
import { deleteCandidateJob } from '../../../../Services/CandidateJobs';
import toast from 'react-hot-toast';
import { DashboardTable } from '../../../../Components/DashboardTable/DashboardTable';

const headCells: HeadCell[] = [
  {
    id: 'candidateName',
    numeric: true,
    disablePadding: false,
    label: 'Candidate name',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'subStatus',
    numeric: false,
    disablePadding: true,
    label: 'Sub status',
  },
  {
    id: 'salaryExpectations',
    numeric: true,
    disablePadding: false,
    label: 'Salary expectations',
  },
  {
    id: 'seniority',
    numeric: true,
    disablePadding: false,
    label: 'Seniority',
  },
  {
    id: 'country',
    numeric: true,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'english',
    numeric: true,
    disablePadding: false,
    label: 'English level',
  },
  {
    id: 'recruiterResponsible',
    numeric: true,
    disablePadding: false,
    label: 'Recruiter responsible',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

interface CandidateTableProps {
  handleSearch: (term: string) => void;
  status: string;
  subStatus: string;
  onStatusChange: (newStatus: string) => void;
  onSubStatusChange: (newStatus: string) => void;
  subStatusOptions: { label: string; value: string }[];
  handleClearFilters: () => void;
  clearSearch: boolean;
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
  onInvalidateQuery: () => void;
  jobStatus: StatusType;
}

export const CandidateTable = ({
  handleSearch,
  status,
  subStatus,
  onStatusChange,
  onSubStatusChange,
  subStatusOptions,
  handleClearFilters,
  clearSearch,
  currentPage,
  handlePageChange,
  rows,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
  onInvalidateQuery,
  jobStatus,
}: CandidateTableProps) => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const navigate = useNavigate();

  const assignExistingCandidate = t('actions.assign');
  const assignNewCandidate = t('actions.create_new');

  const [showAssignCandidatesModal, setShowAssignCandidatesModal] = useState(false);
  const [showCreateCandidatesModal, setShowCreateCandidatesModal] = useState(false);
  const [candidateJobToDeleteId, setCandidateJobToDeleteId] = useState();
  const [showConfirmDeleteCandidateJobModal, setShowConfirmDeleteCandidateJobModal] =
    useState(false);

  const handleClick = (id: number) => {
    navigate(generatePath(paths.CANDIDATE_DETAIL_JOBS, { candidateId: `${id}` }));
  };

  const { mutate: deleteCandidateJobMutation } = useMutation({
    mutationFn: () => deleteCandidateJob(candidateJobToDeleteId),
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      toast.success(t('toast.success.delete', { elem: 'Candidate' }));
      onInvalidateQuery();
    },
  });

  const handleDeleteCandidateJob = () => {
    deleteCandidateJobMutation();
    setShowConfirmDeleteCandidateJobModal(false);
  };

  return (
    <div>
      <ActionsContainer>
        <Box display="flex" flexDirection="row" alignItems="start" gap={2}>
          {(jobStatus === 'Active' || jobStatus === 'OnHold') && (
            <Box display="flex" flexDirection="row" alignItems="start" gap={2}>
              <Button
                sx={{ height: 50 }}
                variant="contained"
                onClick={() => setShowCreateCandidatesModal(true)}
              >
                {assignNewCandidate}
              </Button>
              <Button
                sx={{ height: 50 }}
                variant="contained"
                onClick={() => setShowAssignCandidatesModal(true)}
              >
                {assignExistingCandidate}
              </Button>
            </Box>
          )}
          <ClearFiltersButton handleClearFilters={handleClearFilters} />
        </Box>
        <SearchAndFilterContainer>
          <SearchBar width="300px" onSearch={handleSearch} clearSearch={clearSearch} />
          <div style={{ display: 'flex' }}>
            <StatusSelect
              handleChange={(newStatus: string) => {
                onStatusChange(newStatus);
              }}
              status={status}
              label="Status"
              options={candidateJobStatusOptions}
            />
            <StatusSelect
              options={subStatusOptions}
              handleChange={(newStatus: string) => {
                onSubStatusChange(newStatus);
              }}
              status={subStatus}
              label="Sub status"
            />
          </div>
        </SearchAndFilterContainer>
      </ActionsContainer>
      <DashboardTable
        headerCells={headCells}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        rows={rows}
        lastPage={lastPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalItems={totalItems}
      >
        {rows?.map((row) => {
          const labelId = `enhanced-table-checkbox-${row.id}`;

          return (
            <TableRow
              component={Link}
              to={generatePath(paths.CANDIDATE_DETAIL_JOBS, { candidateId: `${row.candidateId}` })}
              hover
              onClick={() => handleClick(row.candidateId)}
              role="checkbox"
              tabIndex={-1}
              key={row.id}
              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
            >
              <StyledCell>{row.candidateName}</StyledCell>
              <StyledCell id={labelId} scope="row">
                {t(`candidate_jobs.status.${row.generalData.status}`)}
              </StyledCell>
              <StyledCell id={labelId} scope="row">
                {row.generalData.subStatus
                  ? t(`candidate_jobs.sub_status.${row.generalData.subStatus}`)
                  : '-'}
              </StyledCell>
              <StyledCell>{row.generalData?.salaryExpectation}</StyledCell>
              <StyledCell>{row.generalData?.seniority}</StyledCell>
              <StyledCell>{row.candidateCountry}</StyledCell>
              <StyledCell>{row.englishProficiencyData?.level}</StyledCell>
              <StyledCell>{row.user?.fullName}</StyledCell>
              <StyledCell>
                <Tooltip title={t('jobs.assign.unassign_tooltip')}>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCandidateJobToDeleteId(row.id);
                      setShowConfirmDeleteCandidateJobModal(true);
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </StyledCell>
            </TableRow>
          );
        })}
      </DashboardTable>
      <AssignCandidateDialog
        isOpen={showAssignCandidatesModal}
        handleClose={() => setShowAssignCandidatesModal(false)}
        onInvalidateQuery={onInvalidateQuery}
      />
      <CandidateFormDialog
        jobId={jobId}
        open={showCreateCandidatesModal}
        handleClose={() => setShowCreateCandidatesModal(false)}
        onInvalidateQuery={onInvalidateQuery}
      />
      <ConfirmActionDialog
        open={showConfirmDeleteCandidateJobModal}
        handleClose={() => setShowConfirmDeleteCandidateJobModal(false)}
        handleConfirm={handleDeleteCandidateJob}
        description={t('jobs.assign.unassign_confirm_message')}
      />
    </div>
  );
};
