import { forwardRef, useState } from 'react';
import { Box, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useDrag } from 'react-dnd';
import { StyledMuiCard, StyledSpan } from './styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from 'react-i18next';
import { DetailedCard } from '../DetailedCard/DetailedCard';
import { editCandidateJobVisibilityToInternalClient } from '../../../../Services/CandidateJobs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { ConfirmActionDialog } from '../../../../Components/Common/ConfirmActionDialog/ConfirmActionDialog';
import { formatDateTime } from '../../../../Utils/format-date';
import { useAuthStore } from '../../../../Stores/useAuthStore';
import { ROLES } from '../../../../Types/constants';

export const ItemType = {
  CARD: 'card',
};

interface CardProps {
  item: CandidateJob;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({ item }, ref) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useAuthStore();

  const [showExpandedCard, setShowExpandedCard] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [, drag] = useDrag({
    type: ItemType.CARD,
    item: { id: item.id },
  });

  const isRecruiter = !user?.roles.includes(ROLES.internal);

  const countryLabel = t('planner.labels.country');
  const recruiterLabel = t('planner.labels.recruiter');
  const englishLabel = t('planner.labels.english');
  const codingChallengeLabel = t('planner.labels.coding_challenge');

  const successMessage = t('planner.visibility.success_message');
  const confirmMessage = t('planner.visibility.confirm_message');
  const tooltip = t('planner.visibility.tooltip');

  const noResults = t('common.no_results');
  const noData = t('common.no_data');

  const { mutate: editCandidateJobVisibilityToInternalClientMutation } = useMutation({
    mutationFn: (date: string) => editCandidateJobVisibilityToInternalClient(item.id, date),
    onError: (error: { response: { data: string } }) => {
      toast.error(error?.response?.data);
    },
    onSuccess: () => {
      toast.success(successMessage);
      setShowConfirmDialog(false);
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobs', item.jobId] });
    },
  });

  return (
    <Box>
      <StyledMuiCard
        disabled={!item.visibleToInternalClientDate}
        onClick={() => setShowExpandedCard(true)}
        ref={(node) => {
          drag(node);
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
      >
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {!item.visibleToInternalClientDate && isRecruiter && (
            <Tooltip arrow placement="top" title={tooltip}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  color: 'text.secondary',
                  padding: 0.5,
                }}
                aria-label="view"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowConfirmDialog(true);
                }}
              >
                <VisibilityOutlinedIcon sx={{ strokeWidth: 1, fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          )}
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {item.candidateName}
          </Typography>
          <StyledSpan>
            {countryLabel}: {item.candidateCountry ?? noData}
          </StyledSpan>
          <StyledSpan>
            {recruiterLabel}: {item.user?.fullName}
          </StyledSpan>
          <StyledSpan>
            {englishLabel}: {item.englishProficiencyData.level ?? noResults}
          </StyledSpan>
          <StyledSpan>
            {codingChallengeLabel}: {item.technicalSkills.testScore ?? noResults}
          </StyledSpan>
          {item.visibleToInternalClientDate && (
            <Box position="absolute" bottom={1} left={5}>
              <Typography sx={{ fontSize: 11 }} color="text.secondary">
                {formatDateTime(item.visibleToInternalClientDate)}
              </Typography>
            </Box>
          )}
        </CardContent>
      </StyledMuiCard>
      <DetailedCard
        open={showExpandedCard}
        handleClose={() => setShowExpandedCard(false)}
        candidateJob={item}
      />
      <ConfirmActionDialog
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        handleConfirm={() =>
          editCandidateJobVisibilityToInternalClientMutation(new Date().toISOString())
        }
        description={confirmMessage}
      />
    </Box>
  );
});

export default Card;
