import styled from '@emotion/styled';
import { Card, TableCell, TextField } from '@mui/material';

export const Footer = styled.div(() => ({
  marginTop: '10px',
  fontSize: '12px',
  color: '#888',
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'flex-end',
}));
export const StyledCell = styled(TableCell)(({ bold }: { bold?: boolean }) => ({
  fontWeight: bold ? 'bold' : 'normal',
  padding: 5,
  border: 'none',
  maxWidth: '30%',
}));
export const StyledTextField = styled(TextField)(({ isTitle }: { isTitle?: boolean }) => ({
  '& input': {
    padding: '5px 0px 5px 10px',
    fontSize: isTitle ? 32 : 16,
  },
  width: '100%',
  marginTop: 20,
}));
export const StyledTextValue = styled.p(({ isTitle }: { isTitle?: boolean }) => ({
  fontSize: 16,
  margin: 0,
  width: '100%',
  alignItems: 'center',
}));

export const Title = styled.p(() => ({
  padding: '5px 0px 5px 10px',
  fontSize: 32,
  margin: 0,
}));
export const CommentCard = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 200px;
  line-height: 1.5em;
`;

export const StyledCard = styled(Card)(() => ({
  margin: 5,
  cursor: 'pointer',
  width: 240,
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'space-between',
  position: 'relative' as 'relative',
}));

export const StarWrapper = styled.div(() => ({
  position: 'absolute' as 'absolute',
  top: 8,
  right: 8,
}));
