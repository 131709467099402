import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(() => ({
  color: 'gray',
}));

export const LabelSpan = styled('span')(() => ({
  color: '#4a4a4a',
}));

export const AttachmentsContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));
