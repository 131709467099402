import React, { useEffect, useState } from 'react';
import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Column from './Components/Column/Column';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  OutlinedInput,
} from '@mui/material';

import { PlannerContainer, SelectContainer } from './styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getJobs } from '../../Services/Jobs';
import { useTranslation } from 'react-i18next';
import { getCandidateJobs, updateCandidateJobStatus } from '../../Services/CandidateJobs';
import usePlannerCandidateJobs from '../../Hooks/usePlannerCandidateJobs';
import toast from 'react-hot-toast';
import { useAuthStore } from '../../Stores/useAuthStore';
import { ROLES } from '../../Types/constants';

type Columns = Record<string, CandidateJob[]>;

export const Planner: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useAuthStore();
  const [selectedJobId, setSelectedJobId] = useState<string>('');
  const [columns, setColumns] = useState<Columns>({
    'new-candidate': [],
    'in-progress-internal-client': [],
    'in-progress-external-client': [],
    'pre-selected': [],
    'not-selected-internal-client': [],
    'not-selected-external-client': [],
    hired: [],
  });

  const newCandidateLabel = t('planner.columns.new_candidate');
  const inProgressInternalClientLabel = t('planner.columns.in_progress_client.internal');
  const inProgressExternalClientLabel = t('planner.columns.in_progress_client.external');
  const preSelectedLabel = t('planner.columns.pre_selected');
  const hiredLabel = t('planner.columns.hired');
  const notSelectedInternalClientLabel = t('planner.columns.not_selected.internal_client');
  const notSelectedExternalClientLabel = t('planner.columns.not_selected.external_client');
  const selectJobLabel = t('planner.select_job');
  const updateStatusSuccessMessage = t('jobs.status.update.success_message');

  const { data: jobs = [] } = useQuery({
    queryKey: ['getJobs'],
    queryFn: async () => {
      const response = await getJobs({
        page: 1,
        count: 200,
        userId: user?.roles.includes(ROLES.internal) ? `${user?.id}` : '',
        status: 'Active'
      });
      return response.items;
    },
  });

  const { data: candidateJobs } = useQuery({
    queryKey: ['getCandidateJobs', selectedJobId],
    queryFn: async () => {
      const response = await getCandidateJobs({
        page: 1,
        count: 200,
        jobId: selectedJobId,
      });
      return response.items;
    },
    enabled: !!selectedJobId,
  });

  const {
    inProgressTalentAcquisitionCandidates,
    inProgressInternalClientCandidates,
    inProgressExternalClientCandidates,
    preSelectedCandidates,
    notSelectedInternalClientCandidates,
    notSelectedExternalClientCandidates,
    hiredCandidates,
  } = usePlannerCandidateJobs(candidateJobs && candidateJobs?.length > 0 ? candidateJobs : []);

  useEffect(() => {
    if (jobs && jobs.length > 0) {
      setSelectedJobId(`${jobs?.[0]?.id}`);
    }
  }, [jobs]);

  useEffect(() => {
    setColumns({
      'new-candidate': inProgressTalentAcquisitionCandidates,
      'in-progress-internal-client': inProgressInternalClientCandidates,
      'in-progress-external-client': inProgressExternalClientCandidates,
      'pre-selected': preSelectedCandidates,
      'not-selected-internal-client': notSelectedInternalClientCandidates,
      'not-selected-external-client': notSelectedExternalClientCandidates,
      hired: hiredCandidates,
    });
  }, [
    inProgressTalentAcquisitionCandidates,
    inProgressInternalClientCandidates,
    inProgressExternalClientCandidates,
    preSelectedCandidates,
    notSelectedInternalClientCandidates,
    notSelectedExternalClientCandidates,
    hiredCandidates,
  ]);

  const { mutate: updateCandidateJobStatusMutation } = useMutation({
    mutationFn: ({ id, data }: { id: string; data: { status: string; subStatus: string } }) =>
      updateCandidateJobStatus(id, data),
    onError: (error: { response: { data: string } }) => {
      toast.error(error?.response?.data);
    },
    onSuccess: () => {
      toast.success(updateStatusSuccessMessage);
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobs', selectedJobId] });
    },
  });

  const handleDrop = (itemId: string, newColumnId: string) => {
    let status = '';
    let subStatus = '';

    switch (newColumnId) {
      case 'new-candidate':
        status = 'InProgressTalentAcquisition';
        subStatus = 'TalentAcquisitionInterview';
        break;
      case 'in-progress-internal-client':
        status = 'InProgressClient';
        subStatus = 'Internal';
        break;
      case 'in-progress-external-client':
        status = 'InProgressClient';
        subStatus = 'External';
        break;
      case 'pre-selected':
        status = 'InProgressClient';
        subStatus = 'PreSelected';
        break;
      case 'not-selected-internal-client':
        status = 'NotSelected';
        subStatus = 'ByInternalClient';
        break;
      case 'not-selected-external-client':
        status = 'NotSelected';
        subStatus = 'ByExternalClient';
        break;
      case 'hired':
        status = 'hired';
        subStatus = '';
        break;
      default:
        status = 'unknown';
        subStatus = 'unknown';
        break;
    }
    updateCandidateJobStatusMutation({ id: itemId, data: { status, subStatus } });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedJobId(event.target.value as string);
  };

  return (
    <MainLayout>
      <DndProvider backend={HTML5Backend}>
        <SelectContainer>
          <FormControl fullWidth>
            <InputLabel id="select-label">{selectJobLabel}</InputLabel>
            <Select
              labelId="select-label"
              value={selectedJobId}
              onChange={handleSelectChange}
              displayEmpty
              input={<OutlinedInput label={selectJobLabel} />}
            >
              {(jobs && Array.isArray(jobs) ? jobs : []).map((job) => (
                <MenuItem key={job.id} value={job.id}>
                  {job.position} - {job.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SelectContainer>
        <PlannerContainer>
          <Column
            title={newCandidateLabel}
            items={columns['new-candidate']}
            onDropItem={handleDrop}
            columnId="new-candidate"
          />
          <Column
            title={inProgressInternalClientLabel}
            items={columns['in-progress-internal-client']}
            onDropItem={handleDrop}
            columnId="in-progress-internal-client"
          />
          <Column
            title={inProgressExternalClientLabel}
            items={columns['in-progress-external-client']}
            onDropItem={handleDrop}
            columnId="in-progress-external-client"
          />
          <Column
            title={preSelectedLabel}
            items={columns['pre-selected']}
            onDropItem={handleDrop}
            columnId="pre-selected"
          />
          <Column
            title={notSelectedInternalClientLabel}
            items={columns['not-selected-internal-client']}
            onDropItem={handleDrop}
            columnId="not-selected-internal-client"
          />
          <Column
            title={notSelectedExternalClientLabel}
            items={columns['not-selected-external-client']}
            onDropItem={handleDrop}
            columnId="not-selected-external-client"
          />
          <Column
            title={hiredLabel}
            items={columns['hired']}
            onDropItem={handleDrop}
            columnId="hired"
          />
        </PlannerContainer>
      </DndProvider>
    </MainLayout>
  );
};
