import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const StyledSpan = styled.span(() => ({
  fontSize: 12,
}));


export const StyledMuiCard = styled(Card)<{ disabled: boolean }>(({ disabled }) => ({
  backgroundColor: disabled ? '#d0d0d0' : '#f0f0f0', // Color de fondo deshabilitado
  color: disabled ? '#4a4a4a' : 'inherit', // Color del texto deshabilitado
  marginBottom: 5,
  marginTop: 5,
  position: 'relative',
  border: `1px solid ${disabled ? '#a0a0a0' : '#ccc'}`, // Borde deshabilitado
}));
