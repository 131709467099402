import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import * as paths from '../paths';
import { useMsal } from '@azure/msal-react';

export default function ProtectedRouteLogin({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  if (account) return <Navigate to={paths.JOBS} replace />;
  return children ? <>{children}</> : <Outlet />;
}
