import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { createCandidate } from '../../../../Services/Candidates';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as paths from '../../../../Routing/paths';
import FormDialogContainer from '../../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../../DialogActionButtons/DialogActionButtons';
import { CandidateSchema } from '../../../../Utils/schemas';
import { assignCandidateToJob, CandidateSource } from '../../../../Services/Jobs';
import { ContactSource } from '../../../../Views/JobDetail/Components/CandidateTable/Components/ContactSource';
import { useState } from 'react';

interface CandidateFormDialogProps {
  open: boolean;
  handleClose: () => void;
  jobId?: string;
  onInvalidateQuery?: () => void;
}

export default function CandidateFormDialog({
  open,
  handleClose,
  jobId,
  onInvalidateQuery,
}: CandidateFormDialogProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const nameLabel = t('form_fields.name.candidate_label');
  const emailLabel = t('form_fields.email.label');
  const linkedinLabel = t('form_fields.linkedin.label');

  const assignSuccessMessage = t('jobs.assign.success_message');
  const createCandidateSuccess = t('candidates.create.success_message');
  const createCandidateTitle = t('candidates.create.title');
  const createCandidateDescription = t('candidates.create.description');
  const createCandidateError = t('toast.errors.something_went_wrong');

  const [candidateSource, setCandidateSource] = useState<string>('LinkedIn Recruiter');
  const [sourceDetail, setSourceDetail] = useState<string>('');

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<z.infer<typeof CandidateSchema>>({
    resolver: zodResolver(CandidateSchema),
  });

  const { mutate: assignCandidateMutation } = useMutation({
    mutationFn: (data: CandidateSource) => assignCandidateToJob(jobId!, data),
    onError: (error: any) => {
      toast.error(createCandidateError);
    },
    onSuccess: () => {
      toast.success(assignSuccessMessage);
      handleClose();
      if (onInvalidateQuery) {
        onInvalidateQuery();
      }
    },
  });

  const { mutate: createCandidateMutation } = useMutation({
    mutationFn: (data: Candidate) => createCandidate(data),
    onError: (error: any) => {
      toast.error(createCandidateError);
    },
    onSuccess: (data: Candidate) => {
      toast.success(createCandidateSuccess);
      if (jobId) {
        const assignData = {
          candidateId: '' + data.id,
          candidateSource: candidateSource,
          sourceDetail: sourceDetail,
        };
        assignCandidateMutation(assignData);
      } else {
        handleClose();
        navigate(paths.CANDIDATES);
        if (onInvalidateQuery) {
          onInvalidateQuery();
        }
      }
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof CandidateSchema>> = (data) => {
    const newCandidate: Candidate = {
      fullName: data.fullName,
      email: data.email,
      linkedin: data.linkedin,
    };
    createCandidateMutation(newCandidate);
    reset();
  };

  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={createCandidateTitle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText>{createCandidateDescription}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="fullName"
            label={nameLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('fullName')}
            error={!!errors.fullName}
            helperText={errors.fullName?.message}
          />
          <TextField
            margin="dense"
            id="linkedin"
            label={linkedinLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('linkedin')}
            error={!!errors.linkedin}
            helperText={errors.linkedin?.message}
          />
          <TextField
            margin="dense"
            id="email"
            label={emailLabel}
            type="email"
            fullWidth
            variant="outlined"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          {!!jobId && (
            <ContactSource
              candidateSource={candidateSource}
              setSource={setCandidateSource}
              sourceDetail={sourceDetail}
              setSourceDetail={setSourceDetail}
            />
          )}
        </DialogContent>
        <DialogActionButtons
          onCancel={() => {
            handleClose();
            reset();
          }}
        />
      </form>
    </FormDialogContainer>
  );
}
