import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  MenuItem,
  Autocomplete,
  TextField,
} from '@mui/material';
import { TextEditor, SelectEditor } from './styles';
import { Controller } from 'react-hook-form';
import { DataBox } from '../DataBox/DataBox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parse, isValid, format } from 'date-fns';
import Switch from '../Switch/Switch';

interface Props {
  title: string;
  fields: Field[];
  values: any;
  onEdit?: boolean;
  handleEdit?: () => void;
  control?: any;
  handleSave?: () => void;
  handleCancel?: () => void;
  errors?: any;
  fullWidth?: boolean;
}

export const DataBoxTable = ({
  title,
  fields,
  values,
  onEdit,
  handleEdit,
  control,
  handleSave,
  handleCancel,
  errors,
  fullWidth,
}: Props) => {
  return (
    <DataBox
      title={title}
      handleEdit={handleEdit}
      handleCancel={handleCancel}
      handleSave={handleSave}
      onEdit={onEdit}
      fullWidth={fullWidth}
    >
      <TableContainer component={Paper}>
        <Table>
          {fields?.map((detail, index) => (
            <TableRow key={index} style={{ height: '55px' }}>
              <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                {detail.label}
              </TableCell>
              <TableCell style={{ fontSize: 15, padding: 5, width: '50%' }}>
                {onEdit && detail.edit ? (
                  <Controller
                    name={detail.key}
                    control={control}
                    defaultValue={values[detail.key]}
                    render={({ field }) => {
                      if (detail.type === 'select' && detail.options) {
                        return (
                          <SelectEditor
                            {...field}
                            variant="outlined"
                            fullWidth
                            error={!!errors[detail.key]}
                          >
                            {detail.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </SelectEditor>
                        );
                      } else if (detail.type === 'text') {
                        return (
                          <TextEditor
                            {...field}
                            type={detail.textFieldType || 'text'}
                            variant="outlined"
                            fullWidth
                            error={!!errors[detail.key]}
                            helperText={errors[detail.key]?.message}
                            onChange={(e) => {
                              if (detail.textFieldType === 'number') {
                                const value = parseInt(e.target.value);
                                if (value < 0) {
                                  e.target.value = '0';
                                }
                              }
                              field.onChange(e);
                            }}
                          />
                        );
                      } else if (detail.type === 'boolean') {
                        return (
                          <Switch
                            {...field}
                            checked={field.value || false}
                            onChange={field.onChange}
                          />
                        );
                      } else if (detail.type === 'autocomplete' && detail.options) {
                        return (
                          <Autocomplete
                            id={`${detail.key}-autocomplete`}
                            options={detail.options}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            onChange={(_, newValue) => {
                              field.onChange(newValue || '');
                            }}
                            value={field.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors[detail.key]}
                                placeholder={detail.label}
                                fullWidth
                              />
                            )}
                          />
                        );
                      } else {
                        const dateValue =
                          typeof field.value === 'string' && field.value
                            ? parse(field.value, 'yyyy-MM-dd', new Date())
                            : field.value || null;

                        return (
                          <DatePicker
                            sx={{ width: '100%' }}
                            {...field}
                            format="dd/MM/yyyy"
                            value={isValid(dateValue) ? dateValue : null}
                            onChange={(newValue) => {
                              const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : '';
                              field.onChange(formattedDate);
                            }}
                          />
                        );
                      }
                    }}
                  />
                ) : detail.type === 'boolean' ? (
                  values[detail?.key] ? (
                    'Yes'
                  ) : (
                    'No'
                  )
                ) : typeof values[detail?.key] === 'object' && values[detail?.key] !== null ? (
                  values[detail?.key].name
                ) : (
                  values[detail?.key]
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </DataBox>
  );
};
