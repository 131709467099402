import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

interface AutocompleteSelectProps {
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: (newOptions: string[]) => void;
}

export default function AutocompleteSelect({
  searchTerm,
  onSearchTermChange,
  options,
  selectedOptions,
  setSelectedOptions,
}: AutocompleteSelectProps) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    setSelectedOptions(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    onSearchTermChange(value);
  };

  return (
    <Autocomplete
      sx={{ width: '380px' }}
      multiple
      options={options}
      disableCloseOnSelect
      renderTags={(value: string[], getTagProps) => {
        const maxVisibleChips = 2;
        const visibleChips = value
          .slice(0, maxVisibleChips)
          .map((option, index) => <Chip label={option} {...getTagProps({ index })} />);
        const remainingChips = value.length - visibleChips.length;
        if (remainingChips > 0) {
          visibleChips.push(<Chip key="more" label={`+${remainingChips}...`} />);
        }

        return visibleChips;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={<CheckBox />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search skills"
          placeholder="Select skills"
        />
      )}
      onChange={handleChange}
      value={selectedOptions}
      inputValue={searchTerm}
      onInputChange={handleInputChange}
    />
  );
}
