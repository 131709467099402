import styled from '@emotion/styled';

export const PlannerContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const SelectContainer = styled.div(({}) => ({
  marginBottom: '16px',
  padding: '16px',
  backgroundColor: '#f5f5f5',
}));
