import { DialogContent, TextField } from '@mui/material';
import FormDialogContainer from '../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../DialogActionButtons/DialogActionButtons';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { jobTimelineSchema } from '../../../Utils/schemas';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, isValid, parse, set } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { StatusType } from '../../../Types/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateJobStatus } from '../../../Services/Jobs';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
  newStatus: StatusType;
}

export const JobStatusDialog = ({ open, handleClose, newStatus }: Props) => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const queryClient = useQueryClient();

  const title = t('jobs.status.update.title');
  const dateLabel = t('common.date');
  const commentsLabel = t('common.comments');

  const errorMessage = t('toast.errors.something_went_wrong');
  const successMessage = t('jobs.status.update.success_message');

  const { mutate: updateJobStatusMutation } = useMutation({
    mutationFn: (data: { date: string; status: StatusType; comment?: string }) =>
      updateJobStatus(jobId!, data),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(successMessage);
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['getJobStatus'] });
      queryClient.invalidateQueries({ queryKey: ['getJobById'] });
      reset();
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<z.infer<typeof jobTimelineSchema>>({
    resolver: zodResolver(jobTimelineSchema),
    defaultValues: {
      status: newStatus,
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof jobTimelineSchema>> = (data) => {
    updateJobStatusMutation(data);
  };

  const handleDialogClose = () => {
    handleClose();
    reset();
  };
  useEffect(() => {
    setValue('status', newStatus);
  }, [newStatus]);

  return (
    <FormDialogContainer open={open} handleClose={handleDialogClose} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            disabled
            margin="dense"
            id="status"
            type="text"
            fullWidth
            variant="outlined"
            {...register('status')}
          />
          <Controller
            name="date"
            control={control}
            render={({ field }) => {
              const dateValue = field.value
                ? parse(field.value as string, 'yyyy-MM-dd', new Date())
                : null;

              return (
                <DatePicker
                  label={dateLabel}
                  {...field}
                  maxDate={new Date()}
                  value={isValid(dateValue) ? dateValue : null}
                  onChange={(newValue: Date | null) => {
                    const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : '';
                    field.onChange(formattedDate);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.date,
                      helperText: errors.date?.message,
                      sx: { marginTop: 1, marginBottom: 1 },
                    },
                  }}
                />
              );
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label={commentsLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('comment')}
            error={!!errors.comment}
            helperText={errors.comment?.message}
          />
        </DialogContent>
        <DialogActionButtons onCancel={handleDialogClose} />
      </form>
    </FormDialogContainer>
  );
};
