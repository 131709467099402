import styled from '@emotion/styled';
import { TableCell, TextField } from '@mui/material';

export const StyledCell = styled(TableCell)(() => ({
  borderRight: '1px solid lightGray',
}));

export const ActionsContainer = styled.div(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
}));

export const SearchAndFilterContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
}));

export const StyledText = styled.p(({}) => ({}));

export const TextEditor = styled(TextField)(() => ({}));
