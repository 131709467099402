export const STATUS_TYPES = ['Active', 'OnHold', 'Canceled', 'Closed'];
export type StatusType = (typeof STATUS_TYPES)[number];

export const CANDIDATE_JOB_STATUS_TYPES = [
  'Initial message sent',
  'Talent acquisition screening',
  'In progress talent acquisition',
  'Not selected',
  'In progress client',
  'Rejected proposal',
  'Hired',
];

export enum CandidateJobStatus {
  All = 'all',
  InitialMessageSent = 'InitialMessageSent',
  TalentAcquisitionScreening = 'TalentAcquisitionScreening',
  InProgressTalentAcquisition = 'InProgressTalentAcquisition',
  NotSelected = 'NotSelected',
  InProgressClient = 'InProgressClient',
  RejectedProposal = 'RejectedProposal',
  Hired = 'Hired',
}

export type CandidateJobStatusType = (typeof CANDIDATE_JOB_STATUS_TYPES)[number];

export const candidateJobStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Initial message sent', value: 'InitialMessageSent' },
  { label: 'Talent Acquisition screening', value: 'TalentAcquisitionScreening' },
  { label: 'In progress (Talent Acquisition)', value: 'InProgressTalentAcquisition' },
  { label: 'Not selected', value: 'NotSelected' },
  { label: 'In progress (client)', value: 'InProgressClient' },
  { label: 'Rejected the proposal', value: 'RejectedProposal' },
  { label: 'Hired', value: 'Hired' },
];

export const initialMessageSentSubStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending response', value: 'PendingResponse' },
  { label: 'Rejected message', value: 'RejectedMessage' },
  { label: 'Pending contact (process on hold)', value: 'PendingContact' },
];

export const InProgressTalentAcquisitionSubStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Talent Acquisition interview', value: 'TalentAcquisitionInterview' },
  { label: 'English test', value: 'EnglishTest' },
  { label: 'Technical Test', value: 'TechnicalTest' },
];

export const NotSelectedSubStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'English level', value: 'EnglishLevel' },
  { label: 'Technical requirements', value: 'TechnicalRequirements' },
  { label: 'Seniority', value: 'Seniority' },
  { label: 'Could not contact', value: 'CouldNotContact' },
  { label: 'Attitude', value: 'Attitude' },
  { label: 'By internal client', value: 'ByInternalClient' },
  { label: 'By external client', value: 'ByExternalClient' },
  { label: 'Salary expectations', value: 'SalaryExpectations' },
];

export const InProgressClientSubStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Internal', value: 'Internal' },
  { label: 'External', value: 'External' },
  { label: 'Pre Selected', value: 'PreSelected' },
];

export const RejectedProposalSubStatusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Decided Not To Continue', value: 'DecidedNotToContinue' },
  { label: 'Rejected Job Offer', value: 'RejectedJobOffer' },
];

export const SENIORITY_TYPES = ['Advanced', 'Advanced++', 'Sr.', 'Jr.'];
export type SeniorityType = (typeof SENIORITY_TYPES)[number];

export const getOptions = (options: string[]): { label: string; value: string }[] => {
  return options.map((type) => {
    return {
      label: type,
      value: type,
    };
  });
};

export const CURRENCY_TYPES = ['USD', 'UYU'];
export type CurrencyType = (typeof CURRENCY_TYPES)[number];

export const PRIORITY_TYPES = ['High', 'Medium', 'Low'];
export type PriorityType = (typeof PRIORITY_TYPES)[number];

export const CHARACTERISTICS_TYPES = [
  'Market Survey',
  'Active Process',
  'Selection Bench Process',
  'Possible Process Opening',
];
export type CharacteristicsType = (typeof CHARACTERISTICS_TYPES)[number];

export const MARKET_TYPES = ['Uruguay', 'LATAM', 'Rest of the world'];
export type MarketType = (typeof MARKET_TYPES)[number];

export const MODALITY_TYPES = [
  'Employee',
  'Independent (SAS UY)',
  'Independent (professional UY)',
  'Contractor',
];
export type ModalityType = (typeof MODALITY_TYPES)[number];

export const ENGLISH_LEVELS = [
  'A1 - Basic 1',
  'A1.2 - Basic 2',
  'A2 - Pre Intermediate',
  'B1 - Intermediate',
  'B2 - Upper Intermediate',
  'C1 - Advanced',
  'C2 - Advanced',
  'Pending evaluation',
];

export const ENGLISH_TEST_LEVELS = [
  'A1 - Basic 1',
  'A1.2 - Basic 2',
  'A2 - Pre Intermediate',
  'B1 - Intermediate',
  'B2 - Upper Intermediate',
  'C1 - Advanced',
  'C2 - Advanced',
  'No evaluation applied',
];

export const CODERBYTE_STATUS = [
  'Sent',
  'Completed',
  'Pending',
  'Resent (initial deadline expired)',
  'Deadline expired',
];

export const SENIORITY_OPTIONS: Record<string, string> = {
  advanced: 'Advanced',
  advanced_plus: 'Advanced ++',
  junior: 'Jr.',
  senior: 'Sr.',
};
export const PROPOSAL_RESPONSE = ['Proposal accepted', 'Proposal rejected', 'Evaluating proposal'];

export const COUNT_ROWS_PER_PAGE = 10;

export enum ROLES {
  admin = 'App.Admin',
  recruiter = 'App.Recruiter',
  internal = 'App.Internal',
}
