import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClearFilters: () => void;
}

export const ClearFiltersButton = ({ handleClearFilters }: Props) => {
  const { t } = useTranslation();
  const clearFilters = t('common.clear_filters');
  return (
    <Button style={{ height: 50 }} variant="outlined" onClick={handleClearFilters}>
      {clearFilters}
    </Button>
  );
};
