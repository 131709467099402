// -----------------------------------------
// --------------- GENERAL -----------------
// -----------------------------------------

export const HOME = '/';
export const NOT_FOUND = '*';
export const LOGIN = '/login';
export const USER_ERROR = '/user-error';

// -----------------------------------------
// -------------- CANDIDATES ---------------
// -----------------------------------------

export const CANDIDATES = '/candidates';
export const CANDIDATE_DETAIL = '/candidates/:candidateId';
export const CANDIDATE_DETAIL_JOBS = '/candidates/:candidateId/jobs';

// -----------------------------------------
// ----------------- JOBS ------------------
// -----------------------------------------

export const JOBS = '/jobs';
export const JOB_DETAIL = '/jobs/:jobId';

// -----------------------------------------
// ---------------- PLANNER ----------------
// -----------------------------------------

export const PLANNER = '/planner';

// -----------------------------------------
// ---------------- REPORTS ----------------
// -----------------------------------------

export const REPORTS = '/reports';

// -----------------------------------------
// ---------------- CLIENTS ----------------
// -----------------------------------------

export const CLIENTS = '/clients';
