import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import * as paths from '../Routing/paths';

export const useMenu: () => any = () => {
  const { t } = useTranslation();

  const menuItems: MenuItem[] = [
    {
      id: 'jobs',
      label: t('menu.jobs'),
      route: paths.JOBS,
      icon: <WorkIcon />,
    },
    {
      id: 'candidates',
      label: t('menu.candidates'),
      route: paths.CANDIDATES,
      icon: <PersonIcon />,
    },
    {
      id: 'clients',
      label: t('menu.clients'),
      route: paths.CLIENTS,
      icon: <StorefrontIcon />,
    },
    {
      id: 'planner',
      label: t('menu.planner'),
      route: paths.PLANNER,
      icon: <CalendarViewMonthIcon />,
    },
  ];

  return {
    menuItems,
  };
};
