import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  margin?: string;
  onSearch: (searchTerm: string) => void;
  width?: string;
  clearSearch?: boolean;
}

const SearchBar = ({ margin = '0px', onSearch, width = '400px', clearSearch }: SearchBarProps) => {
  const { t } = useTranslation();
  const searchPlaceholder = t('search.placeholder');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (clearSearch) {
      setSearchTerm('');
    }
  }, [clearSearch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value;
    setSearchTerm(newTerm);
    onSearch(newTerm);
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };

  return (
    <TextField
      variant="outlined"
      sx={{ width, margin }}
      placeholder={searchPlaceholder}
      value={searchTerm}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default SearchBar;
