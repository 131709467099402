import { Delete, Download } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { deleteFile } from '../../../Services/Files';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ConfirmActionDialog } from '../../Common/ConfirmActionDialog/ConfirmActionDialog';

interface FileListItemProps {
  file: AttachmentFile;
}

export const FileListItem = ({ file }: FileListItemProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const { mutate: deleteFileMutation } = useMutation({
    mutationFn: () => deleteFile(file.id),
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      toast.success(t('toast.success.delete', { elem: 'File' }));
      queryClient.invalidateQueries({ queryKey: ['getCandidateFiles'] });
      queryClient.invalidateQueries({ queryKey: ['getJobFiles'] });
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobFiles'] });
    },
  });

  const handleDelete = () => {
    deleteFileMutation();
    setShowConfirmDeleteDialog(false);
  };

  return (
    <>
      <ListItem>
        <ListItemText primary={file.name} />
        <Tooltip title="Download">
          <IconButton component="a" href={file.url}>
            <Download />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" sx={{ marginLeft: 3 }}>
          <IconButton onClick={() => setShowConfirmDeleteDialog(true)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </ListItem>

      <ConfirmActionDialog
        open={showConfirmDeleteDialog}
        handleClose={() => setShowConfirmDeleteDialog(false)}
        handleConfirm={handleDelete}
        description={t('attachments.delete_confirm', { elem: `${file.name}` })}
      />
    </>
  );
};
