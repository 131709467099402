import { useState } from 'react';
import { t } from 'i18next';
import {
  Container,
  DescriptionInput,
  EmptyMessageContainer,
  EmptyMessageText,
  NoteContainer,
} from './styles';
import { SectionTitle } from '../Common/SectionTitle/SectionTitle';
import { Note } from './Note';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  createNote?: (description: string) => void;
  notes?: Note[];
  onSuccessEdit?: () => void;
  containerWidth?: string;
  paddingTitle?: string;
}

export const NotesBox = ({
  createNote,
  notes = [],
  onSuccessEdit,
  containerWidth,
  paddingTitle,
}: Props) => {
  const [showInput, setShowInput] = useState(false);
  const [newNoteDescription, setNewNoteDescription] = useState('');

  const handleClick = () => {
    setShowInput(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNoteDescription(e.target.value);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!!newNoteDescription) {
      if (createNote) createNote(newNoteDescription);
    }
    setShowInput(false);
    setNewNoteDescription('');
  };

  const handleCancel = () => {
    setShowInput(false);
    setNewNoteDescription('');
  };

  return (
    <Container width={containerWidth}>
      <SectionTitle title={t('candidates.notes')} handleAdd={handleClick} padding={paddingTitle} />
      {showInput && (
        <NoteContainer
          style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}
        >
          <DescriptionInput
            value={newNoteDescription}
            onChange={handleInputChange}
            placeholder={t('candidates.notes_placeholder')}
          />
          <IconButton onClick={handleConfirm} color="primary">
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleCancel} color="primary">
            <CloseIcon />
          </IconButton>
        </NoteContainer>
      )}
      {notes.length === 0 && !showInput ? (
        <EmptyMessageContainer>
          <EmptyMessageText>{t('actions.no_elem', { elem: 'notes' })}</EmptyMessageText>
        </EmptyMessageContainer>
      ) : (
        notes.map((note) => <Note key={note.id} note={note} onSuccessEdit={onSuccessEdit} />)
      )}
    </Container>
  );
};
