import { instance } from './Axios';

export const editNote = async (id: string, description: string) => {
  const response = await instance.put(`/notes/${id}`, { description });
  return response.data;
};

export const deleteNote = async (id: string) => {
  const response = await instance.delete(`/notes/${id}`);
  return response.data;
};
