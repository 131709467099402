import { t } from 'i18next';
import { msalInstance } from '../App';
import { useAuthStore } from '../Stores/useAuthStore';
import toast from 'react-hot-toast';

export const handleLogin = async () => {
  try {
    var scope = process.env.REACT_APP_SCOPE as string;
    const loginRequest = {
      scopes: [scope],
    };

    const response = await msalInstance.loginPopup(loginRequest);
    const accessToken = response.accessToken;
    useAuthStore.setState({ accessToken });

    return accessToken;
  } catch (error) {
    toast.error(t('login.failed_error'));
    useAuthStore.setState({ loading: false });
    console.error('Login error: ', error);
    throw error;
  }
};
