import { DialogContent, DialogContentText } from '@mui/material';
import FormDialogContainer from '../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../DialogActionButtons/DialogActionButtons';
import { useTranslation } from 'react-i18next';
interface Props {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  description: string;
}
export const ConfirmActionDialog = ({ open, handleClose, handleConfirm, description }: Props) => {
  const { t } = useTranslation();
  const pleaseConfirm = t('common.please_confirm');
  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={pleaseConfirm}>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActionButtons onCancel={handleClose} onConfirm={handleConfirm} />
    </FormDialogContainer>
  );
};
