import { useQuery } from '@tanstack/react-query';
import {
  CHARACTERISTICS_TYPES,
  CURRENCY_TYPES,
  getOptions,
  MARKET_TYPES,
  MODALITY_TYPES,
  SENIORITY_TYPES,
} from '../../../../Types/constants';
import { getClients } from '../../../../Services/Client';

export const GetDetailsFields: () => Field[] = () => {
  const { data: clientsResponse } = useQuery({
    queryKey: ['getClients'],
    queryFn: () =>
      getClients({
        page: 1,
        count: 1000,
      }),
  });
  return [
    {
      label: 'Job reference',
      key: 'id',
      edit: false,
      type: 'text',
    },
    { label: 'Position name', key: 'position', edit: true, type: 'text' },
    { label: 'Headcount', key: 'headcount', edit: true, type: 'text', textFieldType: 'number' },
    {
      label: 'Client name',
      key: 'client',
      edit: true,
      type: 'autocomplete',
      options: clientsResponse?.items,
    },
    {
      label: 'Date requested',
      key: 'requestedDateTime',
      edit: true,
      type: 'date',
    },
    {
      label: 'Close date',
      key: 'closeDateTime',
      edit: false,
      type: 'text',
    },
    {
      label: 'Requester',
      key: 'requester',
      edit: true,
      type: 'text',
    },
    {
      label: 'Tech hub',
      key: 'techHub',
      edit: true,
      type: 'text',
    },
    {
      label: 'Seniority',
      key: 'seniority',
      edit: true,
      type: 'select',
      options: getOptions(SENIORITY_TYPES),
    },
    {
      label: 'Experience (in years)',
      key: 'experience',
      edit: true,
      type: 'text',
      textFieldType: 'number',
    },
    {
      label: 'Minimum salary',
      key: 'minimumSalary',
      edit: true,
      type: 'text',
      textFieldType: 'number',
    },
    {
      label: 'Maximum salary',
      key: 'maximumSalary',
      edit: true,
      type: 'text',
      textFieldType: 'number',
    },
    {
      label: 'Currency',
      key: 'currency',
      edit: true,
      type: 'select',
      options: getOptions(CURRENCY_TYPES),
    },
    {
      label: 'Expected start date',
      key: 'expectedStartDateTime',
      edit: true,
      type: 'date',
    },
    {
      label: 'Characteristics',
      key: 'characteristics',
      edit: true,
      type: 'select',
      options: getOptions(CHARACTERISTICS_TYPES),
    },
    {
      label: 'Market',
      key: 'market',
      edit: true,
      type: 'select',
      options: getOptions(MARKET_TYPES),
    },
    {
      label: 'Modality',
      key: 'modality',
      edit: true,
      type: 'select',
      options: getOptions(MODALITY_TYPES),
    },
  ];
};
