import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCandidateJobs } from '../../Services/CandidateJobs';
import { useEffect, useState } from 'react';
import { CandidateJobs } from './CandidateJobs';
import { formatDates } from '../../Utils';

export const CandidateJobList = () => {
  const { candidateId } = useParams();
  const [jobs, setJobs] = useState<CandidateJob[]>([]);

  const { data: jobsData } = useQuery({
    queryKey: ['getCandidateJobs', candidateId],
    queryFn: () => getCandidateJobs({ page: 1, count: 50, candidateId: candidateId }),
    enabled: !!candidateId,
  });

  useEffect(() => {
    if (jobsData && jobsData.items && jobsData.items.length > 0) {
      const formattedJobs = jobsData?.items.map((item: CandidateJob) => formatDates(item));
      setJobs(formattedJobs);
    }
  }, [jobsData]);

  const updateCandidateJob = (updatedJob: CandidateJob) => {
    setJobs((prevJobs) => prevJobs.map((job) => (job.id === updatedJob.id ? updatedJob : job)));
  };
  return (
    <>
      {jobs?.map((job, index) => (
        <CandidateJobs key={index} candidateJob={job} updateCandidateJob={updateCandidateJob} />
      ))}
    </>
  );
};
