import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';

export const StatusBox = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${color}`,
  width: 80,
  height: 80,
  position: 'relative',
  margin: 6,
  marginRight: 12,
}));

export const TopSection = styled(Box)<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  height: '30%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px',
}));

export const BottomSection = styled(Box)({
  backgroundColor: '#ffffff',
  height: '70%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px',
});
