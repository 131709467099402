import { useEffect, useState } from 'react';
import { DialogContent, Tabs, Tab, Box, Tooltip, Button } from '@mui/material';
import FormDialogContainer from '../../../../Components/FormDialogContainer/FormDialogContainer';
import { DetailedCardAccordion } from '../DetailedCardAccordion/DetailedCardAccordion';
import { DialogActionButtons } from '../../../../Components/DialogActionButtons/DialogActionButtons';
import { AttachmentsContainer, StyledTypography } from './styles';
import { useTranslation } from 'react-i18next';
import { NotesBox } from '../../../../Components/NotesBox/NotesBox';
import { AttachmentsBox } from '../../../../Components/AttachmentsBox/AttachmentsBox';
import { Dropzone } from '../../../../Components/Dropzone/Dropzone';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addNoteCandidateJob,
  editCandidateJob,
  editInterview,
  getCandidateJobFiles,
  getCandidateJobNotes,
  getInterviews,
  uploadCandidateJobFile,
} from '../../../../Services/CandidateJobs';
import toast from 'react-hot-toast';
import { generatePath, useNavigate } from 'react-router-dom';
import * as paths from '../../../../Routing/paths';
import { formatDateTime } from '../../../../Utils/format-date';
import { useAuthStore } from '../../../../Stores/useAuthStore';
import { ROLES } from '../../../../Types/constants';

interface Props {
  open: boolean;
  handleClose: () => void;
  candidateJob: CandidateJob;
}

export const DetailedCard = ({ open, handleClose, candidateJob }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuthStore();
  const [files, setFiles] = useState<File[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [comments, setComments] = useState(candidateJob.generalData.comments || '');

  const goToCandidateProfileLabel = t('planner.detailed_card.general_info.go_to_candidate_profile');
  const viewDetailsLabel = t('planner.detailed_card.general_info.view_details');

  const screeningLabel = t('common.screening');
  const peopleAndTeamsLabel = t('common.people_and_teams');
  const internalClientLabel = t('common.internal_client');
  const externalClientLabel = t('common.external_client');

  const notOccurred = t('common.not_occurred');

  const errorMessage = t('toast.errors.something_went_wrong');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const { data: interviews } = useQuery({
    queryKey: ['getInterviews', candidateJob.id],
    queryFn: async () => {
      const response = await getInterviews(candidateJob.id, {
        page: 1,
        count: 1000,
      });
      return response.items;
    },
    enabled: !!candidateJob.id,
  });

  const { data: candidateJobNotes } = useQuery({
    queryKey: ['getCandidateJobNotes', candidateJob.id],
    queryFn: () =>
      getCandidateJobNotes(candidateJob.id, {
        page: 1,
        count: 100,
      }),
    enabled: !!candidateJob.id,
  });

  const { mutate: createCandidateJobNoteMutation } = useMutation({
    mutationFn: (description: string) =>
      addNoteCandidateJob(candidateJob.id, { description, userId: user?.id! }),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobNotes', candidateJob.id] });
    },
  });

  const { data: candidateJobFiles } = useQuery({
    queryKey: ['getCandidateJobFiles'],
    queryFn: () =>
      getCandidateJobFiles(`${candidateJob.id}`, {
        page: 1,
        count: 100,
      }),
    enabled: !!candidateJob.id,
  });

  const { mutate: uploadCandidateJobFileMutation } = useMutation({
    mutationFn: (data: FormData) => uploadCandidateJobFile(`${candidateJob.id}`, data),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(t('toast.success.upload'));
      setFiles([]);
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobFiles'] });
    },
  });

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadCandidateJobFileMutation(formData);
  };

  const { mutate: editInterviewMutation } = useMutation({
    mutationFn: (data: Interview) => editInterview(data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobs', candidateJob.jobId] });
    },
  });

  const { mutate: editCandidateJobMutation } = useMutation({
    mutationFn: (data: CandidateJob) => editCandidateJob(candidateJob?.id, data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.edit', { elem: 'Candidate Job ' }));
    },
  });

  const [editDescription, setEditDescription] = useState<string | undefined>();

  useEffect(() => {
    setEditDescription(
      interviews?.filter((interview) => interview.title === 'Internal Client')[0].description,
    );
  }, [interviews]);

  const handleSave = () => {
    editInterviewMutation({
      id: interviews?.filter((interview: Interview) => interview.title === 'Internal Client')[0].id,
      title: 'Internal Client',
      date: new Date().toISOString(),
      description: editDescription,
      userId: interviews?.filter((interview: Interview) => interview.title === 'Internal Client')[0]
        .user?.id,
    });
    editCandidateJobMutation({
      ...candidateJob,
      generalData: {
        ...candidateJob.generalData,
        comments,
      },
    });
    handleClose();
  };

  return (
    <FormDialogContainer open={open} handleClose={handleClose} title={candidateJob.candidateName}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '500px' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
          <Tab label={t('planner.detailed_card.tabs.general')} />
          <Tab label={t('planner.detailed_card.tabs.interviews')} />
          <Tab label={t('planner.detailed_card.tabs.notes')} />
          <Tab label={t('planner.detailed_card.tabs.attachments')} />
        </Tabs>
        {tabIndex === 0 && (
          <GeneralInfo comments={comments} setComments={setComments} candidate={candidateJob} />
        )}
        {tabIndex === 1 && interviews && (
          <div>
            <Box display="flex" flexDirection="column" gap={1.5}>
              <StyledTypography>
                {`${screeningLabel} - ${
                  interviews.filter((interview: Interview) => interview.title === 'Screening')[0]
                    .date
                    ? formatDateTime(
                        interviews.filter(
                          (interview: Interview) => interview.title === 'Screening',
                        )[0].date!,
                      )
                    : notOccurred
                } ${
                  interviews.filter((interview: Interview) => interview.title === 'Screening')[0]
                    .user
                    ? `by ${interviews.filter((interview: Interview) => interview.title === 'Screening')[0].user?.fullName!}`
                    : ''
                }`}
              </StyledTypography>
              <StyledTypography>
                {`${peopleAndTeamsLabel} - ${
                  interviews.filter((interview: Interview) => interview.title === 'P&T')[0].date
                    ? formatDateTime(
                        interviews.filter((interview: Interview) => interview.title === 'P&T')[0]
                          .date!,
                      )
                    : notOccurred
                } ${
                  interviews.filter((interview: Interview) => interview.title === 'P&T')[0].user
                    ? `by ${interviews.filter((interview: Interview) => interview.title === 'P&T')[0].user?.fullName!}`
                    : ''
                }`}
              </StyledTypography>
              <StyledTypography>
                {`${externalClientLabel} - ${
                  interviews.filter(
                    (interview: Interview) => interview.title === 'External Client',
                  )[0].date
                    ? formatDateTime(
                        interviews.filter(
                          (interview: Interview) => interview.title === 'External Client',
                        )[0].date!,
                      )
                    : notOccurred
                } ${
                  interviews.filter(
                    (interview: Interview) => interview.title === 'External Client',
                  )[0].user
                    ? `by ${interviews.filter((interview: Interview) => interview.title === 'External Client')[0].user?.fullName!}`
                    : ''
                }`}
              </StyledTypography>
            </Box>
            <DetailedCardAccordion
              editable
              defaultExpanded
              title={`${internalClientLabel} - ${
                interviews.filter(
                  (interview: Interview) => interview.title === 'Internal Client',
                )[0].date
                  ? formatDateTime(
                      interviews.filter(
                        (interview: Interview) => interview.title === 'Internal Client',
                      )[0].date!,
                    )
                  : notOccurred
              } ${
                interviews.filter(
                  (interview: Interview) => interview.title === 'Internal Client',
                )[0].user
                  ? `by ${interviews.filter((interview: Interview) => interview.title === 'Internal Client')[0].user?.fullName!}`
                  : ''
              }`}
              editDescription={editDescription}
              setEditDescription={setEditDescription}
            />
          </div>
        )}
        {tabIndex === 2 && (
          <NotesBox
            containerWidth="540px"
            onSuccessEdit={() =>
              queryClient.invalidateQueries({ queryKey: ['getCandidateJobNotes', candidateJob.id] })
            }
            createNote={createCandidateJobNoteMutation}
            notes={candidateJobNotes}
          />
        )}
        {tabIndex === 3 && candidateJobFiles && (
          <AttachmentsContainer>
            <Dropzone
              onFileUpload={handleFileUpload}
              files={files}
              setFiles={setFiles}
              width="540px"
            />
            <AttachmentsBox files={candidateJobFiles} width={'540px'} />
          </AttachmentsContainer>
        )}
      </DialogContent>
      {!user?.roles.includes(ROLES.internal) && (
        <Tooltip title={goToCandidateProfileLabel} arrow placement="top">
          <Button
            onClick={() =>
              navigate(
                generatePath(paths.CANDIDATE_DETAIL, {
                  candidateId: `${candidateJob.candidateId}`,
                }),
              )
            }
            style={{
              fontSize: 14,
              color: 'gray',
              position: 'absolute',
              bottom: 15,
              left: 20,
              textDecoration: 'none',
            }}
          >
            {viewDetailsLabel}
          </Button>
        </Tooltip>
      )}
      <DialogActionButtons
        onCancel={() => {
          handleClose();
          setComments(candidateJob.generalData.comments || '');
        }}
        onConfirm={handleSave}
      />
    </FormDialogContainer>
  );
};
