import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { getInitials } from '../../Utils';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {
  AttachmentsContainer,
  Container,
  NameContainer,
  StyledAvatar,
  SubTitle,
  TabsContainer,
  Title,
  TitleContainer,
} from './styles';
import Tab from '@mui/material/Tab';
import { CustomBadge } from '../../Components/CustomBadge/CustomBadge';
import { CandidateTable } from './Components/CandidateTable/CandidateTable';
import { JobStatusBox } from './Components/JobStatusBox/JobStatusBox';
import { Divider } from '@mui/material';
import { Dropzone } from '../../Components/Dropzone/Dropzone';
import { AttachmentsBox } from '../../Components/AttachmentsBox/AttachmentsBox';
import { JobSummary } from './Components/JobSummary/JobSummary';
import { JobTimeline } from './Components/JobTimeline/JobTimeline';
import { JobMetrics } from './Components/JobMetrics/JobMetrics';

import { useJob } from '../../Hooks/useJob';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { createJobNote, getJobFiles, getJobNotes, uploadJobFile } from '../../Services/Jobs';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { NotesBox } from '../../Components/NotesBox/NotesBox';
import { JobStatusDialog } from '../../Components/Dialogs/JobStatusDialog/JobStatusDialog';
import { COUNT_ROWS_PER_PAGE, StatusType } from '../../Types/constants';
import { getCandidateJobs } from '../../Services/CandidateJobs';
import { useDebounce } from '../../Hooks/useDebounce';
import { useFilterCandidateJobSubStatus } from '../../Hooks/useFilterCandidateJobSubStatus';
import { JobStatusSelect } from '../../Components/StatusSelect/JobStatusSelect';
import { JobTeam } from './Components/JobTeam/JobTeam';
import { Loader } from '../../Components/Loader/Loader';
import { useAuthStore } from '../../Stores/useAuthStore';

export const JobDetail = () => {
  const { jobId: id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { job, selectedTab, handleChange, updateJob } = useJob();
  const { user } = useAuthStore();

  const [files, setFiles] = useState<File[]>([]);
  const [showJobStatusDialog, setShowJobStatusDialog] = useState(false);
  const [newStatus, setNewStatus] = useState<StatusType>('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [candidateJobStatus, setCandidateJobStatus] = useState<string>('all');
  const [candidateJobSubStatus, setCandidateJobSubStatus] = useState<string>('all');
  const [clearSearch, setClearSearch] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);

  const { candidateJobSubStatusOptions } = useFilterCandidateJobSubStatus(
    candidateJobStatus,
    setCandidateJobSubStatus,
  );

  const errorMessage = t('toast.errors.something_went_wrong');

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const { data: jobFiles } = useQuery({
    queryKey: ['getJobFiles'],
    queryFn: () =>
      getJobFiles(id!, {
        page: 1,
        count: 100,
      }),
    enabled: !!id,
  });

  const { data: candidateJobsData, isLoading } = useQuery({
    queryKey: [
      'getCandidateJobs',
      id,
      debouncedSearchTerm,
      candidateJobStatus,
      candidateJobSubStatus,
    ],
    queryFn: () =>
      getCandidateJobs({
        page: currentPage + 1,
        count: rowsPerPage,
        jobId: id,
        candidateName: debouncedSearchTerm,
        status: candidateJobStatus !== 'all' ? candidateJobStatus : '',
        subStatus: candidateJobSubStatus !== 'all' ? candidateJobSubStatus : '',
      }),
    enabled: !!id,
  });

  const { mutate: uploadJobFileMutation } = useMutation({
    mutationFn: (data: FormData) => uploadJobFile(id, data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.upload'));
      queryClient.invalidateQueries({ queryKey: ['getJobFiles'] });
      setFiles([]);
    },
  });

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    uploadJobFileMutation(formData);
  };

  const { data: jobNotes } = useQuery({
    queryKey: ['getJobNotes'],
    queryFn: () =>
      getJobNotes(id!, {
        page: 1,
        count: 100,
      }),
    enabled: !!id,
  });

  const { mutate: createJobNotaMutation } = useMutation({
    mutationFn: (description: string) => createJobNote(id!, { description, userId: user?.id! }),
    onError: (error: any) => {
      toast.error(errorMessage);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getJobNotes'] });
    },
  });

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleClearFilters = () => {
    setSearchTerm('');
    setCandidateJobStatus('all');
    setCandidateJobSubStatus('all');
    setClearSearch(true);
  };

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (candidateJobsData) {
      setLastPage(candidateJobsData?.lastPage! - 1);
    }
  }, [candidateJobsData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm, candidateJobStatus, candidateJobSubStatus]);

  return (
    <MainLayout noPadding>
      {job && (
        <>
          <Container>
            <NameContainer>
              <StyledAvatar>{getInitials(job.position)}</StyledAvatar>
              <TitleContainer>
                <Title>{job.position}</Title>
                <SubTitle>
                  <AccountBalanceIcon style={{ marginRight: '8px' }} />
                  {job.department}
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                  {(job.currency || '') +
                    ' ' +
                    (job.minimumSalary ? job.minimumSalary + ' - ' : '') +
                    (job.currency || '') +
                    ' ' +
                    (job.maximumSalary || '')}
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginLeft: '8px', marginRight: '8px' }}
                  />
                  <GroupAddIcon style={{ marginRight: '8px' }} />
                  {job.headcount}
                </SubTitle>
              </TitleContainer>
              <JobStatusSelect
                hideAll
                status={job.status}
                handleChange={(newValue: StatusType) => {
                  setNewStatus(newValue);
                  setShowJobStatusDialog(true);
                }}
              />
            </NameContainer>
            <JobStatusBox
              talentAcquisitionScreening={job.summaryMetrics?.talentAcquisitionScreeningCount}
              inProgressTalentAcquisition={job.summaryMetrics?.inProgressTalentAcquisitionCount}
              inProgressClient={job.summaryMetrics?.inProgressClientCount}
            />
          </Container>
          <TabsContainer
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              {...a11yProps(0)}
              label={
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <span>Candidates</span>
                  {!!candidateJobsData?.items.length && (
                    <CustomBadge label={candidateJobsData?.items.length} />
                  )}
                </div>
              }
            />
            <Tab label="Summary" {...a11yProps(1)} />
            <Tab label="Notes" {...a11yProps(2)} />
            <Tab label="Attachments" {...a11yProps(3)} />
            <Tab label="Timeline" {...a11yProps(4)} />
            <Tab label="Metrics" {...a11yProps(5)} />
            <Tab label="Team" {...a11yProps(6)} />
          </TabsContainer>
          <div style={{ padding: 20 }}>
            {selectedTab === 0 && candidateJobsData && (
              <CandidateTable
                onInvalidateQuery={() =>
                  queryClient.invalidateQueries({
                    queryKey: [
                      'getCandidateJobs',
                      id,
                      debouncedSearchTerm,
                      candidateJobStatus,
                      candidateJobSubStatus,
                    ],
                  })
                }
                jobStatus={job.status}
                subStatusOptions={candidateJobSubStatusOptions}
                status={candidateJobStatus}
                subStatus={candidateJobSubStatus}
                handleSearch={handleSearch}
                onStatusChange={(newStatus: string) => setCandidateJobStatus(newStatus)}
                onSubStatusChange={(newStatus: string) => setCandidateJobSubStatus(newStatus)}
                handleClearFilters={handleClearFilters}
                clearSearch={clearSearch}
                rows={candidateJobsData?.items || []}
                currentPage={currentPage}
                lastPage={lastPage}
                handlePageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalItems={candidateJobsData?.totalItems || 0}
              />
            )}
            {selectedTab === 1 && <JobSummary job={job} updateJob={updateJob} />}
            {selectedTab === 2 && (
              <NotesBox
                onSuccessEdit={() => queryClient.invalidateQueries({ queryKey: ['getJobNotes'] })}
                createNote={createJobNotaMutation}
                containerWidth="50%"
                notes={jobNotes}
              />
            )}
            {selectedTab === 3 && (
              <AttachmentsContainer>
                <AttachmentsBox files={jobFiles ?? []} />
                <Dropzone onFileUpload={handleFileUpload} files={files} setFiles={setFiles} />
              </AttachmentsContainer>
            )}
            {selectedTab === 4 && (
              <JobTimeline status={job.status} creationDate={job.creationDateTime} />
            )}
            {selectedTab === 5 && <JobMetrics jobId={job.id} />}
            {selectedTab === 6 && <JobTeam jobId={job.id} />}
          </div>
        </>
      )}
      <JobStatusDialog
        open={showJobStatusDialog}
        handleClose={() => setShowJobStatusDialog(false)}
        newStatus={newStatus}
      />
      <Loader isLoading={isLoading} />
    </MainLayout>
  );
};
