import { FormControl, Autocomplete, TextField } from '@mui/material';
import { DialogActionButtons } from '../../../../../Components/DialogActionButtons/DialogActionButtons';
import FormDialogContainer from '../../../../../Components/FormDialogContainer/FormDialogContainer';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCandidates } from '../../../../../Services/Candidates';
import { assignCandidateToJob, getJobCandidates } from '../../../../../Services/Jobs';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ContactSource } from './ContactSource';
import { StyledText } from '../styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AssignCandidateSchema } from '../../../../../Utils/schemas';
import { COUNT_ROWS_PER_PAGE } from '../../../../../Types/constants';
import { useDebounce } from '../../../../../Hooks/useDebounce';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onInvalidateQuery: () => void;
}

type AssignCandidateFormData = z.infer<typeof AssignCandidateSchema>;

export const AssignCandidateDialog = ({ isOpen, handleClose, onInvalidateQuery }: Props) => {
  const { t } = useTranslation();
  const { jobId } = useParams();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<AssignCandidateFormData>({
    resolver: zodResolver(AssignCandidateSchema),
    defaultValues: {
      candidateId: '',
      candidateSource: 'LinkedIn Recruiter',
      sourceDetail: '',
    },
  });

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const assignSuccessMessage = t('jobs.assign.success_message');
  const errorMessage = t('toast.errors.something_went_wrong');

  const { data: candidates, isLoading } = useQuery({
    queryKey: ['getCandidates', debouncedSearchTerm],
    queryFn: () =>
      getCandidates({
        page: 1,
        count: COUNT_ROWS_PER_PAGE,
        fullName: debouncedSearchTerm,
      }),
  });

  const { data: jobCandidates = [] } = useQuery<Candidate[]>({
    queryKey: ['getJobCandidates', jobId],
    queryFn: () =>
      getJobCandidates(jobId!, {
        page: 1,
        count: 1000,
      }),
    enabled: !!jobId,
  });

  const jobAssignedCandidates = useMemo(() => {
    return jobCandidates.map((candidate) => candidate.id);
  }, [jobCandidates]);

  const handleModalClose = () => {
    handleClose();
    setValue('candidateId', '');
    setValue('candidateSource', 'LinkedIn Recruiter');
    setValue('sourceDetail', '');
    setSearchTerm('');
  };

  const { mutate: assignCandidateMutation } = useMutation({
    mutationFn: (data: AssignCandidateFormData) => assignCandidateToJob(jobId!, data),
    onError: () => {
      toast.error(errorMessage);
    },
    onSuccess: () => {
      toast.success(assignSuccessMessage);
      handleModalClose();
      onInvalidateQuery();
      queryClient.invalidateQueries({
        queryKey: ['getJobCandidates', jobId],
      });
    },
  });

  const onSubmit: SubmitHandler<AssignCandidateFormData> = (data) => {
    assignCandidateMutation(data);
    handleClose();
  };

  const handleCandidateChange = (event: React.SyntheticEvent, newValue: Candidate | null) => {
    setValue('candidateId', newValue?.id?.toString() || '');
    clearErrors('candidateId');
  };

  const candidateId = watch('candidateId');

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <FormDialogContainer
      open={isOpen}
      handleClose={handleModalClose}
      title={t('jobs.assign.title')}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <FormControl sx={{ paddingLeft: 2, paddingRight: 2 }} fullWidth>
          <StyledText>{t('candidates.select')}</StyledText>
          <Autocomplete
            id="candidate-autocomplete"
            options={
              candidates?.items.filter(({ id }) => !jobAssignedCandidates.includes(id)) ?? []
            }
            getOptionLabel={(option) => option.fullName}
            value={candidates?.items.find((candidate) => `${candidate.id}` === candidateId) || null}
            onChange={handleCandidateChange}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!!errors.candidateId}
                helperText={errors.candidateId?.message}
                placeholder={t('candidates.select')}
                onChange={(e) => handleSearch(e.target.value)}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            fullWidth
          />
          <ContactSource
            candidateSource={watch('candidateSource')}
            setSource={(value) => setValue('candidateSource', value)}
            sourceDetail={watch('sourceDetail') ?? ''}
            setSourceDetail={(value) => setValue('sourceDetail', value)}
          />
          <DialogActionButtons
            noMargin
            onCancel={handleModalClose}
            onConfirm={handleSubmit(onSubmit)}
          />
        </FormControl>
      </form>
    </FormDialogContainer>
  );
};
