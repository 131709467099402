import { ListItemText, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledText, TextEditor } from '../../../../JobDetail/Components/CandidateTable/styles';

interface Props {
  candidateSource: string;
  setSource: (x: string) => void;
  sourceDetail: string;
  setSourceDetail: (x: string) => void;
}

export const ContactSource = ({
  candidateSource,
  setSource,
  sourceDetail,
  setSourceDetail,
}: Props) => {
  const { t } = useTranslation();

  const contactSources = [
    'LinkedIn Recruiter',
    'Applicant',
    'Referral',
    'Database',
    'External Consultant',
  ];

  const handleSourceChange = (event: any) => {
    setSource(event.target.value as string);
  };

  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceDetail(event.target.value);
  };

  return (
    <>
      <div>
        <StyledText>{t('Contact Source')}</StyledText>
        <Select fullWidth id={'select'} value={candidateSource} onChange={handleSourceChange}>
          <MenuItem disabled value=""></MenuItem>
          {contactSources.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <StyledText>{t('Comments')}</StyledText>
        <TextEditor
          variant="outlined"
          fullWidth
          value={sourceDetail}
          onChange={handleCommentsChange}
        />
      </div>
    </>
  );
};
