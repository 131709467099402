import { StyledButton, StyledCell, StyledChip } from './styles';
import { TableRow } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DashboardTable } from '../../../Components/DashboardTable/DashboardTable';
import { formatDateTime } from '../../../Utils/format-date';

interface Props {
  currentPage: number;
  handlePageChange: (event: any, page: number) => void;
  rows: any[];
  lastPage: number;
  rowsPerPage: number;
  setRowsPerPage: (x: number) => void;
  totalItems: number;
}

export const ReportsTable = ({
  currentPage,
  handlePageChange,
  rows,
  lastPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
}: Props) => {
  const { t } = useTranslation();
  const reportNameLabel = t('reports.table.job');
  const reportIdLabel = t('reports.table.id');
  const statusLabel = t('reports.table.status');
  const emissionDateLabel = t('reports.table.emission_date');
  const downloadLabel = t('reports.table.download');
  const headCells: HeadCell[] = [
    {
      id: 'job',
      numeric: false,
      disablePadding: false,
      label: reportNameLabel,
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: reportIdLabel,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: statusLabel,
    },
    {
      id: 'creationDateTime',
      numeric: true,
      disablePadding: false,
      label: emissionDateLabel,
    },
    {
      id: 'download',
      numeric: true,
      disablePadding: false,
      label: downloadLabel,
    },
  ];

  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardTable
      headerCells={headCells}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      rows={rows}
      lastPage={lastPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      totalItems={totalItems}
    >
      {rows?.map((row) => {
        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            <StyledCell>{row.job}</StyledCell>
            <StyledCell>{row.id}</StyledCell>
            <StyledCell>
              <StyledChip status={row.status || ''} label={row.status || ''} />
            </StyledCell>
            <StyledCell>
              {row.creationDateTime ? formatDateTime(row.creationDateTime!) : ' - '}
            </StyledCell>
            <StyledCell>
              {row.status === 'Completed' ? (
                <StyledButton onClick={() => handleDownload(row.file.url, row.file.name)}>
                  <Download />
                </StyledButton>
              ) : (
                '-'
              )}
            </StyledCell>
          </TableRow>
        );
      })}
    </DashboardTable>
  );
};
