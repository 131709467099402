import { DataBoxTable } from '../../../../Components/DataBoxTable/DataBoxTable';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getMetrics } from '../../../../Services/Jobs';
import { fieldsContactSource, metricsFields } from '../../../../Utils/fields';

export const JobMetrics = ({ jobId }: { jobId: string }) => {
  const { t } = useTranslation();
  const { data: jobMetricsData } = useQuery({
    queryKey: ['getMetrics'],
    queryFn: () => getMetrics(jobId!),
    enabled: !!jobId,
  });

  return (
    <div style={{ display: 'flex' }}>
      {jobMetricsData && (
        <>
          <DataBoxTable
            title={t('jobs.metrics.job_metrics')}
            fields={metricsFields}
            values={jobMetricsData}
          />
          <DataBoxTable
            title={t('jobs.metrics.candidate_source')}
            fields={fieldsContactSource}
            values={jobMetricsData.candidateSourceCounts}
          />
        </>
      )}
    </div>
  );
};
