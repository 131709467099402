import { useTranslation } from 'react-i18next';
import { DataBoxTable } from '../../../../Components/DataBoxTable/DataBoxTable';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { DetailsJobSchema, PriorityJobSchema } from '../../../../Utils/schemas';
import { useEffect, useState } from 'react';
import { DataBox } from '../../../../Components/DataBox/DataBox';
import { Paper, TextField } from '@mui/material';

import { priorityAndConfidentialityFields } from '../../../../Utils/fields';
import SearchableChipBox from '../../../../Components/SearchableChipBox/SearchableChipBox';
import { GetDetailsFields } from './fields';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { editJobSkills, getJobSkills } from '../../../../Services/Jobs';
import toast from 'react-hot-toast';

export const JobSummary = ({ job, updateJob }: { job: Job; updateJob: any }) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState(job.description || '');
  const [onDetailsEdit, setOnDetailsEdit] = useState<boolean>(false);
  const [onDescriptionEdit, setOnDescriptionEdit] = useState<boolean>(false);
  const [onPriorityAndConfidentialityEdit, setOnPriorityAndConfidentialityEdit] =
    useState<boolean>(false);

  const jobDetailsTitle = t('jobs.details.title');
  const jobDescriptionTitle = t('jobs.details.description_title');
  const jobPriorityAndConfidentialityTitle = t('jobs.details.priority_and_confidentiality_title');
  const queryClient = useQueryClient();

  const {
    control: controlDetails,
    handleSubmit: handleSubmitDetails,
    formState: { errors: errorsDetails },
  } = useForm<z.infer<typeof DetailsJobSchema>>({
    resolver: zodResolver(DetailsJobSchema),
    defaultValues: {
      position: job.position,
      headcount: job.headcount,
      client: job.client,
      requestedDateTime: job.requestedDateTime,
      closeDateTime: job.closeDateTime,
      requester: job.requester,
      techHub: job.techHub,
      seniority: job.seniority,
      minimumSalary: job.minimumSalary,
      maximumSalary: job.maximumSalary,
      currency: job.currency,
      expectedStartDateTime: job.expectedStartDateTime,
      experience: job.experience,
      characteristics: job.characteristics,
      market: job.market,
      modality: job.modality,
      id: `${job.id}`,
    },
  });

  const {
    control: controlPriority,
    handleSubmit: handleSubmitPriority,
    formState: { errors: errorsPriority },
  } = useForm<z.infer<typeof PriorityJobSchema>>({
    resolver: zodResolver(PriorityJobSchema),
    defaultValues: {
      confidential: false,
    },
  });

  const onSubmitPriority: SubmitHandler<z.infer<typeof PriorityJobSchema>> = async (data) => {
    const updatedData = {
      ...data,
      confidential: data.confidential,
    };
    updateJob(updatedData);
    setOnPriorityAndConfidentialityEdit(false);
  };

  const onSubmitDetail: SubmitHandler<z.infer<typeof DetailsJobSchema>> = async (data) => {
    updateJob(data);
    setOnDetailsEdit(false);
  };

  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  const handleSaveDescription = () => {
    updateJob({ description: description });
    setOnDescriptionEdit(false);
  };

  const { data: jobSkills } = useQuery({
    queryKey: ['getJobSkills', job.id],
    queryFn: () => getJobSkills(job.id),
  });

  useEffect(() => {
    if (jobSkills) {
      setSelectedSkills(jobSkills.skills);
    }
  }, [jobSkills]);

  const { mutate: editSkillsMutation } = useMutation({
    mutationFn: (skills: string[]) => editJobSkills('' + job.id, skills),
    onSuccess: () => {
      toast.success(t('toast.success.edit', { elem: 'Skills' }));

      queryClient.invalidateQueries({ queryKey: ['getJobSkills', '' + job.id] });
    },
    onError: () => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'space-between',
        width: '100%',
      }}
    >
      <form onSubmit={handleSubmitDetails(onSubmitDetail)} style={{ width: '100%' }}>
        <DataBoxTable
          title={jobDetailsTitle}
          fields={GetDetailsFields()}
          control={controlDetails}
          values={job}
          handleSave={() => handleSubmitDetails(onSubmitDetail)()}
          handleEdit={() => setOnDetailsEdit(true)}
          onEdit={onDetailsEdit}
          handleCancel={() => setOnDetailsEdit(false)}
          errors={errorsDetails}
          fullWidth
        />
      </form>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '20px' }}>
        <DataBox
          title={jobDescriptionTitle}
          handleEdit={() => setOnDescriptionEdit(true)}
          handleCancel={() => setOnDescriptionEdit(false)}
          handleSave={handleSaveDescription}
          onEdit={onDescriptionEdit}
          fullWidth
        >
          <Paper style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
            {onDescriptionEdit ? (
              <TextField
                multiline
                disabled={!onDescriptionEdit}
                sx={{
                  marginTop: '15px',
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000DE',
                  },
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            ) : (
              <p>{description}</p>
            )}
          </Paper>
        </DataBox>
        <form onSubmit={handleSubmitPriority(onSubmitPriority)}>
          <DataBoxTable
            fullWidth
            title={jobPriorityAndConfidentialityTitle}
            fields={priorityAndConfidentialityFields}
            control={controlPriority}
            values={{
              confidential: job.confidential,
              priority: job.priority,
            }}
            handleSave={() => handleSubmitPriority(onSubmitPriority)()}
            handleEdit={() => setOnPriorityAndConfidentialityEdit(true)}
            onEdit={onPriorityAndConfidentialityEdit}
            handleCancel={() => setOnPriorityAndConfidentialityEdit(false)}
            errors={errorsPriority}
          />
        </form>
        <SearchableChipBox
          initialSelectedChips={selectedSkills}
          handleSave={editSkillsMutation}
          width={'100%'}
        />
      </div>
    </div>
  );
};
