import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import theme from '../../../theme';

export const Container = styled.div(({ padding }: { padding?: string }) => ({
  backgroundColor: '#e9ecef',
  padding: padding || 12,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
export const Title = styled.h3(() => ({
  margin: 0,
}));

export const AddButton = styled(IconButton)(() => ({
  color: theme.palette.primary.main,
  ':hover': {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  width: 30,
  height: 30,
}));
