import { t } from 'i18next';
import { z } from 'zod';

const invalidEmailError = t('form_fields.email.errors.invalid');
const nameError = t('form_fields.name.errors.required');

export const CandidateSchema = z
  .object({
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    creationDateTime: z.string().optional(),
    deletionDateTime: z.string().nullable().optional(),
    editDateTime: z.string().nullable().optional(),
    email: z.string().nullable().optional(),
    fullName: z.string(),
    lastContactDateTime: z.string().nullable().optional(),
    linkedin: z.string().optional(),
    phone: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    const emailFormatValid = !data.email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email);

    if (!data.email && !data.linkedin) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'At least one of email or linkedin must be provided.',
        path: ['email'],
      });
    } else if (data.email && !emailFormatValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: invalidEmailError,
        path: ['email'],
      });
    }
  });

export const DetailsJobSchema = z.object({
  headcount: z.any().optional(),
  position: z.string().min(1),
  requestedDateTime: z.string().optional(),
  closeDateTime: z.string().nullable().optional(),
  client: z.any().nullable().optional(),
  techHub: z.string().nullable().optional(),
  seniority: z.string().nullable().optional(),
  minimumSalary: z.any().optional(),
  maximumSalary: z.any().optional(),
  currency: z.string().nullable().optional(),
  requester: z.string().nullable().optional(),
  expectedStartDateTime: z.string().optional(),
  experience: z.any().optional(),
  characteristics: z.string().nullable().optional(),
  market: z.string().nullable().optional(),
  modality: z.string().nullable().optional(),
  id: z.string().optional(),
});
export const PriorityJobSchema = z.object({
  confidential: z.boolean().optional(),
  priority: z.string().optional(),
});

export const JobMetricsSchema = z.object({
  candidatesPresentedToClientCount: z.string().optional(),
  ptInterviewsCount: z.string().optional(),
  internalClientInterviewCount: z.string().optional(),
  rejectedProposalsCount: z.string().optional(),
  linkedInAccountOneCount: z.string().optional(),
  linkedInAccountTwoCount: z.string().optional(),
  linkedInAccountThreeCount: z.string().optional(),
  totalLinkedInAnswersCount: z.string().optional(),
  totalInterestedCount: z.string().optional(),
  linkedinApplicantsCount: z.string().optional(),
  webApplicantsCount: z.string().optional(),
  otherPortalsApplicantsCount: z.string().optional(),
  dbApplicantsCount: z.string().optional(),
  profilesSubmittedByConsultantsCount: z.string().optional(),
  totalCandidatesCount: z.string().optional(),
});

export const generalJobCandidateSchema = z.object({
  status: z.string().nullable().optional(),
  salaryExpectation: z.any().nullable().optional(),
  currency: z.string().nullable().optional(),
  seniority: z.string().nullable().optional(),
  yearsOfExperience: z.any().nullable().optional(),
});

export const englishJobCandidateSchema = z.object({
  level: z.string().nullable().optional(),
  testDate: z.any().nullable().optional(),
  testResult: z.string().nullable().optional(),
  comments: z.string().nullable().optional(),
});

export const technicalJobCandidateSchema = z.object({
  testStatus: z.string().nullable().optional(),
  testScore: z.any().nullable().optional(),
  testDate: z.any().nullable().optional(),
  difficulty: z.string().nullable().optional(),
  cheatedOnTest: z.boolean().nullable().optional(),
  comments: z.string().nullable().optional(),
});

export const resultsJobCandidateSchema = z.object({
  proposalDate: z.string().nullable().optional(),
  responseDate: z.string().nullable().optional(),
  response: z.string().nullable().optional(),
  startDate: z.string().nullable().optional(),
  surveyCompleted: z.boolean().nullable().optional(),
  notificationDate: z.string().nullable().optional(),
  notificationMean: z.string().nullable().optional(),
});

export const createJobSchema = z.object({
  clientId: z.any().optional(),
  description: z.string().optional(),
  headcount: z.string().transform((val) => {
    const num = Number(val);
    if (isNaN(num)) {
      throw new Error('Invalid number');
    }
    return num;
  }),
  position: z.string().min(1, nameError),
  seniority: z.string(),
});

export const jobTimelineSchema = z.object({
  date: z.string(),
  comment: z.string().optional(),
  status: z.string(),
});

export const AssignCandidateSchema = z.object({
  candidateId: z.string().min(1, 'Required field'),
  candidateSource: z.string().min(1, 'Required field'),
  sourceDetail: z.string().optional(),
});

export const AssignCandidateFromCandidatesTableSchema = z.object({
  jobId: z.string().min(1, 'Required field'),
  candidateSource: z.string().min(1, 'Required field'),
  sourceDetail: z.string().optional(),
});
export const createClientSchema = z.object({
  name: z.string().min(1, 'Required field'),
  type: z.enum(['Internal', 'External']),
});
