import { useState } from 'react';
import {
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Modal,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  Autocomplete,
  TextField,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isValid, parse } from 'date-fns';
import {
  CommentCard,
  Footer,
  StyledCard,
  StyledCell,
  StyledTextField,
  StyledTextValue,
  Title,
  StarWrapper,
} from './styles';
import { t } from 'i18next';
import { ROLES } from '../../Types/constants';
import { useQuery } from '@tanstack/react-query';
import { getUsers } from '../../Services/Users';
import { formatDateTime } from '../../Utils/format-date';

const ExpandableCard = ({
  interview,
  onSave,
  onCancel,
  onEdit,
  handleDelete,
}: {
  interview: Interview;
  onSave: (x: Interview) => void;
  onCancel?: () => void;
  onEdit?: boolean;
  handleDelete: (id?: string) => void;
}) => {
  const [expanded, setExpanded] = useState(onEdit || false);
  const [isEditing, setIsEditing] = useState(onEdit);
  const [currentInterview, setCurrentInterview] = useState<Interview>(interview);

  const { data: users } = useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const response = await getUsers({
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setExpanded(false);
    onSave(currentInterview);
  };

  const handleCancelClick = (close?: boolean) => {
    setIsEditing(false);
    onCancel?.();
    if (close) setExpanded(false);
  };

  const handleUserSelect = (event: any, newValue: User | null) => {
    setCurrentInterview((prevInterview) => ({
      ...prevInterview,
      user: newValue,
    }));
  };

  const handleFieldChange = (field: keyof Interview, value: string) => {
    setCurrentInterview((prevInterview) => ({
      ...prevInterview,
      [field]: value,
    }));
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      const formattedDate = format(newValue, 'yyyy-MM-dd');
      setCurrentInterview((prevInterview) => ({
        ...prevInterview,
        date: formattedDate,
      }));
    }
  };

  const parsedDate = currentInterview.date
    ? parse(currentInterview.date, 'yyyy-MM-dd', new Date())
    : null;
  const isValidDate = isValid(parsedDate);

  return (
    <>
      <StyledCard onClick={handleExpandClick}>
        <CardContent>
          <Typography variant="h6">{currentInterview.title}</Typography>
          {!!currentInterview.date && (
            <Typography variant="body2" color="textSecondary">
              {formatDateTime(currentInterview.date)}
            </Typography>
          )}
          <CommentCard>{currentInterview.description}</CommentCard>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Footer>
            <span>{currentInterview.user?.fullName}</span>
            <IconButton onClick={handleExpandClick} style={{ padding: 5, alignSelf: 'end' }}>
              <OpenInFullIcon />
            </IconButton>
          </Footer>
        </CardActions>
        {interview.isMandatory && (
          <StarWrapper>
            <StarIcon color="primary" />
          </StarWrapper>
        )}
      </StyledCard>
      <Modal
        open={expanded}
        onClose={handleExpandClick}
        aria-labelledby="expandable-card-modal"
        aria-describedby="expandable-card-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          {isEditing ? (
            <StyledTextField
              variant="outlined"
              fullWidth
              value={currentInterview.title || ''}
              onChange={(e) => handleFieldChange('title', e.target.value)}
              isTitle
            />
          ) : (
            <Title>{currentInterview.title}</Title>
          )}

          {!isEditing && (
            <IconButton
              onClick={handleEditClick}
              style={{ position: 'absolute', top: 16, right: 56 }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => handleCancelClick(true)}
            style={{ position: 'absolute', top: 16, right: 16 }}
          >
            <CloseIcon />
          </IconButton>
          <Table>
            <TableBody>
              <TableRow style={{ width: '100%' }}>
                <StyledCell bold>{t('common.date')}</StyledCell>
                <StyledCell>
                  {isEditing ? (
                    <DatePicker
                      sx={{ width: '100%' }}
                      value={isValidDate ? parsedDate : null}
                      onChange={handleDateChange}
                      format="dd/MM/yyyy"
                    />
                  ) : !!currentInterview.date ? (
                    <StyledTextValue>{formatDateTime(currentInterview.date)}</StyledTextValue>
                  ) : (
                    <></>
                  )}
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell bold>{t('common.interviewer')}</StyledCell>
                <StyledCell>
                  {isEditing ? (
                    <Autocomplete
                      value={currentInterview.user}
                      options={
                        users?.filter(
                          (user) =>
                            user.roles.includes(ROLES.admin) ||
                            user.roles.includes(ROLES.recruiter),
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.fullName}
                      onChange={handleUserSelect}
                      renderInput={(params) => <TextField {...params} label={t('common.user')} />}
                      fullWidth
                    />
                  ) : (
                    <Typography>{currentInterview.user?.fullName}</Typography>
                  )}
                </StyledCell>
              </TableRow>
              <TableRow>
                <StyledCell bold>{t('common.comments')}</StyledCell>
              </TableRow>
            </TableBody>
          </Table>
          <StyledTextField
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            value={currentInterview.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            onClick={() => setIsEditing(true)}
            style={{ marginTop: 16 }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 2,
              visibility: isEditing ? 'visible' : 'hidden',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleDelete(interview.id);
                setExpanded(false);
              }}
              disabled={interview.isMandatory}
            >
              {t('actions.delete')}
            </Button>
            <div>
              <Button variant="outlined" onClick={() => handleCancelClick()}>
                {t('actions.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                style={{ marginLeft: 8 }}
              >
                {t('actions.save')}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ExpandableCard;
