import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import theme from '../../theme';

export const StyledChip = styled(Chip)(({ status }: { status: string }) => ({
  backgroundColor:
    status === 'Active'
      ? theme.palette.success.light
      : status === 'Closed'
        ? theme.palette.info.light
        : status === 'OnHold'
          ? theme.palette.yellow.light
          : theme.palette.error.light,
  color:
    status === 'Active'
      ? theme.palette.success.dark
      : status === 'Closed'
        ? theme.palette.info.dark
        : status === 'OnHold'
          ? theme.palette.yellow.dark
          : theme.palette.error.dark,
}));
