import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  technicalJobCandidateSchema,
  generalJobCandidateSchema,
  englishJobCandidateSchema,
  resultsJobCandidateSchema,
} from '../Utils/schemas';
import { englishFields, generalFields, resultsFields, technicalFields } from '../Utils/fields';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addInterview,
  addNoteCandidateJob,
  deleteInterview,
  editCandidateJob,
  editInterview,
  getCandidateJobNotes,
  getInterviews,
} from '../Services/CandidateJobs';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { formatDates, replaceEmptyStrings } from '../Utils';
import { format } from 'date-fns';
import { useAuthStore } from '../Stores/useAuthStore';

export const useCandidateJob: any = ({
  candidateJob,
  updateCandidateJob,
}: {
  candidateJob: CandidateJob;
  updateCandidateJob: (j: CandidateJob) => void;
}) => {
  const { user } = useAuthStore();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [interviewsExpanded, setInterviewsExpanded] = useState<boolean>(true);
  const [generalEdit, setGeneralEdit] = useState<boolean>(false);
  const [englishEdit, setEnglishEdit] = useState<boolean>(false);
  const [technicalEdit, setTechnicalEdit] = useState<boolean>(false);
  const [editResults, setEditResults] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data: notesData } = useQuery({
    queryKey: ['getCandidateJobNotes'],
    queryFn: () =>
      getCandidateJobNotes(candidateJob.id!, {
        page: 1,
        count: 100,
      }),
    enabled: !!candidateJob.id,
  });

  const { mutate: handleAddNote } = useMutation({
    mutationFn: (description: string) =>
      addNoteCandidateJob(candidateJob.id, { description, userId: user?.id! }),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getCandidateJobNotes'] });
    },
  });

  const handleSuccessEditNote = () => {
    queryClient.invalidateQueries({ queryKey: ['getCandidateJobNotes'] });
  };

  const { data: interviewsData } = useQuery({
    queryKey: ['getInterviews', candidateJob.id],
    queryFn: () => getInterviews(candidateJob.id, { page: 1, count: 50 }),
  });

  const { mutate: createInterviewMutation } = useMutation({
    mutationFn: (data: Interview) => addInterview(candidateJob.id, data),
    onSuccess: (response: Interview) => {
      queryClient.invalidateQueries({ queryKey: ['getInterviews', candidateJob.id] });
    },
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
  });

  const handleAddInterview = () => {
    const interview: Interview = {
      title: 'New interview',
      date: format(new Date(), 'yyyy-MM-dd'),
      description: '',
    };
    createInterviewMutation(interview);
    setInterviewsExpanded(true);
  };

  const updateFields = (updatedFields: any) => {
    const updatedCandidateJob = {
      ...candidateJob,
      ...updatedFields,
    };
    replaceEmptyStrings(updatedCandidateJob);
    updateCandidateJob(updatedCandidateJob);
    editMutation(updatedCandidateJob);
  };

  const handleSaveInterview = (updatedInterview: Interview) => {
    editInterviewMutation(updatedInterview);
  };
  const handleDeleteInterview = (id: string) => {
    deleteInterviewMutation(id);
  };

  const { mutate: editMutation } = useMutation({
    mutationFn: (data: CandidateJob) => editCandidateJob(candidateJob?.id, data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.edit', { elem: 'Candidate Job ' }));
    },
  });
  const { mutate: editInterviewMutation } = useMutation({
    mutationFn: (data: Interview) => editInterview(data),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.edit', { elem: 'Interview ' }));
      queryClient.invalidateQueries({ queryKey: ['getInterviews', candidateJob.id] });
    },
  });
  const { mutate: deleteInterviewMutation } = useMutation({
    mutationFn: (id: string) => deleteInterview(id),
    onError: (error: any) => {
      toast.error(t('toast.errors.something_went_wrong'));
    },
    onSuccess: (data) => {
      toast.success(t('toast.success.delete', { elem: 'Interview ' }));
      queryClient.invalidateQueries({ queryKey: ['getInterviews', candidateJob.id] });
    },
  });

  const {
    control: generalControl,
    handleSubmit: generalHandleSubmit,
    formState: { errors: generalErrors },
  } = useForm<z.infer<typeof generalJobCandidateSchema>>({
    resolver: zodResolver(generalJobCandidateSchema),
    defaultValues: {
      status: candidateJob.generalData.status,
      salaryExpectation: candidateJob.generalData.salaryExpectation,
      currency: candidateJob.generalData.currency,
      seniority: candidateJob.generalData.seniority,
      yearsOfExperience: candidateJob.generalData.yearsOfExperience,
    },
  });

  const {
    control: englishControl,
    handleSubmit: englishHandleSubmit,
    formState: { errors: englishErrors },
  } = useForm<z.infer<typeof englishJobCandidateSchema>>({
    resolver: zodResolver(englishJobCandidateSchema),
    defaultValues: {
      level: candidateJob.englishProficiencyData.level,
      testDate: candidateJob.englishProficiencyData.testDate,
      testResult: candidateJob.englishProficiencyData.testResult,
      comments: candidateJob.englishProficiencyData.comments,
    },
  });

  const {
    control: technicalControl,
    handleSubmit: technicalHandleSubmit,
    formState: { errors: technicalErrors },
  } = useForm<z.infer<typeof technicalJobCandidateSchema>>({
    resolver: zodResolver(technicalJobCandidateSchema),
    defaultValues: {
      testStatus: candidateJob.technicalSkills.testStatus,
      testScore: candidateJob.technicalSkills.testScore,
      difficulty: candidateJob.technicalSkills.difficulty,
      cheatedOnTest: candidateJob.technicalSkills.cheatedOnTest,
      comments: candidateJob.technicalSkills.comments,
      testDate: candidateJob.technicalSkills.testDate,
    },
  });

  const {
    control: resultsControl,
    handleSubmit: resultsHandleSubmit,
    formState: { errors: resultsErrors },
  } = useForm<z.infer<typeof resultsJobCandidateSchema>>({
    resolver: zodResolver(resultsJobCandidateSchema),
    defaultValues: {
      proposalDate: candidateJob.results.proposalDate,
      response: candidateJob.results.response || '',
      responseDate: candidateJob.results.responseDate,
      startDate: candidateJob.results.startDate,
      surveyCompleted: candidateJob.results.surveyCompleted,
      notificationDate: candidateJob.results.notificationDate,
      notificationMean: candidateJob.results.notificationMean,
    },
  });

  const generalFormData = {
    fields: generalFields,
    control: generalControl,
    values: candidateJob.generalData,
    handleSave: () =>
      generalHandleSubmit((data) => {
        updateFields({ generalData: data });
        setGeneralEdit(false);
      })(),
    handleEdit: () => setGeneralEdit(true),
    onEdit: generalEdit,
    handleCancel: () => setGeneralEdit(false),
    errors: generalErrors,
  };

  const englishFormData = {
    fields: englishFields,
    control: englishControl,
    values: candidateJob.englishProficiencyData,
    handleSave: () =>
      englishHandleSubmit((data) => {
        updateFields({ englishProficiencyData: data });
        setEnglishEdit(false);
      })(),
    handleEdit: () => setEnglishEdit(true),
    onEdit: englishEdit,
    handleCancel: () => setEnglishEdit(false),
    errors: englishErrors,
  };

  const technicalFormData = {
    fields: technicalFields,
    control: technicalControl,
    values: candidateJob.technicalSkills,
    handleSave: () =>
      technicalHandleSubmit((data) => {
        updateFields({ technicalSkills: data });
        setTechnicalEdit(false);
      })(),
    handleEdit: () => setTechnicalEdit(true),
    onEdit: technicalEdit,
    handleCancel: () => setTechnicalEdit(false),
    errors: technicalErrors,
  };

  const resultsFormData = {
    fields: resultsFields,
    control: resultsControl,
    values: candidateJob.results,
    handleSave: () =>
      resultsHandleSubmit((data) => {
        updateFields({ results: data });
        setEditResults(false);
      })(),
    handleEdit: () => setEditResults(true),
    onEdit: editResults,
    handleCancel: () => setEditResults(false),
    errors: resultsErrors,
  };

  return {
    expanded,
    setExpanded,
    interviewsExpanded,
    setInterviewsExpanded,
    interviews: interviewsData?.items || [],
    handleAddInterview,
    generalFormData,
    englishFormData,
    technicalFormData,
    resultsFormData,
    notesData,
    handleSaveInterview,
    handleDeleteInterview,
    handleAddNote,
    handleSuccessEditNote,
  };
};
