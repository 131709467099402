import { MainLayout } from '../../Components/Layouts/MainLayout/MainLayout';
import { getCandidates } from '../../Services/Candidates';
import { useTranslation } from 'react-i18next';
import { CandidateTable } from './Components/CandidateTable/CandidateTable';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { useDebounce } from '../../Hooks/useDebounce';
import { useEffect, useState } from 'react';
import AutocompleteSelect from '../../Components/AutocompleteSelect/AutocompleteSelect';
import { getSkills } from '../../Services/Skills';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { ClearFiltersButton } from '../../Components/Common/ClearFiltersButton/ClearFiltersButton';
import { COUNT_ROWS_PER_PAGE } from '../../Types/constants';
import { Loader } from '../../Components/Loader/Loader';

export const Candidates = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [searchTerm, setSearchTerm] = useState('');
  const [skillsSearchTerm, setSkillsSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [clearSearch, setClearSearch] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm);
  const debouncedSkillsSearchTerm = useDebounce(skillsSearchTerm);

  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(COUNT_ROWS_PER_PAGE);

  const title = t('candidates.title');

  const { data: candidatesData, isLoading } = useQuery({
    queryKey: ['getCandidates', debouncedSearchTerm, selectedOptions, currentPage, rowsPerPage],
    queryFn: () =>
      getCandidates({
        page: currentPage + 1,
        count: rowsPerPage,
        fullName: debouncedSearchTerm,
        skills: selectedOptions,
      }),
  });

  const { data: skills } = useQuery({
    queryKey: ['getSkills', debouncedSkillsSearchTerm],
    queryFn: () =>
      getSkills({
        page: 1,
        count: 10,
        name: debouncedSkillsSearchTerm,
      }),
  });

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleClearFilters = () => {
    setSearchTerm('');
    setSkillsSearchTerm('');
    setSelectedOptions([]);
    setClearSearch(true);
  };

  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (candidatesData) {
      setLastPage(candidatesData?.lastPage! - 1);
    }
  }, [candidatesData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debouncedSearchTerm, selectedOptions]);

  return (
    <div>
      <MainLayout
        onCandidatesInvalidateQuery={() =>
          queryClient.invalidateQueries({
            queryKey: [
              'getCandidates',
              debouncedSearchTerm,
              selectedOptions,
              currentPage,
              rowsPerPage,
            ],
          })
        }
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h1>{title}</h1>
          <ClearFiltersButton handleClearFilters={handleClearFilters} />
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchBar margin="0 0 15px 0" onSearch={handleSearch} clearSearch={clearSearch} />
          <AutocompleteSelect
            searchTerm={skillsSearchTerm}
            onSearchTermChange={(newValue: string) => setSkillsSearchTerm(newValue)}
            options={skills ?? []}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
        {candidatesData && (
          <CandidateTable
            rows={candidatesData?.items || []}
            currentPage={currentPage}
            lastPage={lastPage}
            handlePageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalItems={candidatesData?.totalItems || 0}
          />
        )}
        <Loader isLoading={isLoading} />
      </MainLayout>
    </div>
  );
};
