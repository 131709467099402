import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  border: 'none',
  margin: 0,
  '&::before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    borderBottom: 'none',
    margin: 0,
    marginBottom: 2,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
  color: 'gray',
  border: 'none',
  height: 50,
  minHeight: 50,
  maxHeight: 50,
  display: 'flex',
  alignItems: 'center',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIcon': {
    color: 'gray',
  },
  '&.Mui-expanded': {
    height: 50,
    minHeight: 50,
    maxHeight: 50,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
  color: 'gray',
}));
