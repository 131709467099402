import { Box, Typography } from '@mui/material';
import { StatusBox, TopSection, BottomSection } from './styles';
import { useTranslation } from 'react-i18next';

interface JobStatusBoxProps {
  inProgressTalentAcquisition: number;
  inProgressClient: number;
  talentAcquisitionScreening: number;
}

export const JobStatusBox = ({
  inProgressTalentAcquisition,
  inProgressClient,
  talentAcquisitionScreening,
}: JobStatusBoxProps) => {
  const { t } = useTranslation();
  const talentAcquisitionScreeningLabel = t(
    'jobs.metrics.summarized_metrics.talent_acquisition_screening',
  );
  const inProgressTalentAcquisitionLabel = t(
    'jobs.metrics.summarized_metrics.in_progress_talent_acquisition',
  );
  const inProgressClientLabel = t('jobs.metrics.summarized_metrics.in_progress_client');
  const statuses = [
    { label: talentAcquisitionScreeningLabel, value: talentAcquisitionScreening, color: '#4caf50' },
    {
      label: inProgressTalentAcquisitionLabel,
      value: inProgressTalentAcquisition,
      color: '#ff9800',
    },
    { label: inProgressClientLabel, value: inProgressClient, color: '#9e9e9e' },
  ];
  return (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      {statuses.map((status, index) => (
        <StatusBox key={index} color={status.color}>
          <TopSection color={status.color}>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              {status.label}
            </Typography>
          </TopSection>
          <BottomSection>
            <Typography variant="h6" sx={{ color: status.color }}>
              {status.value}
            </Typography>
          </BottomSection>
        </StatusBox>
      ))}
    </Box>
  );
};
