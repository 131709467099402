import styled from '@emotion/styled';
import { Button, Chip, TableCell } from '@mui/material';
import theme from '../../../theme';

export const StyledCell = styled(TableCell)(() => ({
  borderRight: '1px solid lightGray',
  paddingTop: '8px',
  paddingBottom: '8px',
}));
export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '8px',
}));

export const StyledChip = styled(Chip)(({ status }: { status: string }) => ({
  backgroundColor:
    status === 'Completed'
      ? theme.palette.success.light
      : status === 'Pending'
        ? theme.palette.yellow.light
        : theme.palette.error.light,
  color:
    status === 'Completed'
      ? theme.palette.success.dark
      : status === 'Pending'
        ? theme.palette.yellow.dark
        : theme.palette.error.dark,
}));
