import { instance, ServiceResponse } from './Axios';

export interface GetFilesResponse {
  items: AttachmentFile[];
  page: number;
  lastPage: number;
}

export const getCandidates = async (params: {
  page: number;
  count: number;
  fullName?: string;
  skills?: string[];
}) => {
  const searchParams = new URLSearchParams({
    page: params.page.toString(),
    count: params.count.toString(),
    fullName: params.fullName || '',
  });

  if (params.skills) {
    params.skills.forEach((skill) => searchParams.append('skills', skill));
  }

  const url = `/candidates?${searchParams.toString()}`;

  const response = await instance.get<ServiceResponse<Candidate[]>>(url);
  return response.data;
};

export const createCandidate = async (data: Candidate) => {
  const response = await instance.post<Candidate>('/candidates', data);
  return response?.data;
};

export const getCandidateById = async (id?: string) => {
  const response = await instance.get<Candidate>(`/candidates/${id}`);
  return response.data;
};
export const editCandidate = async (id?: string, data?: Candidate) => {
  const response = await instance.put(`/candidates/${id}`, data);
  return response.data;
};

export const uploadCandidateFile = async (id?: string, file?: FormData) => {
  const response = await instance.post(`/candidates/${id}/files`, file);
  return response.data;
};

export const getCandidateFiles = async (id: string, params: { page: number; count: number }) => {
  const response = await instance.get<GetFilesResponse>(`/candidates/${id}/files`, { params });
  return response.data.items;
};

export const getCandidateSkills = async (id: number) => {
  const response = await instance.get(`/candidates/${id}/skills`);
  return response.data;
};

export const editCandidateSkills = async (id?: string, data?: string[]) => {
  const response = await instance.put(`/candidates/${id}/skills`, { skills: data });
  return response.data;
};

export const createCandidateNote = async (
  id: string,
  params: { description: string; userId: number },
) => {
  const response = await instance.post(`/candidates/${id}/notes`, params);
  return response.data;
};
