import styled from '@emotion/styled';

export const Container = styled.div(({ width }: { width?: string }) => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  width: width || '380px',
  margin: 5,
}));

export const ChipContainer = styled.div(() => ({
  padding: '10px',
  border: '1px solid #ddd',
  backgroundColor: '#f9f9f9',
  maxHeight: '200px',
  overflow: 'scroll',
}));

export const EmptyMessageText = styled.span(({}) => ({
  alignSelf: 'center',
}));
