import styled from '@emotion/styled';

export const Container = styled.div(({ width = '380px' }: { width?: string }) => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  width,
  margin: 5,
}));

export const NoteContainer = styled.div(() => ({
  padding: '10px',
  border: '1px solid #ddd',
  marginTop: '8px',
  borderRadius: '5px',
  backgroundColor: '#f9f9f9',
}));

export const Description = styled.p(() => ({
  fontSize: '16px',
  margin: 0,
  overflow: 'hidden',
}));
export const EmptyMessageContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  padding: '10px',
  border: '1px solid #ddd',
  marginBottom: '10px',
  borderRadius: '5px',
  backgroundColor: '#f9f9f9',
  marginTop: 5,
}));

export const EmptyMessageText = styled.span(({}) => ({
  alignSelf: 'center',
}));
export const DescriptionInput = styled.textarea(() => ({
  fontSize: '16px',
  margin: 0,
  border: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  outline: 'none',
}));

export const Footer = styled.div(() => ({
  marginTop: '10px',
  fontSize: '12px',
  color: '#888',
  display: 'flex',
  justifyContent: 'space-between',
}));
