import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import FormDialogContainer from '../../../FormDialogContainer/FormDialogContainer';
import { DialogActionButtons } from '../../../DialogActionButtons/DialogActionButtons';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import { createJobSchema } from '../../../../Utils/schemas';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createJob, CreateJobData } from '../../../../Services/Jobs';
import { useState } from 'react';
import { getClients } from '../../../../Services/Client';
import ClientFormDialog from './ClientFormDialog';

interface JobFormDialogProps {
  open: boolean;
  handleClose: () => void;
  onInvalidateQuery: () => void;
}

export default function JobFormDialog({
  open,
  handleClose,
  onInvalidateQuery,
}: JobFormDialogProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isConfidential, setIsConfidential] = useState(false);
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const nameLabel = t('form_fields.name.job_label');
  const headcountLabel = t('form_fields.headcount.label');
  const descriptionLabel = t('form_fields.description.label');
  const seniorityLabel = t('form_fields.seniority.label');

  const seniorityAdvancedLabel = t('form_fields.seniority.options.advanced');
  const seniorityAdvancedPlusLabel = t('form_fields.seniority.options.advanced_plus');
  const seniorityJuniorLabel = t('form_fields.seniority.options.junior');
  const senioritySeniorLabel = t('form_fields.seniority.options.senior');

  const createJobTitle = t('jobs.create.title');
  const createJobDescription = t('jobs.create.description');

  const toastErrorMessage = t('toast.errors.something_went_wrong');
  const toastSuccessMessage = t('toast.success.create', { elem: 'Job' });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    register,
    reset,
  } = useForm<z.infer<typeof createJobSchema>>({
    resolver: zodResolver(createJobSchema),
  });

  const { mutate: createJobMutation } = useMutation({
    mutationFn: (data: CreateJobData) => createJob(data),
    onError: (error: any) => {
      toast.error(toastErrorMessage);
      console.log({error});
      console.log('job form dialog')
    },
    onSuccess: () => {
      toast.success(toastSuccessMessage);
      handleModalClose();
      onInvalidateQuery();
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof createJobSchema>> = (data) => {
    createJobMutation({ ...data, confidential: isConfidential });
    handleModalClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue('seniority', event.target.value);
  };

  const { data: clients = [] } = useQuery<Client[]>({
    queryKey: ['getClients'],
    queryFn: async () => {
      const response = await getClients({
        page: 1,
        count: 1000,
      });
      return response.items;
    },
  });

  const handleAddNewClient = (newClient: Client) => {
    queryClient.invalidateQueries({ queryKey: ['getClients'] });
    setSelectedClient(newClient);
    setValue('clientId', newClient.id);
    setOpenAddClientModal(false);
  };

  const handleModalClose = () => {
    handleClose();
    reset();
    setSelectedClient(null);
  };

  return (
    <FormDialogContainer open={open} handleClose={handleModalClose} title={createJobTitle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText>{createJobDescription}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="position"
            label={nameLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('position')}
            error={!!errors.position}
            helperText={errors.position?.message}
          />
          <Box display="flex" alignItems="center">
            <Autocomplete
              id="client-autocomplete"
              options={clients}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(_, newValue) => {
                setSelectedClient(newValue);
                setValue('clientId', newValue?.id || '');
              }}
              value={selectedClient}
              renderInput={(params) => (
                <TextField {...params} error={!!errors.clientId} label={t('common.client')} />
              )}
              fullWidth
            />
            <Button
              style={{ width: '30%', margin: '10px' }}
              onClick={() => setOpenAddClientModal(true)}
              variant="contained"
            >
              {t('common.add_new')}
            </Button>
          </Box>
          <TextField
            margin="dense"
            id="email"
            label={headcountLabel}
            type="number"
            fullWidth
            variant="outlined"
            {...register('headcount')}
            error={!!errors.headcount}
            helperText={errors.headcount?.message}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'start',
              width: '100%',
            }}
          >
            <FormControl sx={{ width: '50%', marginTop: 1 }}>
              <InputLabel id="demo-simple-select-helper-label">Seniority</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                defaultValue={'advanced'}
                label={seniorityLabel}
                {...register('seniority')}
                error={!!errors.seniority}
                onChange={handleChange}
              >
                <MenuItem value={'advanced'}>{seniorityAdvancedLabel}</MenuItem>
                <MenuItem value={'advanced_plus'}>{seniorityAdvancedPlusLabel}</MenuItem>
                <MenuItem value={'junior'}>{seniorityJuniorLabel}</MenuItem>
                <MenuItem value={'senior'}>{senioritySeniorLabel}</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ marginTop: 1, width: '50%' }}
              control={
                <Switch
                  value={isConfidential}
                  onChange={() => setIsConfidential(!isConfidential)}
                  sx={{ marginRight: 2, marginLeft: 8 }}
                />
              }
              label="Confidential"
            />
          </div>
          <TextField
            multiline
            minRows={3}
            margin="dense"
            id="description"
            label={descriptionLabel}
            type="text"
            fullWidth
            variant="outlined"
            {...register('description')}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </DialogContent>
        <DialogActionButtons onCancel={handleModalClose} />
      </form>
      <ClientFormDialog
        open={openAddClientModal}
        handleClose={() => setOpenAddClientModal(false)}
        onSuccess={handleAddNewClient}
      />
    </FormDialogContainer>
  );
}
