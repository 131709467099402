import { instance, ServiceResponse } from './Axios';

export const getClients = async (params: { page: number; count: number; name?: string }) => {
  const response = await instance.get<ServiceResponse<Client[]>>('/clients', { params });
  return response.data;
};

export const createClient = async (data: Client) => {
  const response = await instance.post<Client>('/clients', data);
  return response.data;
};

export const editClient = async (id: string, data: Client) => {
  const response = await instance.put<Client>(`/clients/${id}`, data);
  return response.data;
};
