import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

interface FormDialogContainerProps {
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
  title: string;
}

export default function FormDialogContainer({
  children,
  open,
  handleClose,
  title,
}: FormDialogContainerProps) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}
