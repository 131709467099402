import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDialogActions } from './styles';

interface DialogActionButtonsProps {
  noMargin?: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
}
export const DialogActionButtons = ({
  noMargin,
  onCancel,
  onConfirm,
}: DialogActionButtonsProps) => {
  const { t } = useTranslation();
  const cancelButton = t('actions.cancel');
  const confirmButton = t('actions.confirm');
  return (
    <StyledDialogActions noMargin={noMargin}>
      <Button variant="outlined" onClick={onCancel}>
        {cancelButton}
      </Button>
      <Button onClick={onConfirm} type="submit" variant="contained">
        {confirmButton}
      </Button>
    </StyledDialogActions>
  );
};
