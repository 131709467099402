import { useState, useEffect } from 'react';

const DEBOUNCE_TIME = 1000;

export const useDebounce = (value: string, debounceTime?: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value === '') {
      setDebouncedValue(value);
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceTime || DEBOUNCE_TIME);

    return () => {
      clearTimeout(handler);
    };
  }, [value, debounceTime]);

  return debouncedValue;
};
