import styled from '@emotion/styled';
import { Avatar } from '@mui/material';
import Tabs from '@mui/material/Tabs';

export const StyledAvatar = styled(Avatar)(() => ({
  display: 'flex',
  backgroundColor: '#F0B529',
  height: 100,
  width: 100,
  fontSize: 36,
}));
export const Container = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: '12px 0',
}));
export const NameContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '12px',
}));
export const TitleContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'flex-start',
  margin: 12,
}));
export const Title = styled.h1(() => ({
  margin: '0px 0px 2px 0px',
}));
export const SubTitle = styled.p(() => ({
  display: 'flex',
  margin: 0,
  fontSize: 15,
  alignItems: 'flex-end',
}));
export const TabsContainer = styled(Tabs)(() => ({
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: 'lightGray',
}));

export const AttachmentsContainer = styled.div(({}) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  margin: 5,
}));

export const ActionsContainer = styled.div(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
}));

export const ButtonsContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  paddingTop: 10,
}));

export const SearchAndFilterContainer = styled.div(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
}));
